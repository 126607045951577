<template>
  <SimpleModal class="create_modal"
               close-text="Back to my jobs"
               @close="$emit('close')">
    <h4 class="create_modal__header">
      <slot name="header">
        Create new job
      </slot>
    </h4>
    <div class="create_modal__body">
      <slot name="body">
        <div
          class="body__select"
          :class="isError ? 'body__select--error': ''">
          <div
            v-show="isError"
            class="select__error">
            Please select a customer or create a new customer
          </div>
          <div class="position-relative">
            <SelectDropdownInput
              :list="customers"
              :loading="loading"
              placeholder="Customer Search"
              :value="searchValue"
              @input="changeValue"
              @select="setCustomer"
            >
            </SelectDropdownInput>
          </div>
        </div>
      </slot>
    </div>
    <div class="create_modal__footer">
      <slot name="footer">
        <ButtonMain @click="createJob">
          Create new job
          <svg-icon name="solid/arrow-right"/>
        </ButtonMain>
        <LinkReturn @click="createCustomer">
          <svg-icon name="solid/plus-circle"/>
          <p>Create new customer</p>
        </LinkReturn>
      </slot>
    </div>
  </SimpleModal>
</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue'
import ButtonMain from "@/components/buttons/ButtonMain";
import LinkReturn from "@/components/links/LinkReturn";
import SelectDropdownInput from "@/components/dropdown/SelectDropdownInput";
import SimpleModal from "@/components/modals/SimpleModal";
import {useStore} from "vuex";
import { useRouter, useRoute } from 'vue-router'

export default ({
  components: {
    ButtonMain,
    SelectDropdownInput,
    SimpleModal,
    LinkReturn
  },
  props: ['id'],
  emits: ['close'],
  setup(props, {emit}) {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loading = ref(false)
    const searchValue = ref('')
    const chosenCustomer = ref(null)
    const customers = computed(() => store.getters['customers/customersFixedAmount']({amount: 7,text: searchValue.value}))
    let changeValue = (e) => {
      isError.value = false
      searchValue.value = e
    }
    const isError = ref(false)
    let createJob = async () => {
      if(!chosenCustomer.value) {
        isError.value = true
      } else {
        store.commit('customers/customer', chosenCustomer.value)
        await router.push('/my-jobs/new')
        emit('close')
      }
    }
    let createCustomer = async () => {
       store.commit('customers/resetCustomer')
        await router.push('/my-jobs/new')
    }
    let setCustomer = (e) => {
      chosenCustomer.value = e
    }
    onMounted(async () => {
      store.commit('customers/resetCustomers')
      loading.value = true
      await store.dispatch('customers/getCustomers')
      loading.value = false
    })
    return {
      createJob,
      createCustomer,
      isError,
      customers,
      searchValue,
      changeValue,
      setCustomer,
      chosenCustomer,
      loading
    }
  }
})
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
::v-deep .simple_modal__container {
  height: 480px;
  width: 920px;
}
.create_modal {
  &__header {
    font-weight: bold;
    font-size: 25px;
    line-height: 100%;
    color: #1C2833;
    padding-bottom: 50px;
  }
  &__body {
    .body {
      &__select {
        position: relative;
        &--error {
          ::v-deep input {
            border: 1px solid #C0392B;
          }
          .select {
            &__error {
              position: absolute;
              left: 20px;
              bottom: -18px;
              font-size: 13px;
              letter-spacing: 0.02em;
              color: #C0392B;
            }
          }
        }
      }
    }
  }
  &__footer {

    & button {
      height: 60px;
      width: 260px;
      text-transform: none;
      margin: 50px 0;
      span {
        margin-right: 0;
        margin-left: 10px;
      }
    }
    a {
      span {
        font-size: 20px;
      }
      & p {
        font-size: 15px;
        &:before {
          border-bottom: 1px dashed rgba(89, 182, 146, 0.7)
        }
      }
    }
  }
}
@media (max-width: 768px) {
  ::v-deep .simple_modal__container {
    height: 100vh;
    width: 100%;
  }
  .create_modal {
    &__header {
      font-size: 21px;
      padding-bottom: 35px;
    }
  }
}

</style>
