<template>
  <div class="total_price">
<!--    <div>-->
<!--      <slot><div style="text-transform: uppercase; margin-right: 5px; opacity: 0.8">Job Total:</div></slot>-->
<!--      <div class="total_price_value" @click="toggle()">-->
<!--        <div class="total_price__price">${{ priceCalc(jopPriceTotal) }}</div>-->
<!--        <div>-->
<!--          <svg-icon v-show="jobTotalIsOpened === true" name="solid/angle-up"/>-->
<!--          <svg-icon v-show="jobTotalIsOpened === false" name="solid/angle-down"/>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div v-if="jobTotalIsOpened" class="total_price__content" :class="placeOrder? 'total_price__content&#45;&#45;place_order' : ''">-->
      <div class="total_price__content" :class="placeOrder? 'total_price__content--place_order' : ''">
      <h5 class="text-uppercase font-weight-bold color-black" >Total investment</h5>
      <div v-for="(value, propertyName) in jopPriceList"
           class="total_price__item align-items-center"
           :class="value?.bold == true && 'total_price__item--bold'"
      >
        <div class="position-relative"> <p style="text-transform: capitalize">{{ propertyName == 'gst' ? 'GST' :  readable(propertyName) }}</p>
          <svg-icon name="solid/angle-right"/>
        </div>
        <div> ${{priceCalc(value?.amount)}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import {useStore} from "vuex";
import { useRouter, useRoute } from 'vue-router'
import {changePriceForm} from "@/helpers/aroundPrice";

export default ({
  components: {
  },
  props: ['jopPriceList', 'jopPriceTotal'],
  emits: ['toggle'],
  setup(props, {emit}) {
    const jobTotalIsOpened = ref(false)
    let priceCalc = (price) => {
      return changePriceForm(price)
    }
    let readable = (value) => {
      return value.replace(/_/g, ' ');
    }
    let toggle = () => {
      jobTotalIsOpened.value =! jobTotalIsOpened.value
      emit('toggle', jobTotalIsOpened.value)
    }
    return {
      jobTotalIsOpened,
      priceCalc,
      readable,
      toggle
    }
  }
})
</script>

<style scoped lang="scss">
@import "../../src/assets/scss/utils/vars";
.total_price {
  &>div:first-child {
    align-items: center;
    font-size: 15px;
    letter-spacing: 0.02em;

    span {
      margin-left: 3px;
      font-size: 12px;
    }
  }
  .total_price_value {
    align-items: center;
    font-weight: bold;
    transition-duration: 0.2s;
    &:hover {
      color: rgba(89, 182, 146, 0.8);
      .total_price__price {
        color: rgba(89, 182, 146, 0.8);
        &:before {
          width: 0;
        }
      }
    }
  }
  &__price {
    position: relative;
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      bottom: -2px;
      border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
    }
  }
  &__content {
    position: absolute;
    bottom: 60px;
    left: 0;
    background: linear-gradient(0deg, rgba(11, 53, 83, 0.07), rgba(11, 53, 83, 0.07)), #FFFFFF;
    width: 100%;
    padding: 32px 10px 32px 20px;
    box-shadow: 0px -3px 3px rgba(28, 40, 51, 0.2);
    color: $text-gray;
    z-index: 5;
    h5 {
      padding-bottom: 5px;
    }
    &--place_order {
      bottom: 139px
    }
  }
  &__item {
    margin-top: 15px;
    display: flex;
    .position-relative {
      width: 226px;
      margin-right: 10px;
      p {
        display: inline-block;
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.07), rgba(11, 53, 83, 0.07)), #FFFFFF;
        position: relative;
        padding-right: 10px;
      }
      &:before {
        position: absolute;
        content: '';
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        z-index: 0;
        border-bottom: 1px dashed rgba(11, 53, 83, 0.4);
      }
      span {
        color: rgba(11, 53, 83, 0.4);
        font-size: 10px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &--bold {
      font-weight: bold;
      color: $black;
      .position-relative {
        &:before {
          border-bottom: 1px dashed $black;
        }
        span {
          color: $black;
        }
      }
    }
  }
}

</style>
