<template>
  <div
    class="tw-flex tw-card tw-text-sm hover:tw-border-estimate-approved tw-cursor-pointer"
    @click="$emit('createNewModalOpen')"
  >
    <div class="tw-m-auto">
      <div class="tw-card-head tw-py-1.5 tw-text-white tw-bg-white ">
        <div
          class="tw-flex tw-items-baseline tw-justify-between"
        />
      </div>

      <div class="tw-card-body tw-text-secondary">
        <div class="thumbnailsTable tw-text-center">
          <span class="thumbnailsTable__img tw-uppercase tw-font-bold tw-text-xl tw-mb-0">
            <svg-icon name="plus-circle"
              class="small_icon tw-w-10 tw-h-10"
            />
          </span>
          <span class="thumbnailsTable__img tw-uppercase tw-font-bold tw-text-xl tw-m-0">
          {{ title }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateNewCardComponent",
  props: {
    title: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/utils/_vars.scss";

.tw-card-head {
  border-bottom-width: 0 !important;
}

</style>
