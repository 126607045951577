<template>
  <SimpleModal class="cancel_modal" @close="$emit('close')">
    <h4 class="cancel_modal__header">
      <slot name="header">
        Cancel job
      </slot>
    </h4>
    <div class="cancel_modal__body">
      <slot name="body">
        <div class="body__notification">
          <div>
            Once job is cancelled it will be archived including related quotes and estimates and your customer {{job.customer?.name}} {{job.customer?.last_name}} will be notified.
            <br><br>Are you sure you want to cancel this job?
          </div>
        </div>
        <ErrorField
          :is-error="isError"
          text-error="Please select the reason for refusal"
        >
          <SelectDropdown
            :list="reasons"
            placeholder="Select Reject Reason"
            @select="chooseReason"
            :selected="chosenReason"
          >
          </SelectDropdown>
        </ErrorField>
        <ErrorField
          v-if="chosenReason === reasons[reasons.length - 1]"
          :is-error="isErrorTextarea"
          text-error="The note is required"
        >
        <Textarea                  @input="changeNoteValue"
                  placeholder="Type you reason for cancelling the job here"></Textarea>
        </ErrorField>
      </slot>
    </div>
    <div class="cancel_modal__footer">
      <slot name="footer">
        <ButtonMain @click="cancelJob">
          <svg-icon name="solid/check"/>
          Cancel job
        </ButtonMain>
      </slot>
    </div>
  </SimpleModal>
</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue'
import ButtonMain from "@/components/buttons/ButtonMain";
import SelectDropdown from "@/components/dropdown/SelectDropdown";
import SimpleModal from "@/components/modals/SimpleModal";
import Textarea from "@/components/inputs/Textarea";
import {useStore} from "vuex";

export default ({
  components: {
    ButtonMain,
    SelectDropdown,
    SimpleModal,
    Textarea
  },
  props: ['id', 'job'],
  emits: ['close', 'cancelled'],
  setup(props, {emit}) {
    const store = useStore()
    const reasons = computed(() => store.getters['leads/rejectionReasons'])
    const isError = ref(false)
    const isErrorTextarea = ref(false)
    const chosenReason = ref(null)
    const noteValue = ref('')
    let chooseReason = (reason) => {
      isError.value = false
      chosenReason.value = reason
    }
    let changeNoteValue = (value) => {
      isErrorTextarea.value = false
      noteValue.value = value
    }
    let cancelJob = async () => {
      if(!chosenReason.value) {
        isError.value = true
      } else if (chosenReason.value === 'Other' && noteValue.value.length < 1) {
        isErrorTextarea.value = true
      } else {
        await store.dispatch('jobs/changeJobsStatus', {id: props.id, status: 'mark-as-cancelled'} )
        emit('close')
        emit('cancelled')
      }
    }
    onMounted(async () => {
      await store.dispatch('leads/getRejectionReasons')
    })
    return {
      chosenReason,
      chooseReason,
      noteValue,
      changeNoteValue,
      isError,
      isErrorTextarea,
      cancelJob,
      reasons
    }
  }
})
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";

.cancel_modal {
  ::v-deep .simple_modal__container {
    padding: 52px 40px 80px;
  }
  &__header {
    font-weight: bold;
    font-size: 25px;
    line-height: 100%;
    color: #1C2833;
    padding-bottom: 40px;
  }
  &__body {
    .body {
      &__notification {
        font-size: 15px;
        margin-bottom: 40px;
        color: rgba(28, 40, 51, 0.8);
      }
    }
    textarea {
      margin-top: 20px;
    }
  }
  &__footer {
    padding-top: 50px;
    margin-top: auto;
  }
}
@media (max-width: 768px) {
  .cancel_modal {
    ::v-deep .simple_modal__container {
      width: 100%;
      padding: 40px 20px 50px;
    }
    &__header {
      font-size: 21px;
      padding-bottom: 20px;
    }
    &__body {
      .body {
        &__notification {
          margin-bottom: 30px;
        }
      }
    }
  }
}

</style>
