<template>
  <div class="card" :class="noButton ? 'card--no_button': ''">
    <div class="card__header tw-text-black-gray">
      <h5 class="header__id cursor_pointer" @click="$router.push(`/my-jobs/${job.container_id}`)">{{ job.id_with_prefix }}</h5>
      <h5 :title="job.customer?.display_name" class="header__title">{{job.customer?.display_name}}</h5>
      <div v-if="!noButton" class="header__info"  @click="detailsAreShown = job.container_id">
        <svg-icon name="solid/info"/>
      </div>
      <div v-if="!noButton"
           class="dropdown_menu"
           @click="toggleMenu">
        <svg-icon name="regular/ellipsis-h"/>
        <SimpleDropdown v-if="menuIsOpened" @close="closeMenu" class="tw-flex tw-flex-col tw-items-start" style="padding-left: 15px;">
          <div
            class="tw-flex tw-gap-1 tw-items-center"
            v-for="action in otherActions"
            :key="action.action"
            @click="handleActionClick(action.action)">
            <svg-icon :name="action.icon"/>
            {{action.name}}
          </div>
        </SimpleDropdown>
      </div>
    </div>
    <div class="card__content">
      <div class="content__elements">
        <div
            class="d-flex align-items-center elements__reference"
            v-if="job.container?.dealer_order_count > 0">
            Order:&nbsp;<router-link :to="{name: 'order-item', params: { id: job.container_id}}" class="font-weight-bold color-green">OR{{job.container_id}}</router-link>
          </div>
        <div class="d-flex align-items-center justify-content-between">
          <div
            class="d-flex align-items-center elements__reference"
            :class="!job.customer_ref ? 'elements__reference--inactive' : ''"
          >
            <svg-icon name="solid/cog"/>
            {{job.customer_ref ? job.customer_ref : 'No Reference'}}
          </div>
          <!-- <div
            class="d-flex align-items-center elements__reference"
            v-if="job.container?.dealer_order_count > 0">
            Order:&nbsp;<router-link :to="{name: 'order-item', params: { id: job.container_id}}" class="font-weight-bold color-green">OR{{job.container_id}}</router-link>
          </div> -->
        </div>
        <div v-if="job.customer?.address"  class="d-flex align-items-center text-uppercase">
          <svg-icon name="solid/globe"/>
          <div v-if="!job.job_address.same_as_customer_address">{{job.job_address.address}} {{job.job_address.suburb}} {{job.job_address.state?.code}} {{job.job_address.postcode}}</div>
          <div v-else>{{job.customer?.address}} {{job.customer?.suburb}} {{job.customer.state?.code}} {{job.customer.postcode}}</div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div v-if="job.customer?.phone"  class="d-flex align-items-center mr-5px flex-shrink-0">
            <svg-icon name="solid/phone"/>

            <a :href="'tel:'+job.customer?.phone">{{job.customer?.phone}}</a>
          </div>
          <div v-if="job.customer?.email" class="d-flex align-items-center">
            <svg-icon name="solid/envelope"/>
            <a :href="'mailto:'+job.customer?.email" class="text-lowercase font-weight-bold link-green">{{job.customer?.email}}</a>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between" v-if="job.status !== 'Cancelled' && job.status !== 'Complete'">
          <div v-if="job.status === 'Open'" title="Check measure date" class="d-flex align-items-center" style="font-weight: bold">
            <svg-icon name="solid/ruler-combined"/>
            {{job.check_measure_date ? dateFormat(job.check_measure_date) : 'Not scheduled'}}
          </div>
          <div v-if="job.status !== 'Open' && job.status !== 'Cancelled' && job.status !== 'Complete'" title="Installation date" class="d-flex align-items-center" style="font-weight: bold">
            <svg-icon name="solid/screwdriver-wrench"/>
            {{job.installation_date ? dateFormat(job.installation_date) : 'Not scheduled'}}
          </div>
          <div v-else-if="job.status === 'Open' && job.installation_date" title="Installation date" class="d-flex align-items-center" style="font-weight: bold">
            <svg-icon name="solid/screwdriver-wrench"/>
            {{job.installation_date ? dateFormat(job.installation_date) : 'Not scheduled'}}
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center mr-5px">
            <svg-icon name="solid/layer-group"/>
            <span v-if="job.container.positions_count > 0">{{job.container.positions_count || 0}}</span>
            <span v-if="job.container.positions_count && job.container.additional_accessories_count"> + </span>
            <span v-if="job.container.additional_accessories_count > 0">{{job.container.additional_accessories_count || 0}}</span>

          </div>
          <div class="tw-flex" v-if="job.status === 'Open' && job.container.latest_quote_or_estimate && job.container.latest_quote_or_estimate.length > 0"
               :title="job.container.latest_quote_or_estimate[0].type + ' sent on ' + dateFormat(job.container.latest_quote_or_estimate[0].sent_on) +
           '. Status: ' + job.container.latest_quote_or_estimate[0].status "
          >
            <div class="tw-pt-1">
              <template v-if="job.container.latest_quote_or_estimate[0].type === 'Quote'">
                <svg-icon name="solid/tape" v-if="job.container.latest_quote_or_estimate[0].status === 'Sent'"/>
              </template>
              <template v-else-if="job.container.latest_quote_or_estimate[0].type === 'Estimate'">
                <svg-icon name="solid/clipboard-list" v-if="job.container.latest_quote_or_estimate[0].status === 'Sent'"/>
              </template>
              <svg-icon name="solid/eye" v-if="job.container.latest_quote_or_estimate[0].status === 'Opened'"/>
              <svg-icon name="solid/handshake" v-if="job.container.latest_quote_or_estimate[0].status === 'Approved'"/>
              <svg-icon name="solid/thumbs-down" v-if="job.container.latest_quote_or_estimate[0].status === 'Rejected'"/>
            </div>
            <span>
            {{ job.container.latest_quote_or_estimate[0].type }} {{ job.container.latest_quote_or_estimate[0].status }}
              </span>
          </div>
          <div class="tw-flex" v-else-if="job.status === 'Open'">
            <span>Not sent</span>
          </div>
<!--          <div v-else-if="job.order_received_on && job.order_received_on!='0000-00-00'" class="d-flex align-items-center" style="font-weight: bold">-->
<!--            {{dateCalc(job.order_received_on)}}-->
<!--          </div>-->
<!--          <div v-else-if="job.check_measure_date && job.check_measure_date!='0000-00-00'" class="d-flex align-items-center" style="font-weight: bold">-->
<!--            <svg-icon name="solid/clock"/>-->
<!--            {{dateFormat(job.check_measure_date)}}-->
<!--          </div>-->
<!--          <div v-else style="width: 50px"></div>-->
          <div v-if="job.total && canSeePrice" style="font-size: 15px;" class="d-flex justify-content-end">
            <div class="mr-5px font-weight-bold color-black">$ {{priceCalc(job.total)}}</div>
          </div>
        </div>
      </div>
      <div v-if="type" class="content__button">
        <ButtonMain
          v-if="mainAction"
          @click="handleActionClick(mainAction.action)">
          <svg-icon :name="mainAction.icon"/>
          {{ mainAction.name }}
        </ButtonMain>

<!--        <JobsCardButtons-->
<!--          :job="job"-->
<!--          @showBookInstallation="showBookInstallation = job.id"-->
<!--          @showSchedule="showSchedule = job.id"-->
<!--          @checkIsCompleted="checkIsCompleted = job.id"-->
<!--          @deliveryIsShown="deliveryIsShown = job.id"-->
<!--          @changeStatusCompleted="changeStatus(job.id, 'mark-as-completed')"-->
<!--          @placeOrderIsShown="placeOrderIsShown = job.id"-->
<!--          @cloneJob="cloneJob"-->
<!--          @showCancel="showCancel = job.id"-->
<!--        />-->
      </div>
      <JobsDetails v-if="detailsAreShown"
                   :id="detailsAreShown"
                   :placeOrder="placeOrderIsShown"
                   :job="job"
                   @close="detailsAreShown = false"
                   @showSchedule="showSchedule = job.id"
                   @checkIsCompleted="checkIsCompleted = job.id"
                   @deliveryIsShown="deliveryIsShown = job.id"
                   @changeStatusCompleted="changeStatus(job.id, 'mark-as-completed')"
                   @placeOrderIsShown="placeOrderIsShown = job.id"
                   @cloneJob="cloneJob"
                   @showCancel="showCancel = job.id"
      />
<!--      <JobsScheduleCheckMeasure  v-if="showSchedule" :id="showSchedule" @close="showSchedule = false"/>-->
<!--      <JobsBookInstallation :job="job" v-if="showBookInstallation" :id="showBookInstallation" @close="showBookInstallation = false"/>-->
      <JobsCancel v-if="showCancel" :id="showCancel" @close="showCancel = false" :job="job" @cancelled="jobCancelled"/>
      <JobsCheckMeasureCompleted :job="job" v-if="checkIsCompleted" :id="checkIsCompleted" @close="checkIsCompleted = false"/>
      <JobsDelivery v-if="deliveryIsShown" :id="deliveryIsShown"  @close="deliveryIsShown = false"/>
      <JobsDetails v-if="placeOrderIsShown" :id="placeOrderIsShown" :place-order="true" :job="job"  @close="placeOrderIsShown = false" @updateList="updateList"/>
      <Teleport to="body">
        <DeleteConfirmationModal v-model="showDelete" @deleteEstimate="deleteJob" message="This action will delete entire estimate.
  Click Continue if you want to proceed"/>
      </Teleport>

      <template v-if="quoteOrEstimateType">
        <QuoteAndEstimateSendConfirmationModal
          :container_id="job.id"
          :type="quoteOrEstimateType"
          v-model:show="quoteOrEstimateType"
          @onSend="updateList(job.status)"
        />
      </template>

      <SubmitOrderOrJobModal
        v-model:show="showSubmitOrderConfirmation"
        @orderSubmitted="handleOrderSubmitted"
        :id="job.id"
        area="my-jobs"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import SimpleDropdown from "@/components/dropdown/SimpleDropdown";
// import JobsScheduleCheckMeasure from "@/components/jobs/JobsScheduleCheckMeasure";
import JobsCancel from "@/components/jobs/JobsCancel";
import JobsDetails from "@/components/jobs/JobsDetails";
import JobsDelivery from "@/components/jobs/JobsDelivery";
import JobsCheckMeasureCompleted from "@/components/jobs/JobsCheckMeasureCompleted";
import JobsCardDropdown from "@/components/jobs/JobsCardDropdown";
import JobsCardButtons from "@/components/jobs/JobsCardButtons";
// import JobsBookInstallation from "@/components/jobs/JobsBookInstallation";
import {changePriceForm} from '../../helpers/aroundPrice';
import {formatDate, dateCalcFormat} from "../../helpers/datetime";
import DeleteConfirmationModal from '@/components/item-wizard/components/modals/DeleteConfirmationModal'
import QuoteAndEstimateSendConfirmationModal from "@/components/item-wizard/components/modals/QuoteAndEstimateSendConfirmationModal.vue";
import SubmitOrderOrJobModal from "@/components/item-wizard/components/modals/SubmitOrderOrJobModal.vue";
import ButtonMain from "@/components/buttons/ButtonMain";
import router from "@/router";

export default ({
  name: 'JobsCard',
  props: ['job', 'type', 'noButton'],
  components: {
    SimpleDropdown,
    ButtonMain,
    JobsCheckMeasureCompleted,
    JobsCancel,
    JobsDetails,
    JobsDelivery,
    // JobsScheduleCheckMeasure,
    JobsCardDropdown,
    JobsCardButtons,
    // JobsBookInstallation,
    DeleteConfirmationModal,
    QuoteAndEstimateSendConfirmationModal,
    SubmitOrderOrJobModal,
  },
  setup(props, {emit}) {
    const store = useStore()
    const showSchedule = ref(false)
    const showBookInstallation = ref(false)
    const showCancel = ref(false)
    const checkIsCompleted = ref(false)
    const menuIsOpened = ref(false)
    const detailsAreShown = ref(false)
    const deliveryIsShown = ref(false)
    const placeOrderIsShown = ref(false)
    const showDelete = ref(false)
    const quoteOrEstimateType = ref(null)
    const showSubmitOrderConfirmation = ref(false)
    const mainAction = computed(() => {
      return props.job?.actions?.find(x => x.type === "main")
    })
    const otherActions = computed(() => {
      return props.job?.actions?.filter(i => {
        return i.type !== "main" && (i.available_on === 'list' || i.available_on === 'both')
      })
    })

    let toggleMenu = () => {
      menuIsOpened.value =! menuIsOpened.value
    }
    let closeMenu = () => {
      menuIsOpened.value = false
    }
    let changeStatus = (id, value) => {
      menuIsOpened.value = false
    }
    let priceCalc = (price) => {
      return changePriceForm(price);
    }
    let dateCalc = (date) => {
      return dateCalcFormat(date);
    }
    let dateFormat = (date) => {
      return dateCalcFormat(date, true, false)
    }
    let cloneJob = async () => {
      await store.dispatch('jobs/changeJobsStatus', {id: props.job.id, status: 'clone'} )
    }

    const canSeePrice = computed(() => store.getters['auth/canSeePrice'])

    let updateList = async (status) => {
      store.commit('jobs/resetJobs', props.job.status)
      store.commit('jobs/resetJobs', status)
      await store.dispatch('jobs/getJobsList', {status: props.job.status} )
      await store.dispatch('jobs/getJobsList', {status: status} )
    }

    async function deleteJob() {
      await store.dispatch('jobs/deleteJob', props.job )
    }

    function jobCancelled() {
      const sort = store.getters['jobs/jobsSorting']
      store.commit('jobs/jobsSorting', sort +' ')
    }

    async function handleActionClick(action){
      switch (action){
        case "EDIT":
        case "VIEW": router.push({name: "job", params: {id : props.job.container_id}})
          break
        case "MARK_AS_DELIVERED":
          deliveryIsShown.value = props.job.id
          break
        case "COMPLETE":
          //checkIsCompleted.value = props.job.id
          await store.dispatch('jobs/changeJobsStatus', {id: props.job.id, status: 'mark-as-completed'} )
          const sort = store.getters['jobs/jobsSorting']
          store.commit('jobs/jobsSorting', sort +' ')
          // changeStatus(props.job.id, 'mark-as-completed')
          break
        case "CLONE":
          await cloneJob()
          break
        case "PLACE_ORDER":
          //placeOrderIsShown.value = props.job.id
          showSubmitOrderConfirmation.value = true
          break
        case "CANCEL":
          showCancel.value = props.job.id
          break
        case "DELETE":
          showDelete.value = true
          break
        case "SPECIFICATION":
          router.push({ name: 'specification', params: { id: props.job.id, type: 'my-jobs' } })
          break
        case "SEND_QUOTE":
          quoteOrEstimateType.value = 'quote'
          break
        case "SEND_ESTIMATE":
          quoteOrEstimateType.value = 'estimate'
          break

      }

    //@showBookInstallation="showBookInstallation = job.id"
    //@showSchedule="showSchedule = job.id"

    }

    const handleOrderSubmitted = () => {
      showSubmitOrderConfirmation.value = false
      const sort = store.getters['jobs/jobsSorting']
      store.commit('jobs/jobsSorting', sort +' ')
    }

    return {
      jobCancelled,
      handleOrderSubmitted,
      deleteJob,
      showDelete,
      quoteOrEstimateType,
      showSubmitOrderConfirmation,
      toggleMenu,
      showSchedule,
      checkIsCompleted,
      showCancel,
      closeMenu,
      priceCalc,
      dateCalc,
      dateFormat,
      changeStatus,
      detailsAreShown,
      deliveryIsShown,
      placeOrderIsShown,
      menuIsOpened,
      cloneJob,
      showBookInstallation,
      updateList,
      canSeePrice,
      handleActionClick,
      mainAction,
      otherActions
    }
  }
})
</script>


<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
  border-radius: 2px;
  height: auto;
  margin-bottom: 10px;
  &__header {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: rgba(11, 53, 83, 0.15);
    border-radius: 2px 2px 0px 0px;
    .header {
      &__id {
        font-size: 13px;
        margin-left: 10px;
        margin-right: 5px;
        text-transform: uppercase;
        flex-shrink: 0;
        font-weight: bold;
      }
      &__title {
        font-size: 13px;
        text-transform: uppercase;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: rgba(28, 40, 51, 0.7);
        font-weight: bold;
      }
      &__info {
        height: 100%;
        width: 30px;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        background: rgba(11, 53, 83, 0.2);
        margin-left: auto;
        font-size: 14px;
        color: white;
        cursor: pointer;
        transition-duration: 0.2s;
        &:hover {
          background: #59B692;
        }
      }
    }
    ::v-deep .dropdown_menu {
      flex-shrink: 0;
      margin: 0;
      background: linear-gradient(0deg, rgba(11, 53, 83, 0.15), rgba(11, 53, 83, 0.15)), #FFFFFF;
      &:hover {
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.2), rgba(11, 53, 83, 0.2)), #FFFFFF;
      }
      .simple_dropdown {
        > * {
          transition-duration: 0.2s;
          &:hover {
            span {
              transition-duration: 0.2s;
              color: #59B692;
            }
            color: #59B692;
          }
        }
        div.cancel {
          &:hover {
            span {
              color: #C0392B;
            }
            color: #C0392B;
          }
        }
      }
    }
  }
  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px 10px 7px;
    color: rgba(28, 40, 51, 0.8);
    font-size: 13px;
    span {
      margin-right: 5px;
    }
    .content {
      &__elements {
        & > div {
          padding-bottom: 10px;
        }
        & * {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        span {
          flex-shrink: 0;
        }
        .elements {
          &__reference {
            font-weight: bold;
            font-size: 13px;
            color: #1C2833;
            padding-bottom: 14px;
            &--inactive {
              color: rgba(28, 40, 51, 0.6);
              font-style: italic;
              font-weight: normal;
            }
          }
        }
      }
      &__button {
        margin-top: auto;
        ::v-deep button {
          margin-bottom: 10px;
        }
      }
    }
  }
  &--no_button {
    height: auto;
    .card__content {
      padding-bottom: 0;
    }
  }
}
@media (max-width: 768px) {
  ::v-deep .dropdown_menu {
    .simple_dropdown {
      padding: 0;
      width: 260px;
      > * {
        padding: 0 18px;
        height: 50px;
        &:not(:last-child) {
          border-bottom: 1px solid rgba(11, 53, 83, 0.2);
        }
      }
    }
  }
}
</style>
