<script setup>
import { defineRule } from "vee-validate";
import { ref, watch, computed } from "vue";
import { maska as vMaska, mask, create as createMask } from "maska";

const props = defineProps({
    modelValue: {
        type: String,
    },
    rules: {
        type: Object,
        default: {},
    },
    name: {
        type: String,
        required: true,
    }
})

const emit = defineEmits(["update:modelValue"]);

const mobileinput = ref(null);

const createdMask = ref(null)

const startLetter = computed(() => {
  return props.modelValue?.charAt(0)
})

watch(
  [startLetter, mobileinput],
  ([valStartLetter, valMobileinput], []) => {
    if (valStartLetter === undefined || !mobileinput.value) return;

    if (valStartLetter === "") return createdMask?.value?.destroy();

    if (valStartLetter.startsWith("+")) {
      createdMask.value = createMask(mobileinput.value, {
        mask: "+##############",
      });
    } else {
      createdMask.value = createMask(mobileinput.value, {
        mask: '["## ## ##", "## ## ### ###"]',
      });
    }
  },
  { immediate: true }
);

const mobileRule = defineRule("mobileRule", (value) => {
    if(value == undefined) return

    if (value.startsWith("+")) {
    } else {
        const valWithoutSpace = value.split(/\s/).join("");
        if (valWithoutSpace.length < 6 || valWithoutSpace.length > 10)
        return "You can use only  6-digit or 10-digit format";
    }
    return true;
});
</script>

<template>
  <Field
    :value="modelValue"
    @input="emit('update:modelValue', $event.target.value)"
    :name="name"
    v-slot="{ field }"
    type="text"
    :rules="{
        ...rules,
        mobileRule,
    }"
    class="input_component"
    label="phone"
    placeholder="Phone*"
  >
    <input
      v-bind="field"
      class="input_component"
      label="phone"
      placeholder="Use 6-digit or 10-digit format"
      ref="mobileinput"
    />
  </Field>
</template>
