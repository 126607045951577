import { AJAX_POST, AJAX_GET, AJAX_PUT, AJAX_DELETE } from '../../helpers/ajax'

export default {

  async create(payload){
    return await AJAX_POST(`my-jobs`, payload)
  },

  async get(id){
    return await AJAX_GET(`/my-jobs/${id}`)
  },

  async getSummary(id){
    return await AJAX_GET(`/my-jobs/${id}/summary`)
  },

  async getMargins(id){
    return await AJAX_GET(`/my-jobs/${id}/margins`)
  },

  async setMargins(id, payload){
    return await AJAX_POST(`/my-jobs/${id}/margins`, payload)
  },

  async changeCustomer(id, payload){
    return await AJAX_PUT(`/my-jobs/${id}/change-customer`, payload)
  },

  async update(id, payload){
    return await AJAX_PUT(`/my-jobs/${id}`, payload)
  },

  async placeOrder(id){
    return await AJAX_POST(`/my-jobs/${id}/place-order`)
  },

  async getPriceLists(id) {
    return await AJAX_GET(`my-jobs/${id}/price-lists`)
  },

}
