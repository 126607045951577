<script setup>
import { computed, ref, onBeforeMount, watch, nextTick, onMounted } from "vue";
import SingleAddressComponent from "@/components/commons/NewSingleAddressComponent";
import Input from "@/components/inputs/Input";
import api from "@/services/customers.service";
import { useEventBus } from "@vueuse/core";
import CustomerAPI from "@/routes/Customer/CustomerAPI.js";
import { useStore } from "vuex";
import SingleContactTileComponent from "@/components/customer/SingleContactTileComponent";
import CreateNewCardComponent from "@/components/commons/CreateNewCardComponent";
import CreateNewContactModal from "/src/views/Customer/CreateNewContactModal.vue";
import UpdateContactModal from "/src/views/Customer/UpdateContactModal.vue";
import PhoneNumber from "./PhoneNumber.vue";

const INDIVIDUAL_TYPE = 'Individual'
const BUSINESS_TYPE = 'Business'

const props = defineProps({
  selected: {
    type: Object,
    default: null,
  },
  customerId: {
    type: Number,
    default: null,
  },
  showBackButton: {
    type: Boolean,
    default: true,
  },
  createButtonText: {
    type: Text,
    default: 'Create'
  },
  updateButtonText: {
    type: Text,
    default: 'Update'
  }
});

const emits = defineEmits(["customerSaved", "back"]);
const processing = ref(false);
const store = useStore();

watch(processing, (val, oldVal) => {
  store.commit("preloader/preloader", val);
});

// const editorData = ref(_.cloneDeep((props.selected || {})))
const editorData = ref({});
const updateCustomerEvent = useEventBus("update-customer");
const billingAddressSameAsAbove = ref(true);
const contactTypes = ref([]);
const createNewContactModal = ref(null);
const updateContactModal = ref(null);

const state = computed(() => store.getters["registration/state"]);
onBeforeMount(async () => {
  if (props.customerId) {
    processing.value = true;
    let data = (await CustomerAPI.getDealerSingleCustomer(props.customerId)).data.data;
    contactTypes.value = (await CustomerAPI.activeContactTypes()).data.data;

    editorData.value = data;
    processing.value = false;

    billingAddressSameAsAbove.value = data?.billing_address?.is_same ?? false;
  }else {
    editorData.value.type = "Individual"
  }
});

watch(billingAddressSameAsAbove, (val, oldVal) => {
  console.log("CHanges....", val);
  editorData.value.billing_address = {
    ...editorData.value.billing_address,
    is_same: val,
  };
});


const address = computed({
  get: () => {
    return (
      {
        address: editorData.value?.address,
        suburb: editorData.value?.suburb,
        postcode: editorData.value?.postcode,
        state: editorData.value?.state,
      } ?? {}
    );
  },
  set: (value) => {
    editorData.value = { ...editorData.value, ...value };
  },
});

const billingAddress = computed({
  get: () => {
    return (
      {
        address: editorData.value?.billing_address?.address,
        suburb: editorData.value?.billing_address?.suburb,
        postcode: editorData.value?.billing_address?.postcode,
        state: editorData.value?.billing_address?.state,
      } ?? {}
    );
  },
  set: (value) => {
    const billing = { ...editorData.value.billing_address, ...value };
    editorData.value = { ...editorData.value, billing_address: billing };
  },
});

const tradingNameIsSameAsBusinessName = computed({
  get() {
    return editorData.value.business_name === editorData.value.trading_name;
  },
  set(newValue) {
    editorData.value.trading_name = editorData.value.business_name;
  },
});

function closeEditor() {
  emits('back')
}

async function deleteContact(itemIndexToBeRemoved) {

processing.value = true
try {
  (await CustomerAPI.dealerCustomerContactDelete(editorData.value.id, editorData.value.contacts[itemIndexToBeRemoved].id)).data.data
  editorData.value.contacts.splice(itemIndexToBeRemoved, 1)
} catch (error) {
  store.dispatch("errors/setErrors", error.response.data);

}
processing.value = false
}

async function updateAContact(updatedContactDetails, hasAddress) {
  processing.value = true;
  try {
    let contact_address = {...updatedContactDetails.contact_address, ...( updatedContactDetails?.contact_address?.state?.state_id ? {state: updatedContactDetails.contact_address.state.state_id}: {})}

    if(!hasAddress) {
      contact_address = {}
    }

    await CustomerAPI.dealerCustomerContactUpdate(
      editorData.value.id,
      updatedContactDetails.id,
      // updatedContactDetails
      {...updatedContactDetails, contact_address}
    );
    editorData.value.contacts = (
      await CustomerAPI.getDealerSingleCustomer(editorData.value.id)
    ).data.data.contacts;
  } catch (error) {
    store.dispatch("errors/setErrors", error.response.data);
  }
  processing.value = false;
}

async function newContactToStore(newContactDetails, hasAddress) {
  console.log('CustomerForm newcontactoostore>>>>>>>>>>', createNewContactModal.value)


  processing.value = true;

  try {
    let contact_address = {...newContactDetails.contact_address, ...( newContactDetails?.contact_address?.state?.state_id ? {state: newContactDetails.contact_address.state.state_id}: {})}

    if(!hasAddress) {
      contact_address = {}
    }

    await CustomerAPI.dealerCustomerContactCreat(editorData.value.id, {...newContactDetails, contact_address});
    editorData.value.contacts = (
      await CustomerAPI.getDealerSingleCustomer(editorData.value.id)
    ).data.data.contacts;
    createNewContactModal.value.close()
    // createNewContactModal.value.close()
  } catch (error) {
    store.dispatch("errors/setErrors", error.response.data);
  }
  // this.stopLoader()
  processing.value = false;
}

async function saveCustomer() {
  console.log("save");
  processing.value = true;
  const data = _.cloneDeep(editorData.value);

  if (data.billing_address && data.billing_address.is_same) {
    delete data.billing_address;
  }

  if (data.billing_address && !data.billing_address.is_same) {
    data.billing_address.state = data?.billing_address?.state?.state_id;
  }

  if(data.type === BUSINESS_TYPE) {
    data.name = ''
    data.last_name = ''
    // delete data.name
    // delete data.last_name
  }else {
    data.business_name = ''
    data.trading_name = ''
    // delete data.business_name
    // delete data.trading_name
  }

  if (!editorData.value.id) {
    let result = await api.createCustomer(data);
    if (result.success) {
      editorData.value.id = result.id;
      emits("customerSaved", editorData.value);
    }
  } else {

    let result = await api.updateCustomer(data);
    if (result.success) {
      emits("customerSaved", editorData.value);

      updateCustomerEvent.emit();
      //   closeEditor()
    }
  }
  processing.value = false;
}
</script>

<template>
  <Form
    v-slot="{ errors }"
    ref="editForm"
    autocomplete="off"
    class="form form_col"
    id="edit_contact"
    @submit="saveCustomer"
  >
    <div class="modal__content__wrapper">
      <div class="editor__header">
        <h3 class="editor__back" @click="closeEditor" v-if="showBackButton">
          <svg-icon name="solid/arrow-left" title="Back to search" />
        </h3>
        <h2 v-if="editorData.id">{{ editorData.type === INDIVIDUAL_TYPE? editorData.name: '' }} {{ editorData.type === INDIVIDUAL_TYPE? editorData.last_name: editorData.trading_name }}</h2>
        <h2 v-else>New Customer</h2>
      </div>
      <div class="editor__content tw-mt-4">
        <h3 class="tw-mb-4 titlePage_green">Customer Type</h3>
        <div class="tw-flex" :class="[editorData.id? 'tw-pointer-events-none tw-opacity-50 tw-grayscale tw-filter': '']">
          <label class="form__label form__label_row">
            <input
              type="radio"
              class="form__radio"
              hidden
              value="Individual"
              v-model="editorData.type"
              required
              :checked="editorData.type === INDIVIDUAL_TYPE"
              :disabled="editorData.id"
            />
            <span class="form__radio_icon"></span>
            <span class="form__labelTitle">Individual</span>
          </label>
          <label class="form__label form__label_row">
            <input
              type="radio"
              class="form__radio"
              value="Business"
              v-model="editorData.type"
              hidden
              required
              :checked="editorData.type === BUSINESS_TYPE"
              :disabled="editorData.id"
            />
            <span class="form__radio_icon"></span>
            <span class="form__labelTitle">Company</span>
          </label>
        </div>
        <div v-if="editorData.type" class="tw-mt-4">
          <div
            v-if="editorData.type == INDIVIDUAL_TYPE"
            class="tw-flex tw-flex-col justify-content-between flex-wrap"
          >
            <div>
              <label class="form__label">
                <span class="">First Name*</span>
                <Field
                  v-model="editorData.name"
                  :rules="{ required: true, max: 50 }"
                  class="input_component"
                  label="first name"
                  name="name"
                  placeholder="First Name*"
                  type="text"
                />
                <span class="form__error">{{ errors.name }}</span>
              </label>

              <label class="form__label">
                <span class="">Last Name*</span>
                <Field
                  v-model="editorData.last_name"
                  :rules="{ required: true, max: 128 }"
                  class="input_component"
                  label="last name"
                  name="last_name"
                  placeholder="Last Name*"
                  type="text"
                />
                <span class="form__error">{{ errors.last_name }}</span>
              </label>
              <h3 class="tw-mb-4 titlePage_green">Contact Details</h3>
              <label class="form__label">
                <span class="">Phone*</span>
                <PhoneNumber v-model="editorData.phone" name="phone" :rules="{required: editorData.type === INDIVIDUAL_TYPE}"/>
                <span class="form__error">{{ errors.phone }}</span>
              </label>

              <label class="form__label">
                <span class="">Email*</span>
                <Field
                  v-model="editorData.email"
                  :rules="{ required: true, email: true, max: 50 }"
                  class="input_component"
                  name="email"
                  placeholder="Email"
                  type="text"
                />

                <span class="form__error">{{ errors.email }}</span>
              </label>
            </div>

            <h3 class="tw-mb-4 titlePage_green">Address</h3>

            <SingleAddressComponent
              ref="individualAddressREFER"
              class="details_input"
              :suburb-and-post-code-in-same-raw="true"
              :address="address"
              @update:address="address = $event"
            />
          </div>
          <div v-else class="tw-flex tw-flex-col justify-content-between flex-wrap">
            <div>
              <div class="d-flex justify-content-between flex-wrap">
                <label class="form__label">
                  <span class="">Business Name*</span>
                  <Field
                    v-model="editorData.business_name"
                    :rules="{ required: editorData.type === BUSINESS_TYPE, max: 191 }"
                    class="input_component"
                    label="business name"
                    name="business_name"
                    placeholder="Business Name*"
                    type="text"
                  />
                  <span class="form__error">{{ errors.business_name }}</span>
                </label>
              </div>
              <div class="d-flex justify-content-between flex-wrap">
                <label class="form__label">
                  <span class="">Trading Name*</span>
                  <Field
                    v-model="editorData.trading_name"
                    :rules="{ required: editorData.type === BUSINESS_TYPE, max: 191 }"
                    class="input_component"
                    label="trading name"
                    name="trading_name"
                    placeholder="Trading Name"
                    type="text"
                  />
                  <span class="form__error">{{ errors.trading_name }}</span>
                </label>

                <label class="label-switch switch-success">
                  <input
                    id="status"
                    v-model="tradingNameIsSameAsBusinessName"
                    class="switch switch-bootstrap status"
                    name="status"
                    type="checkbox"
                    value="1"
                  />
                  <span class="lable"> Same as business name</span>
                </label>
              </div>
              <div class="d-flex justify-content-between flex-wrap">
                <label class="form__label">
                  <span class="">ABN</span>
                  <Field
                    v-model="editorData.abn"
                    :rules="{ max: 11 }"
                    class="input_component"
                    label="abn"
                    name="abn"
                    placeholder="ABN"
                    type="text"
                  />
                  <span class="form__error">{{ errors.abn }}</span>
                </label>
              </div>
              <div class="d-flex justify-content-between flex-wrap">
                <label class="form__label">
                  <span class="">ACN</span>
                  <Field
                    v-model="editorData.acn"
                    :rules="{ max: 9 }"
                    class="input_component"
                    label="acn"
                    name="acn"
                    placeholder="ACN"
                    type="text"
                  />
                  <span class="form__error">{{ errors.acn }}</span>
                </label>
              </div>
              <h3 class="titlePage_green">Contact Details</h3>
              <div class="d-flex justify-content-between flex-wrap">
                <label class="form__label">
                  <span class="">Company phone</span>
                  <PhoneNumber v-model="editorData.phone" name="phone" />
                  <span class="form__error">{{ errors.phone }}</span>
                </label>
              </div>

              <label class="form__label">
                <span class="">Company email*</span>
                <Field
                  v-model="editorData.email"
                  :rules="{ required: true, email: true, max: 50 }"
                  class="input_component"
                  name="email"
                  placeholder="Email"
                  type="text"
                />

                <span class="form__error">{{ errors.email }}</span>
              </label>
            </div>
            <div class="tw-flex tw-flex-col tw-gap-4">
              <h3 class="titlePage_green">Address</h3>
              <SingleAddressComponent
                ref="businessAddressREFER"
                class="details_input"
                :suburb-and-post-code-in-same-raw="true"
                :address="address"
                @update:address="address = $event"
              />
              <div>
                <h3 class="tw-mb-4 titlePage_green">Billing Address</h3>

                <label class="label-switch switch-success">
                  <input
                    id="status"
                    v-model="billingAddressSameAsAbove"
                    class="switch switch-bootstrap status"
                    name="status"
                    type="checkbox"
                    value="1"
                  />
                  <span class="lable"> Billing address is same as above</span>
                </label>
              </div>
              <SingleAddressComponent
                v-if="!billingAddressSameAsAbove"
                ref="businessAddressREFER"
                class="details_input"
                :suburb-and-post-code-in-same-raw="true"
                :address="billingAddress"
                @update:address="billingAddress = $event"
              />
            </div>

            <!-- Contacts starts -->
            <div v-if="customerId" class="tw-mt-4">
              <h3 class="tw-mb-4 titlePage_green">Contacts</h3>

              <SingleContactTileComponent
                v-for="(contact, index) in editorData?.contacts ?? []"
                :key="index"
                :contact="contact"
                :contactTypes="contactTypes"
                :index="index"
                :states="state"
                :singleColumn="true"
                @removeThisContact="(itemIndexToBeRemoved) => deleteContact(itemIndexToBeRemoved)"
                @updateThisContact="(itemIndexToBeUpdated) => $refs.updateContactModal.open(editorData.contacts[itemIndexToBeUpdated],itemIndexToBeUpdated)"

              />

              <CreateNewCardComponent
                :title="'create new contact'"
                @createNewModalOpen="$refs.createNewContactModal.open()"
              />

              <Teleport to="body">
                <div class="contact-modals">
                  <CreateNewContactModal
                    :hideLandline="true"
                    ref="createNewContactModal"
                    :contactTypes="contactTypes"
                    @newContact="(dataReceived, hasAddress) => newContactToStore(dataReceived, hasAddress)"
                  />
                  <UpdateContactModal
                    :hideLandline="true"
                    v-if="editorData.contacts.length > 0"
                    ref="updateContactModal"
                    :contactTypes="contactTypes"
                    @updatedContact="(dataReceived, hasAddress) => updateAContact(dataReceived, hasAddress)"
                  />
                </div>
              </Teleport>
            </div>
            <!-- Contacts ends -->
          </div>
          <div>
            <button
              class="btn btn_default"
              type="submit"
              :disabled="processing"
            >
              <svg-icon name="solid/floppy-disk"></svg-icon>
              {{ editorData && editorData.id ? updateButtonText : createButtonText }}
            </button>
            <button class="btn" type="button" @click="closeEditor">
              <svg-icon name="solid/ban"></svg-icon> Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>



<style scoped lang="scss">

::v-deep(.modal) {
  max-height: unset !important;
}

::v-deep(.orderItem__iconRight.times_icon) {
  margin-right: unset!important;
}


::v-deep(.input_component) {
  height: 40px;
}

</style>
