<template>
  <Modal v-model="show" class="confirmation-modal" @update:modelValue="$emit('update:show', false)">
    <div class="confirmation-modal__content" v-if="!customer">
      <Loader />
    </div>
    <div class="confirmation-modal__content" v-else>
      <div class="confirmation-modal__title">
        <slot name="message">
          <h2>Send {{ type }}</h2>
          <br>
          <p>Are you sure you want to send {{ type }} to the customer {{ customer.name }} on
            {{ customer.email }}</p>
          <br>

          <div>
            <p class="text_left">Note to the customer (optional):</p>
            <Textarea
              class="form__input"
              rows="5"
              @input="e => comment = e.target.value"
            />
          </div>
        </slot>
      </div>
      <div class="confirmation-modal__actions">
        <Button :data="{ type: 'primary', title: 'Cancel',icon: 'far fa-arrow-left' }"
                @click="$emit('cancelClicked'); $emit('update:show', false);"/>
        <Button :data="{ type: 'primary', title: 'Send', icon: 'fa fa-paper-plane', }"
                @click="send"
                :disabled="loading"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../elements/Modal'
import Button from '../form/Button'
import Input from "../inputs/Input";
import {ref} from 'vue'
import {computed} from "@vue/reactivity";
import api from '../../services/request.service'
import jobApi from '../../services/job.service'
import Loader from '../elements/Loader.vue'
import { useToast } from "vue-toastification";
import {useStore} from "vuex";

export default ({
  props: ['show', 'container_id', 'type'],
  components: {
    Modal,
    Button,
    Input,
    Loader
  },
  setup(props, {emit}) {
    const toast = useToast();
    const comment = ref(null)
    const customer = ref(null)
    const loading = ref(false)
    const store = useStore()

    async function send() {

      if (!loading.value) {
        loading.value = true

          api.sendQuoteOrEstimate(props.container_id, props.type, comment.value).then(async (response) => {
            toast.success(response.message)
            await store.commit('jobs/resetJobs', 'Quote')
            await store.commit('jobs/resetJobs', {status: 'Quote'})
            await store.dispatch('jobs/getJobsList', {status: 'Quote'})
            emit('onSend');
            emit('update:show', false);
            loading.value = false
          })

      }
    }

    loadCustomer()

    async function loadCustomer() {
      const res = await jobApi.getJobCustomerDetails(props.container_id)
      customer.value = res.customer
    }

    return {
      customer,
      comment,
      loading,
      send
    }
  },

})
</script>

<style lang="scss" scoped>
.confirmation-modal {

  height: 100%;
  top: 0;
  min-width: 100%;
  max-width: 100vw;

  &__title {
    padding: 10px;
    width: 100%;
    max-width: 90vw;
    height: auto;
    text-align: center;

    p {
      max-width: 100%;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin: 5px;
    }
  }

  ::v-deep .modal {
    max-width: 100%;

    min-height: 180px;

    max-height: 80vh;
  }

  ::v-deep .btn_outline {
    border-color: var(--PrimaryButtonColour);
    color: var(--PrimaryButtonColour);
    margin-right: 20px;
  }
}

</style>
