<template>
  <Sorting
    title="My Jobs"
    placeholder="Job Search"
    :sorting="sorting"
    :is-created="true"
    @sorting="sortJobs"
    @search="searchJobs"
    @create="createNewJob"
  ></Sorting>
<!--  <JobsCreate v-if="jobsCreateIsOpened" @close="jobsCreateIsOpened = false">-->
<!--  </JobsCreate>-->
  <div class="jobs_nav">
    <div class="jobs_nav__icon"
         :class="activeStatus === -1 && 'jobs_nav__icon--active'"
         @click="activeStatus = -1"
    >
      <svg-icon name="solid/pen-swirl"/>
    </div>
    <div v-for="(type, index) in statusList"
         class="jobs_nav__icon"
         :class="index == activeStatus && 'jobs_nav__icon--active'"
         @click="activeStatus = index"
    >
      <svg-icon :name="type.icon"/>
    </div>
  </div>
    <div class="jobs_list" v-if="screen.width > 768" >
      <OrdersListElement ref="ref_draft" :type="{name:'Draft', icon: 'solid/pen-swirl',ref:'ref_draft'}" >
      </OrdersListElement>
      <JobsListElement v-for="(type, index) in statusList" :ref="type.ref" :key="index" :type="type" v-dragscroll="{ target: '.item__container' }">
      </JobsListElement>
    </div>
  <div class="jobs_list jobs_list--mobile" v-if="screen.width <= 768">
    <OrdersListElement ref="ref_draft" :type="{name:'Draft', icon: 'solid/pen-swirl',ref:'ref_draft'}" v-if="activeStatus === -1" >
    </OrdersListElement>
    <JobsListElement :type="statusList[activeStatus]" v-else>
    </JobsListElement>
  </div>
</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue'
import { useStore} from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import Sorting from "@/components/sorting/Sorting";
import JobsListElement from "@/components/jobs/JobsListElement";
import JobsCreate from "@/components/jobs/JobsCreate";
import { dragscrollNext } from 'vue-dragscroll'
import { useScreen, useGrid } from 'vue-screen'
import OrdersListElement from "@/components/portal-orders/OrdersListElement.vue";
import _ from "lodash";

export default ({
  components: {
    Sorting,
    JobsListElement,
    JobsCreate,
    OrdersListElement
  },
  directives: {
    'dragscroll': dragscrollNext
  },
  setup() {
    const store = useStore()
    const screen = useScreen()
    const jobsCreateIsOpened = ref(false)
    const activeStatus = ref(-1)
    const router = useRouter()

    //Refs
    const ref_draft = ref(null)
    const ref_open = ref(null)
    const ref_awaiting_check_measure = ref(null)
    const ref_awaiting_order_placement = ref(null)
    const ref_in_production = ref(null)
    const ref_on_the_way = ref(null)
    const ref_awaiting_installation = ref(null)
    const ref_complete = ref(null)
    const ref_cancelled = ref(null)

    const sorting = reactive([
      {
        name: 'By date',
        sort: 'by_date'
      },
      {
        name: 'By suburb',
        sort: 'by_suburb'
      },
      {
        name: 'By price',
        sort: 'by_price'
      }
    ])
    const statusList = computed(() => store.state.jobs.statusList)
    const sortJobs = async (sort, direction) => {
      await store.commit('orders/ordersSorting', direction ? sort.sort :`-${sort.sort}`)
        await store.commit('jobs/jobsSorting', direction ? sort.sort :`-${sort.sort}`)
    }
    const searchJobs  = (search) => {
      if (search.length > 2 || search.length === 0) {
        setTimeout(() => {
          store.commit('orders/ordersSearch', search)
          store.commit('jobs/jobsSearch', search)
        }, 500)
      }
    }

    function createNewJob() {
      router.push("/my-orders/new")
    }

    return {
      sortJobs,
      searchJobs,
      createNewJob,
      statusList,
      sorting,
      jobsCreateIsOpened,
      activeStatus,
      screen,

      //Refs
      ref_draft,
      ref_open,
      ref_awaiting_check_measure,
      ref_awaiting_order_placement,
      ref_in_production,
      ref_on_the_way,
      ref_awaiting_installation,
      ref_complete,
      ref_cancelled,
    }
  },
})
</script>


<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.jobs_list {
 display: flex;
  margin: 0 20px;
 justify-content: space-between;
  overflow: auto;
  &>::v-deep div:last-child {
    margin-right: 0;
  }
  &--mobile {
    display: none;
  }
}
.jobs_nav {
  display: none;
}
@media (max-width: 768px) {
  .jobs_list {
    margin: 0;
    display: none;
    &>::v-deep div:last-child {
      margin: 0 auto;
    }
    &--mobile {
      display: flex;
    }
  }
  .jobs_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 47px;
      height: 47px;
      background: #0B3553;
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      border-right: 1px solid #0B3553;
      color: rgba(255, 255, 255, 0.8);
      transition-duration: 0.2s;
      &:first-child {
        border-left: 1px solid #0B3553;
      }
      &--active {
        border-left: 1px solid #F1C40F!important;
        border-right: 1px solid #F1C40F;
        background: #F1C40F;
        color: #1C2833;
      }
    }
  }
}
</style>
