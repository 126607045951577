<template>
  <base-modal
    v-model:show="createNewContactModalVisibility"
    :title="'New contact'"
  >
    <template v-slot:modal-content>
      <span class="text text_italic required__text">*Required Fields</span>
      <div class="row">
        <Form
          v-slot="{ errors }"
          autocomplete="off"
          class="form form_col"
          id="add_contact"
          @submit = "handleContact"
        >
          <div class="col-12">

            <label class="form__label mt-0">
              <span class="form__labelTitle">Type*</span>
              <Field as="select" v-model="newContact.contact_type_id"
                      :rules="'required'"
                      class="form__input form__select"
                      name="type"
                      placeholder="Type*"
              >
                <option :value="undefined" disabled hidden selected>Type*</option>
                <option
                  v-for="(item, index) in contactTypes"
                  :key="index"
                  :value="item.id"
                >{{ item.name }}
                </option>
              </Field>
              <span class="form__error">{{ errors.type }}</span>
            </label>

            <label class="form__label">
              <span class="form__labelTitle">First Name*</span>
              <Field v-model="newContact.first_name" :rules="{required:true, max:128}"
                     class="form__input" label="first name"
                     name="first_name"
                     placeholder="First Name*"
                     type="text"
              />
              <span class="form__error">{{ errors.first_name }}</span>
            </label>

            <label class="form__label">
              <span class="form__labelTitle">Last Name</span>
              <Field v-model="newContact.last_name" :rules="{max:128}" class="form__input"
                     label="last name"
                     name="last name"
                     placeholder="Last Name*"
                     type="text"
              />
              <span class="form__error">{{ errors.last_name }}</span>
            </label>

            <label class="form__label">
              <span class="form__labelTitle">E-mail*</span>
              <Field v-model="newContact.email" :rules="'email|required'"
                     class="form__input"
                     label="e-mail"
                     name="email"
                     placeholder="E-mail"
                     type="text"
              />

              <span class="form__error">{{ errors.email }}</span>
            </label>
            <label class="form__label">
              <span class="form__labelTitle">Mobile Number*</span>
              <Field v-model="newContact.phone"
                        :rules="{required: true, digits_without_whitespace: 10, min: 6}"
                        class="form__input"
                        label="phone number"
                        v-maska="'## ## ### ###'"
                        name="phone"
                        placeholder="Mobile Number*"
              />
              <span class="form__error">{{ errors.phone }}</span>
            </label>

            <label class="form__label" v-if="!hideLandline">
              <span class="form__labelTitle">Landline Number</span>
              <Field v-model="newContact.landline"
                        :rules="{digits_without_whitespace: 10, min: 6}"
                        class="form__input"
                        label="landline number"
                        v-maska="'## ## ### ###'"
                        name="landline"
                        placeholder="Landline Number*"
              />
              <span class="form__error">{{ errors.landline }}</span>
            </label>

            <!-- Address section starts -->

            <h4 class="titlePage titlePage_subtitle titlePage_green">Address</h4>

            <label class="label-switch switch-success pb-3">
              <input id="status" v-model="hasAddress"
                     class="switch switch-bootstrap status" name="status" type="checkbox" value="1">
              <span class="lable"> New address </span></label>

            <div>
              <NewSingleAddressComponent
                v-if="hasAddress"
                ref="addressRef"
                class="details_input"
                :suburb-and-post-code-in-same-raw="true"
                :address="newContact.contact_address"
                @update:address.sync="address = $event"
              />
            </div>
            <!-- Address section ends -->

          </div>

        </Form>
      </div>
    </template>
    <template v-slot:actions>
      <button class="btn btn_transparent mx-0 mb-0" form="add_contact" >
        <svg-icon name="solid/floppy-disk"/>
        Save
      </button>

    </template>

  </base-modal>
</template>

<script>
import CustomerAPI from '@/routes/Customer/CustomerAPI.js'
import SingleAddressComponent from '/src/components/customer/SingleAddressComponent'
import GlobalMixins from "../../Mixins/GlobalMixins";
import {mapGetters} from "vuex";
import { maska } from 'maska'
import NewSingleAddressComponent from '../../components/commons/NewSingleAddressComponent.vue';

export default {
  name: "CreateNewContactModal",
  directives: {
    maska
  },
  mixins : [GlobalMixins],
  data() {
    return {
      createNewContactModalVisibility: false,
      newContact: {
        contact_type_id: undefined,
        contact_address: {}
      },
      // address: {},
      hasAddress: false,
      validAddress: false
    }
  },
  props: {
    contactTypes: {
      required: true,
      type: Array
    },
    hideLandline: {
      default: false,
      type: Boolean
    }
  },
  components: {
    SingleAddressComponent,
    NewSingleAddressComponent,
},
  methods: {
    updateIsValidAddress(value) {
      this.validAddress = value
    },
    updateAddress(value) {
      this.address = value
    },
    close() {
      this.createNewContactModalVisibility = false
    },
    async open() {

      this.newContact = {
        contact_type_id: undefined,
        contact_address: {}
      }
      this.hasAddress = false
      this.createNewContactModalVisibility = true
    },
    async handleContact() {
      return this.$emit('newContact', this.newContact, this.hasAddress);
    }
  },
  computed: {
    ...mapGetters({
      "state": 'registration/state',
    }),
    address: {
      get: function() {
        return (
          {
            address: this.newContact?.contact_address?.address,
            suburb: this.newContact?.contact_address?.suburb,
            postcode: this.newContact?.contact_address?.postcode,
            state: this.newContact?.contact_address?.state,
          } ?? {}
        );
      },
      set: function(value) {
        this.newContact.contact_address = { ...this.newContact.contact_address, ...value };
      },
    }
  },
}
</script>

<style scoped>

</style>
