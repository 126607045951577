<script setup>
import  { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import  { ref } from 'vue'
import dealerOrderApi from './../../services/dealerOrder.service.js'
import CustomerSelector from "@/components/customer/CustomerSelector";
import Modal from './../../components/elements/Modal'
import Loader from './../../components/elements/Loader.vue'

const props = defineProps({
    orderId: {
        type: Number,
        require: true,
    },
    show: {
        type: Boolean,
        default: false
    },
    redirectToJobsPage: {
        type: Boolean,
        default: true
    }
})
const emit = defineEmits(['completed', 'update:show'])
const store = useStore()
const router = useRouter()
const convertingToJob = ref(false)


async function convertToJob(customer) {
    //store.commit('preloader/preloader', true);
    convertingToJob.value = true
    const start = new Date()
    let result = await dealerOrderApi.convertToJob(props.orderId, {customer_id: customer.id})
    if (result.success){
      setTimeout(() => {
        convertingToJob.value = false
        emit('completed', result)
        if(props.redirectToJobsPage) router.push({name: 'job', params: {id: result.job_id}})
      }, 1000 - (new Date() - start))
    }
    // store.commit('preloader/preloader', false);
}


</script>

<template>
    <Modal :modelValue="show" @update:modelValue="$emit('update:show', false)">
        <div class="modal-wrap">
            <CustomerSelector @selectCustomer="convertToJob" />
        </div>
    </Modal>

  <div v-if="convertingToJob" style="z-index: 99999;" class="cloning-loader tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-black tw-z-20 tw-bg-opacity-70 tw-flex tw-flex-col tw-items-center tw-justify-center">
    <Loader class="estimate__loader main tw-text-white" customClass="big tw-text-white">
      <p class="loading-text tw-max-w-3xl tw-font-sans tw-text-xl md:tw-text-2xl tw-text-gray-200">Your Draft is being converted to a Job</p>
    </Loader>
  </div>

</template>

<style scoped lang="scss">
 .modal-wrap {
    max-height: 85vh;
    overflow: auto;
 }

</style>
