<template>
  <SimpleModal class="check_calendar_modal" @close="$emit('close')">
    <h4 class="check_calendar_modal__header">
      <slot name="header">
        Jobs schedule
      </slot>
    </h4>
    <div class="check_calendar_modal__body">
        <Calendar
        />
    </div>
  </SimpleModal>
</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue'
import SimpleModal from "@/components/modals/SimpleModal";
import Calendar from "@/components/commons/Calendar";
import {useStore} from "vuex";

export default ({
  components: {
    Calendar,
    SimpleModal
  },
  emits: ['close'],
  setup() {
    const store = useStore()

    return {
    }
  }
})
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";

.check_calendar_modal {
  ::v-deep .simple_modal__container {
    padding: 52px 40px 60px;
    min-height: 290px;
    width: 500px;
  }
  &__header {
    font-weight: bold;
    font-size: 25px;
    line-height: 100%;
    color: #1C2833;
    padding-bottom: 15px;
  }
}
@media (max-width: 768px) {
  .check_calendar_modal {
    ::v-deep .simple_modal__container {
      padding: 40px 20px 50px;
      width: 100%;
    }
    &__header {
      font-size: 21px;
      padding-bottom: 30px;
    }
  }
}

</style>
