import { AJAX_GET, AJAX_POST } from '../helpers/ajax'
import {AJAX_DELETE} from "../helpers/ajax.js";

export default {
    async getCurrentUser() {
        return await AJAX_POST('/current-user')
    },
    async resendVerifyEmail(payload) {
        return await AJAX_POST('/resend-verify-email')
    },
    async request(id) {
        return await AJAX_POST(`/my-estimates/${id}/request`)
    },
    async sendNewAddress(payload) {
        return await AJAX_POST(`/my-estimates/${payload.id}/request/new-address`, payload)
    },
    async cloneEstimate(id, payload) {
        return await AJAX_POST(`/my-estimates/actions/${id}/clone`)
    },
    async deleteEstimate(id) {
        return await AJAX_DELETE(`/my-estimates/actions/${id}`)
    },
    async addToExistingEstimate(url, params) {
        return await AJAX_POST(url, params)
    },
    async createNewEstimate(url, params) {
        return await AJAX_POST(url, params)
    },
    async sendQuoteOrEstimate(id, type, comment){
      return await AJAX_POST(`/my-jobs/${id}/send-${type}`, {comment: comment})
    }
}
