<template>
  <SimpleModal class="check_completed_modal" @close="$emit('close')">
    <h4 class="check_completed_modal__header">
      <slot name="header">
        <svg-icon name="solid/tape"/>
        Check measure completed
      </slot>
    </h4>
    <div class="check_completed_modal__body">
      <slot name="body">
        <div class="body__notification">
          <div>
            Do you confirm the check measure has been<br>
            completed and product sizes updated on the quotes/estimates?
          </div>
        </div>
      </slot>
    </div>
    <div class="check_completed_modal__footer">
      <slot name="footer">
        <ButtonMain  @click="changeStatus">
          <svg-icon name="solid/check"/>
          Check measure completed
        </ButtonMain>
      </slot>
    </div>
  </SimpleModal>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import ButtonMain from "@/components/buttons/ButtonMain";
import SimpleModal from "@/components/modals/SimpleModal";
import {useStore} from "vuex";

export default ({
  props: ['id','job'],
  components: {
    ButtonMain,
    SimpleModal
  },
  emits: ['close'],
  setup(props, {emit}) {
    const store = useStore()
    let changeStatus = async () => {
      await store.commit('jobs/currentActionPerformingJobs', props.job)
      await store.dispatch('jobs/changeJobsStatus', {id: props.id, status: 'mark-as-check-measure-completed'} )
      emit('close')
    }
    return {
      changeStatus
    }
  }
})
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";

.check_completed_modal {
  ::v-deep .simple_modal__container {
    padding: 52px 40px 60px;
    text-align: center;
    min-height: 290px;
  }
  &__header {
    font-weight: bold;
    font-size: 25px;
    line-height: 100%;
    color: #1C2833;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 18px;
      margin-right: 5px;
      color: rgba(28, 40, 51, 0.8);
    }
  }
  &__body {
    .body {
      &__notification {
        font-size: 15px;
        color: rgba(28, 40, 51, 0.8);
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    padding-top: 40px;
  }
}
@media (max-width: 768px) {
  .check_completed_modal {
    ::v-deep .simple_modal__container {
      width: 100%;
      padding: 70px 20px 50px;
    }
    &__header {
      font-size: 21px;
      padding-bottom: 30px;
    }
    button {
      height: 40px;
    }
  }
}
</style>
