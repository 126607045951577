<template>
  <button class="button_sort"
          :class="isPicked ? 'button_sort--active' : 'button_sort--inactive'"
          @click="pickButton($event.target.value)">
    <p><slot>By region</slot></p>
      <div v-if="isPicked">
        <svg-icon v-show="sorting === true" name="solid/arrow-up"/>
        <svg-icon v-show="sorting === false" name="solid/arrow-down"/>
      </div>
  </button>
  <button class="button_sort_mob"
          :class="isPicked ? 'button_sort_mob--active' : 'button_sort_mob--inactive'"
          @click="pickButton($event.target.value)">
    <p><slot>By region</slot></p>
    <div class="d-flex">
      <div :class="sortingValue === false || (sortingValue == null &&sorting === false) ? 'font-weight-bold' : 'font-weight-normal'"><svg-icon name="solid/arrow-down"/></div>
      <div :class="sortingValue === true || (sortingValue == null && sorting === true) ? 'font-weight-bold' : 'font-weight-normal'"><svg-icon name="solid/arrow-up"/></div>
    </div>
  </button>
</template>

<script>
import { reactive, ref } from 'vue'

export default({
  components: {
  },
  props: {
    isPicked:{
      default: false
    },
    data:{
      default: {}
    },
    index:{
      default: null
    },
    notClickable: {
      default: false
    },
    sortingValue: {
      default: null
    },
  },
  emits: ['pick'],
  setup(props, { emit }) {
    let sorting = ref(!!props.data.group_by)
    let pickButton = () => {
      if (!props.notClickable) {
        if(props.isPicked) {
          sorting.value =! sorting.value
        } else {
          sorting.value = true
        }
      }
      emit('pick', sorting.value, props.data, props.index);
    }
    return {
      pickButton,
      sorting
    }
  },
})
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.button_sort {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  align-items: center;
  font-size: 15px;
  margin: 0 20px;
  height: 30px;
  border: 0;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
  &--active {
    background: rgba(241, 196, 15, 0.4);
    border-radius: 30px;
    color: #1C2833;
    padding: 10px 15px;
    &:hover {
      transition: color 0.2s;
      background: rgba(241, 196, 15, 0.6);
    }
  }
  &--inactive {
    background-color: transparent;
    color: #59B692;
    padding: 10px 0px;
    p {
      border-bottom: 1px dashed rgba(89, 182, 146, 0.7)
    }
    &:hover {
      transition-duration: 0.2s;
      color: #3F9775;
      p {
        border-bottom: 1px dashed transparent;
      }
    }
  }
  span {
    font-size: 12px;
    margin-left: 4px;
  }
  &_mob{
    display: none;
    width: 100%;
    justify-content: flex-start;
    white-space: nowrap;
    align-items: center;
    font-size: 13px;
    height: 40px;
    background-color: white;
    border: 0;
    &:not(:nth-child(2)){
      border-top: 1px solid rgba(11, 53, 83, 0.2);
    }
    color: rgba(28, 40, 51, 0.8);
    cursor: pointer;
    transition: 0.2s;
    white-space: nowrap;
    cursor: pointer;
    span {
      font-size: 11px;
      margin-left: 2px;
    }
    > div {
      margin-left: 3px;
    }
    &:hover {
      background: rgba(241, 196, 15, 0.3);
    }
    &--active {
      background: rgba(241, 196, 15, 0.3);
      font-weight: bold;
      color: #1C2833;
      .font-weight-normal {
        color: rgba(28, 40, 51, 0.6);
      }
    }
    @media (max-width: 768px) {
      display: flex;
    }
  }
}
</style>
