<template>
  <inline-svg fill="currentColor" class="pr-1" height="1em" :src="getSrc" title="Login" :keepDuringLoading="false"/>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
  components: {
    InlineSvg,
  },
  props: {
    name: {
      type: String,
      required:true
    }
  },
  computed: {
    getSrc () {
      let name = this.name || 'solid/ban'
      let icon = null
      try{
        icon = require(`@/assets/svgs/${name}.svg`)
      }catch (err) {
        icon =  require(`@/assets/svgs/solid/ban.svg`)
      }
      return icon
    }
  }
}
</script>
