<template>
  <div class="navigation container">
    <h2 class="title">{{ title }}<span class="title__badge" v-show="showQuantity">{{  items }}</span>
    </h2>
    <LinkReturn v-if="isCreated == true" path="" @click="create">
      <svg-icon name="solid/plus-circle"/>
      <p>Create</p>
    </LinkReturn>
    <div class="d-flex align-items-center">
      <div v-if="(!items || items >0) " class="filters filters--mobile" v-click-outside="function() {menuIsOpened = false}">
        <ButtonSort
          :is-picked="true"
          @pick="menuIsOpened =! menuIsOpened"
          :not-clickable = true
          :sortingValue = pickedSorting.value
        >
          {{pickedSorting?.name}}
          <div class="filters__icon"
               :class="menuIsOpened ? 'filters__icon--opened':''">
            <svg-icon  name="solid/chevron-down"/>
          </div>
        </ButtonSort>
        <div class="filters__dropdown"   v-if="menuIsOpened">
          <ButtonSort
            v-for="(element, index) in grouping"
            :is-picked="pickedGrouping.name === element.name"
            :data="element"
            :index="index"
            @pick="pickGrouping"
          >
            {{element.name}}
          </ButtonSort>
          <ButtonSort
            v-for="(element, index) in sorting"
            :is-picked="pickedSorting.name === element.name"
            :data="element"
            :index="index"
            @pick="pickSorting"
          >
            {{element.name}}
          </ButtonSort>
        </div>
      </div>

      <div v-if="(!items || items >0) " class="filters">
        <ButtonSort
          v-for="(element, index) in grouping"
          :is-picked="pickedGrouping.name === element.name"
          :data="element"
          :index="index"
          @pick="pickGrouping"
        >
          {{element.name}}
        </ButtonSort>

        <ButtonSort
          v-for="(element, index) in sorting"
          :is-picked="pickedSorting.name === element.name"
          :data="element"
          :index="index"
          @pick="pickSorting"
        >
          {{element.name}}
        </ButtonSort>
      </div>

        <slot name="extra-actions">

        </slot>

    <div  class="filters__search">
      <Input type="text"
             name="search"
             :icon="true"
             :placeholder="placeholder"
             :value="searchValue"
             @input="changeValue"

      >
<!--        @change="search"-->
      </Input>
      <svg-icon v-if="searchValue.length < 1" name="regular/search"/>
      <div v-else @click="remove"><svg-icon name="solid/times" /></div>
      <LinkReturn v-if="isSearch === true" class="filters__return"></LinkReturn>
    </div>
    </div>
  </div>

  <PageHeader :offset="20" elementClass="dealer-ui-my-orders">
    <div class="container navigation tw-flex tw-flex-wrap tw-justify-between tw-pb-5 tw-gap-2">
        <div class="tw-bg-white tw-flex tw-gap-4 tw-items-center tw-justify-between tw-w-full md:tw-w-auto tw-relative tw-border-b tw-border-double tw-border-gray-400 md:tw-border-none">
          <h2 class="title">{{ title }}<span class="title__badge" v-show="showQuantity">{{ items }}</span></h2>
          <div class="btn-action-wrap tw-flex tw-divide-x tw-divide-double tw-divide-gray-400 md:tw-divide-none">
            <div class="btn-link  md:tw-hidden" @click="showSearch = !showSearch">
              <span >
                <svg-icon name="solid/magnifying-glass" />
              </span>
            </div>

            <div class="btn-link  md:tw-hidden" @click="showSortAction = !showSortAction">
              <span >
                <svg-icon name="solid/arrow-down-arrow-up" />
              </span>
            </div>
            <router-link to="" class="link_return- btn-link tw-flex tw-items-center tw-gap-1.5 tw-w-9 tw-h-full tw-justify-center md:tw-w-auto" @click="create">
                <span class="tw-hidden md:tw-block">
                  <svg-icon name="solid/plus-circle" />
                </span>
                <span class="md:tw-hidden">
                  <svg-icon name="solid/plus" />
                </span>
                <p class="tw-hidden md:tw-block">Create new</p>
            </router-link>
          </div>
        </div>
        <div class="tw-flex- tw-items-center tw-px-3 tw-pb-2 md:tw-px-0 md:tw-pb-0 tw-w-full md:tw-w-auto md:tw-flex" :class="showSortAction || showSearch? 'tw-flex': 'tw-hidden'">
          <div class="md:tw-block tw-flex-1" :class="showSortAction? 'tw-block': 'tw-hidden'">
              <div v-if="(!items || items >0) " class="filters filters--mobile"
                  >
                  <ButtonSort :is-picked="true" @pick="menuInStickyNavOpen =! menuInStickyNavOpen" :not-clickable=true
                      :sortingValue=pickedSorting.value>
                      {{pickedSorting?.name}}
                      <div class="filters__icon" :class="menuInStickyNavOpen ? 'filters__icon--opened':''">
                          <svg-icon name="solid/chevron-down" />
                      </div>
                  </ButtonSort>
                  <div class="filters__dropdown" v-if="menuInStickyNavOpen">
                      <ButtonSort v-for="(element, index) in sorting" :is-picked="pickedSorting.name === element.name"
                          :data="element" :index="index" @pick="pickSorting">
                          {{element.name}}
                      </ButtonSort>
                  </div>
              </div>
              <div v-if="(!items || items >0) " class="filters">
                  <ButtonSort v-for="(element, index) in sorting" :is-picked="pickedSorting.name === element.name"
                      :data="element" :index="index" @pick="pickSorting">
                      {{element.name}}
                  </ButtonSort>
              </div>
            </div>

            <slot name="extra-actions">

            </slot>
              <div class="filters__search md:tw-block" :class="showSearch? 'tw-block': 'tw-hidden'">
                  <Input type="text" name="search" :icon="true" :placeholder="placeholder" :value="searchValue"
                      @input="changeValue">
                  </Input>
                  <svg-icon v-if="searchValue.length < 1" name="regular/search" />
                  <div v-else @click="remove">
                      <svg-icon name="solid/times" />
                  </div>
                  <LinkReturn v-if="isSearch === true" class="filters__return"></LinkReturn>
              </div>
        </div>
    </div>
  </PageHeader>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import ButtonSort from "@/components/buttons/ButtonSort";
import LinkReturn from "@/components/links/LinkReturn";
import Input from "@/components/inputs/Input";
import SimpleDropdown from "@/components/dropdown/SimpleDropdown";
import vClickOutside from "click-outside-vue3";
import PageHeader from '@/components/item-wizard/components/elements/PageHeader.vue'
import _ from "lodash"

export default ({
  props: ['items', 'title', 'showQuantity', 'placeholder', 'sorting','grouping', 'isCreated'],
  emits: ['sorting','search', 'remove', 'create','grouping'],
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    ButtonSort,
    LinkReturn,
    Input,
    SimpleDropdown,
    PageHeader,
  },
  setup( props, {emit}) {
    let pickedSorting = ref(props?.sorting[0] || {})
    let pickedGrouping = ref(props.grouping && props.grouping.length > 0 ? props.grouping[0] : {})
    if(props.grouping && props.grouping.length > 0) pickedSorting.value = {}
    const menuIsOpened = ref(false)
    const searchValue = ref('')
    const isRemove = ref(false)
    const isSearch = ref(false)
    const regions = ref(['Kallangur 4785', 'NORTH LAKES'])
    let pickSorting = (value, element, index ) => {
      pickedGrouping.value = {}
      pickedSorting.value = {name: element.name, value: value, sort: element.sort}
      emit('sorting', element, value);
    }
    let pickGrouping = (value, element, index ) => {
      pickedSorting.value = {}
      pickedGrouping.value = {name: element.name, value: value, group: element.sort}
      emit('grouping', element, value);
    }

    let changeValue = _.debounce((e) => {
      searchValue.value = e
      if(isRemove.value === false){
        emit('search', e)
      }
    }, 900)
    
    let create = async (e) => {
      emit('create');
    }
    let remove = (e) => {
      searchValue.value = ''
      isRemove.value = true
      emit('search', searchValue.value);
      setTimeout(() => isRemove.value = false, 100);
    }
    const menuInStickyNavOpen = ref(false)
    const showSearch = ref(false)
    const showSortAction = ref(false)
    return {
      regions,
      remove,
      searchValue,
      pickSorting,
      pickGrouping,
      pickedSorting,
      pickedGrouping,
      isSearch,
      changeValue,
      create,
      isRemove,
      menuIsOpened,
      menuInStickyNavOpen,
      showSearch,
      showSortAction,
    }
  },
})
</script>


<style lang="scss">

  .dealer-ui-my-orders.page-header-global {
    padding: 0;
    .navigation {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
</style>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";

.dealer-ui-my-orders.page-header-global {
    padding: 0;

    .navigation {
      .filters--mobile, .filters__search {
        width: auto;
        flex: 1;
      }
    }
    .container {
      @media(max-width: 768px) {
        padding: 0;
        background-color: #59B69233;
      }

    }
    h2{
      margin-top: 0;
      @media(max-width: 768px) {
        padding: 10px;
      }
    }

    .btn-action-wrap {
      @media(max-width: 768px) {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      }
    }
    .btn-link {
      color: #59B692CC;
      margin: 0;
      @media(max-width: 768px) {
        background-color: #59B692CC;
        color: #1C2833CC;
        width: 43px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }
      }

      p {
        color: #59B692;
        position: relative;
        &:before {
          position: absolute;
          content: '';
          width: 100%;
          bottom: 2px;
          left: 0px;
          border-bottom: 1px solid rgba(89, 182, 146, 0.5)
        }
      }
    }
  }
.navigation {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 56px;
  h2 {
    margin-top: 5px;
  }
  button {
    margin-top: 5px;
  }
  a {
    margin-top: 11px;
    margin-right: auto;
    margin-left: 10px;
    span {
      font-size: 20px;
    }
    & p {
      font-size: 15px;
      &:before {
        border-bottom: 1px dashed rgba(89, 182, 146, 0.7)
      }
    }
  }
.filters {
  display: flex;
  &--mobile {
    display: none;
  }
  &__search {
     position: relative;
    input {
      width: 350px;
    }
      span {
        position: absolute;
        right: 20px;
        font-size: 16px;
        color: rgba(28, 40, 51, 0.6);
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  &__return {
    position: absolute;
    bottom: -22px;
    left: 0;
  }
  }
}
.title {
  font-family: $bold;
  font-style: normal;
  font-weight: bold;
  display: inline-block;
  font-size: 31px;
  text-transform: uppercase;
  line-height: 100%;
  @media (max-width: 768px) {
    font-size: 27px;
  }
&__badge {
   margin-left: 10px;
   color: #F1C40F;
  @media (max-width: 768px) {
    margin-left: 5px;
  }
 }
}
@media (max-width: 768px) {
  .navigation {
    padding-top: 10px;
    padding-bottom: 25px;
    .filters {
        display: none;
      &--mobile {
        display: flex;
        flex-direction: column;
        position: relative;
        flex-shrink: 0;
        width: 140px;
        border-radius: 2px;
        position: relative;
        margin-right: 10px;

        > ::v-deep button {
          border-radius: 2px;
          border: 1px solid rgba(28, 40, 51, 0.3);
          &:hover {
            border: 1px solid rgba(28, 40, 51, 0.4);
          }
        }
      }
      &__search {
        input {
          width: 100%
        }
      }
      &__icon {
        position: absolute;
        right: 16px;
        font-size: 14px;
        top: 12px;
        color: #1C2833;
        transition-duration: 0.2s;
        &--opened {
          transform: rotate(180deg);
        }
      }
      &__dropdown{
        background: white;
        width: 100%;
        cursor: pointer;
        position: absolute;
        top: 40px;
        right: 0;
        z-index: 5;
        box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
        border-radius: 0px 0px 2px 2px;
        overflow: hidden;
      }
    }
    h2 {
      margin-top: 0;
      margin-bottom: 0;
    }
    a {
      margin-top: 3px;
      margin-right: 0;
      margin-left: auto;
    }
  }
  .title {
    font-size: 27px;
    margin-bottom: 10px;
    &__badge {
        margin-left: 5px;
    }
  }
}
</style>
