<template>
    <div class="form-group" :class="{error: data.error}">
        <label class="form-group__label" for="" v-if="data.label">{{ data.label }}<small v-if="data.suffix" class="label-suffix">&nbsp; {{data.suffix}}</small><slot name="label"></slot></label>
        <div class="form-group__input-container" :class="{'form-group__input-suf':data.suf}">
            <slot name="input">
                <input v-if="data.mask" class="form-group__input"
                  ref="inputRef"
                  :value="modelValue"
                  v-mask="data.mask"
                  :type="data.type"
                  :min="data.min"
                  :max="data.max"
                  :placeholder="data.placeholder"
                  :disabled="data.disabled"
                  :tabindex="data.tabindex"
                  @focus="data.error = '', $emit('focus')"
                  @blur="$emit('blur')"
                  @input="input"
                  @keydown.tab="$emit('handletab', $event)"
                  >
              <input v-else class="form-group__input"
                  ref="inputRef"
                  :value="modelValue"
                  :type="data.type"
                  :min="data.min"
                  :max="data.max"
                  :placeholder="data.placeholder"
                  :disabled="data.disabled"
                  :tabindex="data.tabindex"
                  @focus="data.error = '', $emit('focus')"
                  @blur="$emit('blur')"
                  @input="input"
                  @keydown.tab="$emit('handletab', $event)"
                  >
            </slot>
          <div class="suffix"  v-if="data.suf">{{ data.suf }}</div>
            <slot></slot>
        </div>
        <div class="form-group__error" v-if="data.error">{{ data.error }}</div>
    </div>
</template>

<script>

import { watch, ref, onMounted } from 'vue'
import {mask} from 'vue-the-mask'

export default({
    directives: {mask},
    props: ['data', 'modelValue', 'filter','classes', 'focusOnMount'],
    setup(props, { emit }) {
        function input(event) {
            let data = event.target.value
            const minVal = props.data?.min ?? null
            if(props.data.type === 'number' && minVal !== null && minVal >=0 && (parseFloat(data) < minVal) ){
                data= data.replace('-', '');
            }
            if(props.filter) data = props.filter(data)


            if(props.data?.sanitize) {
                data = props.data.sanitize(data)
            }

            emit('update:modelValue', data)
        }

        const inputRef = ref(null)

        onMounted(() => {
            if(props.focusOnMount) inputRef.value.focus()
        })

        return {
            input,
            inputRef,
        }
    },
})
</script>


<style scoped lang="scss">
.suffix {
  padding-left:8px;
  padding-top: 8px;
}
.form-group {
    width: 100%;
    &__label {
        font-weight: bold;
        font-size: 17px;
        line-height: 140%;
        display: block;
        margin: 0 0 2px 4px;
        text-align: left;
        white-space: nowrap;
        display: flex;
        align-items: center;
        ::v-deep .help_topic {
            margin-left: 5px;
            display: inline-block;
        }
    }

    &__input {
        border: 1px solid rgba(11, 53, 83, 0.2);
        border-radius: 2px;
        height: 40px;
        width: 100%;
        padding: 0 13px 0 15px;
        font-weight: bold;
        font-size: 17px;
        line-height: 140%;
        &::placeholder {
            font-style: italic;
            font-weight: normal;
        }
        &-container {
            position: relative;
        }
      &-suf {
        display:flex;
      }
    }
    &__error {
        font-size: 14px;
        line-height: 140%;
        color: #DB5151;
        text-align: left;
    }

}

</style>
