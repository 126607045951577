<template>
  <div>
    <Form
      v-slot="{ errors }"
      autocomplete="off"
      @submit="formValid"
    >
      <div class="position-relative">
        <div>Street:</div>
        <GMapAutocomplete
          :options="{ componentRestrictions: { country: 'aus' },fields: ['address_components']}"
          :value="address.address"
          class="input_component"
          placeholder="Start typing address"
          :class="showAddressError && 'input_component--error'"
          @place_changed="setFullAddress"
          v-if="validApiKey"
          @input="onGmapInputChange"
          id="gmap-input"
        >
        </GMapAutocomplete>
        <Input
            v-else
            :value="address.address"
            @input="value => updateAddressWithoutGmap(value)"
            :rules="{max: 128}"
            class="input_component"
            name="address"
            type="text"
            placeholder="Start typing address"
          />
        <div v-if="showAddressError" class="input_component--error-text">The address field is required</div>
      </div>
      <div class="tw-grid tw-grid-cols-2 tw-gap-4 tw-mt-4" v-if="suburbAndPostCodeInSameRaw">
        <div class="">
          <div class="position-relative">
            <div>Suburb:</div>
            <Input
              :value="address.suburb"
              @input="value => $emit('update:address', {...address, suburb: value})"
              :rules="{max: 128}"
              class="input_component"
              name="suburb"
              type="text"
            />
<!--            @input="value => address.suburb = value"-->
            <span class="input_component--error-text">{{ errors.suburb }}</span>
          </div>
        </div>
        <div class="">
          <div class="position-relative">
            <div>Postcode:</div>
            <Input
              :value="address.postcode"
              @input="value => $emit('update:address', {...address, postcode: value})"
              :rules="{digits: 4}"
              class="input_component"
              name="postcode"
              type="text"
              mask="####"
            />
<!--            @input="value => address.postcode = value"-->
            <span class="input_component--error-text">{{ errors.postcode }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="position-relative">
          <div>Suburb:</div>
          <Input
            :value="address.suburb"
            @input="value => address.suburb = value"
            :rules="{max: 128}"
            name="suburb"
            type="text"
          />
          <span class="input_component--error-text">{{ errors.suburb }}</span>
        </div>
        <div class="position-relative">
          <div>Postcode</div>
          <Input
            :value="address.postcode"
            @input="value => address.postcode = value"
            :rules="{digits: 4}"
            name="postcode"
            type="text"
            mask="####"
          />
          <span class="input_component--error-text">{{ errors.postcode }}</span>
        </div>
      </div>
      <div class="position-relative tw-mt-4">
        <div>State</div>
        <SelectDropdown
        :list="state"
        :list-is-object="true"
        :selected="selectedState"
        @select="value => $emit('update:address', {...address, state: value})"
        ></SelectDropdown>
      </div>
      <button ref="address_create" class="d-none" type="submit"/>
    </Form>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Input from "@/components/inputs/Input";
import SelectDropdown from "@/components/dropdown/SelectDropdown";
import axios from 'axios';
import store from '../../store/store';
import {computePosition, autoUpdate} from '@floating-ui/dom';

const autoCompleteListSelector = '.pac-container.pac-logo.hdpi'
const gmapInputSelector = '#gmap-input'

export default {
  name: "NewSingleAddressComponent",
  components: {
    Input,
    SelectDropdown
  },
  data() {
    return {
      validApiKey: false,
      key:0,
      valid: false,
      showAddressError: false,
      plainAddress: ''
    }
  },
  props: {
    address: {
      required: true,
      type: Object
    },
    suburbAndPostCodeInSameRaw : {
      default :false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      "state": 'registration/state',
    }),
    selectedState(){
      return typeof this.address.state === 'object'? this.address.state: (this.state ?? []).find(x => x.state_id === this.address.state)
    }
  },
  created() {
    const apiKey = process.env.VUE_APP_GOOGLE_API_KEY
    store.commit('preloader/preloader', true);

    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=New+York&key=${apiKey}`)
        .then(response => {
            if ( response.data?.error_message) {
                console.log('API Key is not valid');
                this.validApiKey = false
            } else {
                console.log('API Key is valid');
                this.validApiKey = true
            }
            store.commit('preloader/preloader', false);
        })
        .catch(error => {
            console.error('Error occurred while checking API key validity:', error);
            this.validApiKey = false
            store.commit('preloader/preloader', false);
        });

  },
  methods: {
    onGmapInputChange(e) {
      this.$emit('update:address', {...this.address, address: e.target.value})
    }, 
    updateAddressWithoutGmap(value) {
      this.plainAddress = value.replaceAll(' ', '')
      this.address.address = value
      this.$emit('update:address', {...this.address, address: value})
    },
    setFullAddress(selectedAddress) {
      console.log(selectedAddress)
      let mainAddress = ''
      let addressFullComponent = selectedAddress.address_components

      let subpremise = _.find(addressFullComponent, {types: ["subpremise"]})

      let street_number = _.find(addressFullComponent, {types: ["street_number"]})
      let route = _.find(addressFullComponent, {types: ["route"]})
      let suburb = _.find(addressFullComponent, {types: ['locality', 'political']})
      let postal_code = _.find(addressFullComponent, {types: ["postal_code"]})
      let state = _.find(addressFullComponent, {types: ['administrative_area_level_1', 'political']})

      if (subpremise) mainAddress += `${subpremise.long_name}/`
      if (street_number) mainAddress += `${street_number.long_name} `
      if (route) mainAddress += `${route.long_name}`
      if (suburb) this.address.suburb = suburb.long_name
      if (postal_code) this.address.postcode = postal_code.long_name;
      if (state) this.address.state = _.find(this.state, {name: state.long_name})

      if(!mainAddress) {
        const l1 = _.find(addressFullComponent, (v) => {
          return v.types.find(x => x !== 'country')
        })
        const c = _.find(addressFullComponent, {types: ['country']})
        mainAddress = `${l1?.long_name ?? ''}, ${c?.long_name ?? ''}`
      }
      this.address.address = mainAddress
      this.plainAddress = mainAddress
      this.showAddressError = false
      this.$emit('update:address', {...this.address, address: mainAddress})

      this.key ++

    },

    async validateForm() {
      this.$refs.address_create.click()
      this.showAddressError = typeof this.address.address == 'undefined' || this.plainAddress === ''
      setTimeout(() => {
        this.$emit('isValidAddress', this.valid)
      }, 300)

    },
    formValid() {
      this.showAddressError = typeof this.address.address == 'undefined' || this.plainAddress === ''
      if (!this.showAddressError) {
        this.valid = true
        this.$emit('isValidAddress', this.valid)
      }
    },
    updateValidationErrors(error, index) {
      let field = String(index).replace('contact_address.', '')
      /* this.$validator.errors.add({
         field: field,
         msg: error[0]
       });*/
    },
    updatePosition() {
      let tooltip = document.querySelector(autoCompleteListSelector)
      let button = document.querySelector(gmapInputSelector)

      if(!tooltip || !button) return
      computePosition(button, tooltip, {
        placement: 'bottom-start',

      }).then(({x, y}) => {
        tooltip.style.left = `${x}px`
        tooltip.style.top = `${y}px`
      });
    }
  },
  watch: {
    address () {
      setTimeout(() => {
        this.key ++
      },500)
    }
  },
  beforeUnmount() {
    document.querySelector(autoCompleteListSelector)?.remove()
  },
  mounted() {
    if (!this.state) {
      this.$store.dispatch('registration/getState');
    }

    this.plainAddress = this.address.address

   setTimeout(() => {
    autoUpdate(document.querySelector(gmapInputSelector),  document.querySelector(autoCompleteListSelector), this.updatePosition, {ancestorResize: true, ancestorScroll: true, elementResize: true, layoutShift: true})
   }, 2000);

  },
}
</script>

<style scoped>

</style>
