<template>
  <SimpleModal class="orders-delivery-modal-wrapper"  @close="$emit('close')"
                :close-text="'Back to orders'"
                :close-text-reversed="true"
                v-if="!loading"
                :close-outside="true">
    <div class="delivery_modal">
      <div class="delivery_modal__left">
        <div>
        <div class="d-flex justify-content-between" style="padding: 0 20px">
          <h5 class="text-uppercase font-weight-bold">Delivery slip</h5>
<!--          <div class="delivery_modal__print"><svg-icon name="solid/print"/><p>print</p></div>-->
        </div>
        <div class="date_container date_container--open">
          <div class="date_container__label">Delivered on:</div>
<!--          <div class="position-relative">-->
<!--            <Input small :value="deliveryDate.toLocaleString('en-AU')" disabled :icon="true" placeholder="dd/mm/yyyy" mask="##/##/####"  />-->
<!--          </div>-->
          <div class="body__calendar">
          <vue-cal
            :selectedDate="deliveryDate"
            small
            active-view="month"
            hide-view-selector
            :disable-views="['years', 'year', 'week', 'day']"
            today-button
            @cell-click="((e) => deliveryDate = e)"
            :maxDate="(new Date())"
          /></div>
<!--          <Calendar-->
<!--            v-if="calendarIsOpened"-->
<!--            :maxDate="new Date()"-->
<!--            @passDate="chooseDate"-->
<!--          />-->
        </div>
        <div v-if="!calendarIsOpened" class="delivery_modal__right--mobile">
            <div class="delivery_modal__info_card">
              <h5 class="color-black font-weight-bold text-uppercase"><svg-icon name="solid/layer-group"/>TOTAL Quantity: {{ allItems.length }}</h5>
            </div>
            <div class="delivery_modal__products">
              <ProductItem v-for="item in allItems"
                           :product="item"
                           :is-mobile="true"
              ></ProductItem>

            </div>
          </div>
        <div class="checkbox_label" >
          <Checkbox text="I confirm that all goods received are in good condition and match order specifications"
                    id="confirm_deliver"
                    :value="checkboxValue"
                    @input="checkboxValue =!checkboxValue"/>
        <ButtonMain @click="confirmDelivery" style="height: 60px" :disabled="!checkboxValue">
          <svg-icon name="solid/archive"/>
          confirm order delivery
        </ButtonMain>
        </div>
        </div>
      </div>
        <div class="delivery_modal__right">
        <div class="delivery_modal__info_card">
          <h5 class="color-black font-weight-bold text-uppercase"><svg-icon name="solid/layer-group"/>TOTAL Quantity: {{ allItems.length }}</h5>
        </div>
        <div class="delivery_modal__products">
            <ProductItem v-for="item in allItems"
                         :product="item"
            ></ProductItem>
          <div v-if="accessories && accessories.length > 0" style="padding: 5px;">
            <h3>Accessories</h3>
            <div class="products_item" v-for="(product, i) in accessories" :key="i">
              <div class="content__img">
                <svg-icon-qouting v-if="product.image && product.image != 'No Image'"  :data="product.image"/>
              </div>
              <div class="d-flex w-100">
                <div style="width: 100%; padding: 5px;">
                  <h5 class="item__title"><p>{{Number(product.qty).toFixed(0)}} x {{product.name}} - {{ product.colour_name}}</p></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="delivery_modal__bottom"></div>
      </div>
    </div>
  </SimpleModal>
</template>

<script>
import { reactive, ref, computed, onBeforeMount } from 'vue'
import VueCal from 'vue-cal'
import ButtonMain from "@/components/buttons/ButtonMain";
import SimpleModal from "@/components/modals/SimpleModal";
import Input from "@/components/inputs/Input";
import ProductItem from "@/components/jobs/ProductItem";
import {useStore} from "vuex";
import Checkbox from "@/components/inputs/Checkbox";


export default ({
  components: {
    ButtonMain,
    SimpleModal,
    VueCal,
    Input,
    Checkbox,
    ProductItem
  },
  emits: ['close'],
  props: ['id'],
  setup(props, {emit}) {
    const store = useStore()
    const allItems = computed(() => store.getters['orders/orderAllItems'].mainItems)
    const accessories = computed(() => store.getters['orders/orderAllItems'].accessories)
    const loading = ref(false)
    const deliveryDate = ref(new Date())
    const dateValue = ref((new Date()).toLocaleDateString('en-AU'))
    const checkboxValue = ref(false)
    const calendarIsOpened = ref(false)

    let changeDateValue = (value) => {
      dateValue.value = value
    }
    let chooseDate = (value) => {
      console.log(value)
      dateValue.value = value//.toLocaleDateString('en-AU')
    }
    let confirmDelivery = async () => {
      if (!checkboxValue.value)
        return
      let date = `${dateValue.value.substr(10,4)}-${dateValue.value.substr(5,2)}-${dateValue.value.substr(0,2)}`
      await store.dispatch('orders/changeOrdersStatus',
        {id: props.id, status: 'mark-as-delivered', parameters: {
            "order_received_on" : deliveryDate.value.toLocaleDateString('en-AU'),
            "is_order_received" : checkboxValue.value ? 1 : 0
          }} )
      emit('close')
    }
    let priceCalc = (price) => {
      return changePriceForm(price)
    }
    let priceRemoveZero = (price) => {
      if (price) {
        return changePriceForm(parseFloat(price).toFixed(2))
      }
    }
    let readable = (value) => {
      return value.replace(/_/g, ' ');
    }
    onBeforeMount(async () => {
      store.commit('orders/resetOrderAllItems')
      loading.value = true
      await store.dispatch('orders/getOrderAllItems',props.id)
      loading.value = false
    })
    return {
      priceCalc,
      priceRemoveZero,
      readable,
      loading,
      allItems,
      accessories,
      dateValue,
      checkboxValue,
      calendarIsOpened,
      confirmDelivery,
      chooseDate,
      changeDateValue,
      deliveryDate
    }
  }
})
</script>


<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
::v-deep .simple_modal__container {
  width: 1110px;
}
.delivery_modal {
  display: flex;
  font-size: 15px;
  height: 730px;
  &__left {
    width: 350px;
    >div {
      background: #0B3553;
      box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
      border-radius: 2px 0 0 2px;
      display: flex;
      flex-direction: column;
      height: auto;
      color: white;
      padding: 20px 10px 50px;
    }
  }
  &__right {
    background-color: white;
    position: relative;
    width: 760px;
    border-radius: 0px 0px 2px 2px;
    &--mobile {
      display: none;
    }
  }
  &__print {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.6);
    font-size: 13px;
    line-height: 100%;
    cursor: pointer;
    transition-duration: 0.2s;
    p {
      border-bottom: 1px dashed rgba(255, 255, 255, 0.6);
    }
    span {
      margin-right: 6px;
      font-size: 15px;
    }
    &:hover {
      color: rgba(255, 255, 255, 0.9);
      p {
        border-bottom: 1px solid transparent;
      }
    }
  }
  .date_container {
    display: flex;
    flex-direction: column;
    padding: 30px 0px;
    background: transparent;
    border-radius: 2px;
    margin: 20px 20px 50px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    &--open {
      border-top: transparent;
      margin: 20px 0px 50px;
      padding: 30px 20px;
      background: linear-gradient(0deg, rgba(11, 53, 83, 0.07), rgba(11, 53, 83, 0.07)), #FFFFFF;
      .date_container__label {
        color: rgba(28, 40, 51, 0.7);
      }
    }
    &__label {
      margin-bottom: 10px;
      color: rgba(255, 255, 255, 0.7);
    }
    ::v-deep .vuecal {
      &__cell {
        &--selected {
          background: rgba(11, 53, 83, 0.2);
        }
        &:hover {
          cursor: pointer;
          background: rgba(11, 53, 83, 0.1);
        }
      }
    }
  }
  &__calendar {
    display: flex;
    align-items: center;
    color: #59B692;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    cursor: pointer;
    transition-duration: 0.2s;
    &:hover {
      color: #3CD298;
    }
  }
  .checkbox_label {
    padding: 0 20px;
    ::v-deep label {
      margin-bottom: 30px;
      &:before {
        margin-bottom: auto;
        margin-top: 6px;
      }
      span {
        top: 10px;
      }
    }
  }
  &__info_card {
    height: 60px;
    padding: 19px 30px;
    display: flex;
    align-items: center;
    background: rgba(11, 53, 83, 0.1);
    box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
    & > h5 {
      display: flex;
      align-items: center;
      span {
        font-size: 13px;
        margin-right: 5px;
      }
    }
  }
  &__products {
    height: calc(100% - 90px);
    overflow-y: auto;
    box-shadow: -3px 0px 3px rgba(28, 40, 51, 0.2);
  }
  &__bottom {
    background: #0B3553;
    height: 30px;
    width: 100%;
    margin-top: auto;
    box-shadow: 0px -3px 3px rgba(28, 40, 51, 0.2);
    border-radius: 0px 0px 2px 2px;
  }
}
@media (max-width: 768px) {
  ::v-deep .simple_modal__container {
    width: 100%;
  }
  .delivery_modal {
    height: auto;
      &__left {
        width: 100%;
        >div {
          border-radius: 0;
          padding: 20px 0px;
        }
    }
    &__right {
      width: 100%;
      display: none;
      &--mobile {
        display: block;
        background: white;
        height: calc(100vh - 372px);
        margin-bottom: 16px;
      }
    }
    .date_container {
      padding: 10px 0px 20px;
      border-radius: 0px;
      margin: 20px 20px 0;
      &--open {
        margin: 20px 0px 16px;
        padding: 10px 20px;
        height: calc(100vh - 270px);
      }
    }
    .checkbox_label {
      padding: 0px 20px;
      ::v-deep label {
        margin-bottom: 20px;
      }
    }
    &__info_card {
      height: 45px;
      padding: 0 20px;
      background: white;
      box-shadow: none;
      & > h5 {
        span {
          font-size: 13px;
          margin-right: 5px;
        }
      }
    }
    &__products {
      height: calc(100% - 45px);
    }
  }
}
.body {
  &__calendar {
    height: 280px;
    ::v-deep .vuecal {
      box-shadow: none;
      &__heading {
        font-weight: bold;
        background: rgba(11, 53, 83, 0.1);
        font-size: 15px;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #1C2833;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          right: -1px;
          bottom: -1px;
          border: 1px solid rgba(11, 53, 83, 0.3)
        }
      }
      &__title-bar {
        background-color: transparent;
        font-weight: bold;
        font-size: 17px;
        line-height: 100%;
        height: 45px;
        letter-spacing: 0.02em;
        color: #1C2833;
      }
      &__weekdays-headings {
        padding-right: 0;
        border-right: 1px solid rgba(11, 53, 83, 0.3);
      }
      &__cell {
        font-size: 15px;
        line-height: 100%;
        color: rgba(28, 40, 51, 0.9);

        &--selected {
          // background: initial;
          background: rgba(89, 182, 146, 0.2);
        }
        //&:hover {
        //cursor: pointer
        //  background: rgba(11, 53, 83, 0.1);
        //}
        &--out-of-scope {
          color: rgba(11, 53, 83, 0.5);
        }
        &--disabled {
          color: rgba(11, 53, 83, 0.5);
        }
        &--has-events {
          background: rgba(89, 182, 146, 0.2);
          cursor: pointer;
          &.vuecal__cell--selected {
            background: rgba(89, 182, 146, 0.2);
          }
          &:hover {
            background: rgba(89, 182, 146, 0.4)
          }
        }
        &:before {
          border-color: rgba(11, 53, 83, 0.2)
        }
        &-events-count {
          left: auto;
          right: 0;
          top: 0;
          transform: translateX(0);
          background: rgba(11, 53, 83, 0.3);
          border-radius: 0px 0px 0px 6px;
          color: #fff;
          min-width: 15px;
          height: 15px;
          padding: 2px;
          font-weight: bold;
          font-size: 11px;
          line-height: 100%;
          letter-spacing: 0.02em;
        }
      }
    }

  }
}
</style>
