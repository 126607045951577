<template>
  <button class="main_button">
    <slot>
      <svg-icon name="solid/check"/>
      Accept
    </slot>
  </button>
</template>



<style scoped lang="scss">
@import "../../assets/scss/utils/vars";
.main_button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Sansation;
  width: 100%;
  height: 32px;
  font-size: 15px;
  border-radius: 2px;
  color: white;
  border: 0;
  background-color: $green;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition-duration: 0.2s;
  ::v-deep span {
    margin-right: 5px;
  }
  &:hover {
    background: #3CD298
  }
}
</style>
