<template>
  <div class="body__calendar">
    <vue-cal
      :selectedDate="selectedDate"
      active-view="month"
      hide-view-selector
      :disable-views="['years', 'year', 'week', 'day']"
      :events="events"
      today-button
      @cell-dblclick="showDate"
      @cell-click="convertToLocal"
      :min-date="minDate ? new Date().subtractDays(0) :''"
    >
      <template v-slot:weekday-heading="{ heading }">{{ heading.label.substring(0, 2) }}</template>
      <template v-slot:cell-content="{ cell, view, events, goNarrower }" >
          <div class="vuecal__cell-date" @click="$emit('pass-events',events)">{{ cell.content }}</div>
<!--        <div class="vuecal__cell-events-count" v-if="events.length > 1 " @click="$emit('pass-events',events)">{{ events.length }}</div>-->
          <div class="vuecal__cell-events-count" v-if="events.length > 0 " @click="$emit('pass-events',events)">{{ events.length }}</div>
      </template>
    </vue-cal>
  </div>
  <JobsCheckMeasureData v-if="dataIsOpened" :date="date" @close="dataIsOpened = false"/>
</template>
<script>
import { reactive, ref, computed } from 'vue'
import VueCal from 'vue-cal'
// import 'vue-cal/dist/vuecal.css'
import eventsApi from "@/services/events";
import JobsCheckMeasureData from "@/components/jobs/JobsCheckMeasureData";
import {formatDate} from "@/helpers/datetime";

export default ({
  components: {
    VueCal,
    JobsCheckMeasureData,
  },
  emits: ['pass-date', 'pass-events'],
  props: ['min-date', 'noEvents', 'hasEmits', 'selectedDate'],
  setup(props,{emit}) {

    const dataIsOpened = ref(false)
    const date = ref(false)
    const events = ref([])

    let showDate = async (value) => {
      date.value = value

      dataIsOpened.value = true
    }

    function convertToLocal(date){
      if (props.hasEmits)
        emit('pass-date',date)
      else
        showDate(date)
    }

    async function loadEvents(){
      const result = await eventsApi.getAll()
      events.value = result.data
    }

    if (!props.noEvents){
      loadEvents()
    }

    function formatDateLocal(date){
      return formatDate(date)
    }

    return {
      date,
      events,
      convertToLocal,
      showDate,
      dataIsOpened,
      formatDateLocal
    }

  }
})
</script>

<style scoped lang="scss">
.body {
  &__calendar {
    height: 280px;
    ::v-deep .vuecal {
      box-shadow: none;
      &__heading {
        font-weight: bold;
        background: rgba(11, 53, 83, 0.1);
        font-size: 15px;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #1C2833;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          right: -1px;
          bottom: -1px;
          border: 1px solid rgba(11, 53, 83, 0.3)
        }
      }
      &__title-bar {
        background-color: transparent;
        font-weight: bold;
        font-size: 17px;
        line-height: 100%;
        height: 45px;
        letter-spacing: 0.02em;
        color: #1C2833;
      }
      &__weekdays-headings {
        padding-right: 0;
        border-right: 1px solid rgba(11, 53, 83, 0.3);
      }
      &__cell {
        font-size: 15px;
        line-height: 100%;
        color: rgba(28, 40, 51, 0.9);

        &--selected {
          // background: initial;
          background: rgba(89, 182, 146, 0.2);
        }
          //&:hover {
        //cursor: pointer
          //  background: rgba(11, 53, 83, 0.1);
          //}
        &--out-of-scope {
          color: rgba(11, 53, 83, 0.5);
        }
        &--disabled {
          color: rgba(11, 53, 83, 0.5);
        }
        &--has-events {
          background: rgba(89, 182, 146, 0.2);
          cursor: pointer;
          &.vuecal__cell--selected {
            background: rgba(89, 182, 146, 0.2);
          }
          &:hover {
            background: rgba(89, 182, 146, 0.4)
          }
        }
        &:before {
          border-color: rgba(11, 53, 83, 0.2)
        }
        &-events-count {
          left: auto;
          right: 0;
          top: 0;
          transform: translateX(0);
          background: rgba(11, 53, 83, 0.3);
          border-radius: 0px 0px 0px 6px;
          color: #fff;
          min-width: 15px;
          height: 15px;
          padding: 2px;
          font-weight: bold;
          font-size: 11px;
          line-height: 100%;
          letter-spacing: 0.02em;
        }
      }
    }

  }
}

</style>
