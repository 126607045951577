<template>
    <div class="tw-flex tw-w-full">
      <div class="jobs_list__item tw-transition-all" :style="isExpanded? '': 'width: 0; margin:0; overflow: hidden'">
        <div class="d-flex align-items-center item__header">
          <div class="title__icon"><svg-icon :name="type.icon"/></div>
          <h5 class="title"><div>{{ type.value ?? type.name }}</div><span class="title__badge">{{jobsAmount}}
        </span></h5>
          <div class="title__calendar link-green tw-flex tw-gap-4">
            <span v-if="type.name === 'Open' || type.name === 'Awaiting Installation'" @click="checkIsOpened = true">
              <svg-icon  name="solid/calendar-alt" />
            </span>
            <span @click="expanded = false" class="tw-hidden md:tw-block">
              <svg-icon name="solid/chevron-left"/>
            </span>
          </div>
        </div>

        <div class="item__container" @scroll="loadMore">
          <JobsCard v-for="job in jobsList" :job="job" :type="type.name" class="container__item"/>
          <span v-if="loading" class="d-flex justify-content-center py-3">
            <PreloaderSmall class="aaaaa"/>
          </span>
          <div class="item__no" v-else-if="!loading && jobsList.length == 0">You don't have any {{ (type.value ?? type.name).toLowerCase() }} jobs.</div>
        </div>
      </div>
      <div v-show="!isExpanded" @click="expanded = true" class="tw-relative tw-transition-all collapsed-item tw-flex tw-items-center tw-justify-center tw-w-24 tw-mr-7 tw-px-4 tw-py-2">
        <span class="tw-absolute tw-top-3 link-green">
          <svg-icon name="solid/chevron-right"/>
        </span>
        <div class="d-flex align-items-center item__header tw-whitespace-nowrap tw-transform tw--rotate-90">
          <div class="title__icon"><svg-icon :name="type.icon"/></div>
          <h5 class="title"><div>{{ type.value ?? type.name }}</div>
            <span class="title__badge">{{jobsAmount}}</span>
          </h5>
        </div>
      </div>
    </div>
    <JobsCheckMeasureCalendar data-no-dragscroll v-if="checkIsOpened" @close="checkIsOpened = false"/>
</template>

<script>
import { watch, reactive, ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import JobsCard from "@/components/jobs/JobsCard";
import JobsCheckMeasureCalendar from "@/components/jobs/JobsCheckMeasureCalendar";
import infiniteScroll from "vue-infinite-scroll";
import PreloaderSmall from "@/components/PreloaderSmall";

export default ({
  props: ['type'],
  components: {
    JobsCard,
    JobsCheckMeasureCalendar,
    infiniteScroll,
    PreloaderSmall
  },
  setup(props, {emit}) {
    watch(() => props.type.name, (value, prevValue) => {
      getJobs()
    })
    const store = useStore()
    const checkIsOpened = ref(false)
    const jobsList = computed(() => store.getters['jobs/jobsList'](props.type.name))
    const jobsAmount = computed(() => store.getters['jobs/jobsAmount'](props.type.name))
    const sort = computed(() => store.getters['jobs/jobsSorting'])
    const search = computed(() => store.getters['jobs/jobsSearch'])

    const expanded = ref(null)

    const isExpanded = computed(() => {
      if(expanded.value === null && Number(jobsAmount.value) > 0) return true
      if(expanded.value === null && Number(jobsAmount.value) === 0) return false
      return expanded.value
    })

    watch(sort, (sort, prevSort) => {
      getJobs()
    })
    watch(search, (search, prevSearch) => {
      getJobs()
    })
    const loading = ref(false)
    const loadMore = async (e) => {
      let { scrollTop, clientHeight, scrollHeight } = e.target;
      if ((loading.value === false) && (scrollTop + clientHeight >= scrollHeight - 400)) {
        loading.value = true;
        await store.dispatch('jobs/getJobsList', {status: props.type.name} )
        loading.value =  false
      }
    }
    const getJobs =  _.debounce(async () => {
        loading.value = true;
        store.commit('jobs/resetJobs', props.type.name)
        await store.dispatch('jobs/getJobsList', {status: props.type.name} )
        loading.value =  false
    }, 100);
    onMounted(async () => {
      store.commit('jobs/jobsSorting',`-by_date`)
      store.commit('jobs/jobsSearch',` `)
      await getJobs()
    })
    return {
      expanded,
      isExpanded,
      loading,
      loadMore,
      getJobs,
      checkIsOpened,
      jobsList,
      jobsAmount
    }
  },
})
</script>


<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";


.collapsed-item {
  background-color: #0B3553;
  color: white;
  font-weight: bold;
  font-size: 17px;
  line-height: 100%;
  min-height: calc(100vh - 270px);;
  font-weight: bold;
  font-size: 17px;
  line-height: 100%;
  color: white;
  text-transform: uppercase;
  -webkit-box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.4);
  box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.4);
  cursor: pointer;

  .title {
    display: flex;
    align-items: center;
    & > div {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &__icon {
      opacity: 0.8;
      font-size: 16px;
      margin-right: 8px;
    }
    &__badge {
      margin-left: 5px;
      color: #F1C40F;
    }
    &__calendar {
      color: #59B692;
      margin-left: auto;
      font-size: 16px;
      cursor: pointer;
    }
  }
}


.jobs_list {
 &__item {
   width: 350px;
   flex-shrink: 0;
   margin-right: 30px;
   .item {
     &__header {
       font-weight: bold;
       font-size: 17px;
       line-height: 100%;
       color: white;
       height: 36px;
       padding: 6px 14px 6px 10px;
       text-transform: uppercase;
       box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.4);
      background-color: #0B3553;

       .title {
         display: flex;
         align-items: center;
         & > div {
           text-overflow: ellipsis;
           overflow: hidden;
           white-space: nowrap;
         }
         &__icon {
           opacity: 0.8;
           font-size: 16px;
           margin-right: 8px;
         }
         &__badge {
           margin-left: 5px;
           color: #F1C40F;
         }
         &__calendar {
           margin-left: auto;
           font-size: 16px;
         }
       }
     }
     &__container {
       display: flex;
       flex-direction: column;
       height: calc(100vh - 270px);
       overflow-y: auto;
       border: 1px solid rgba(11, 53, 83, 0.3);
       border-top: none;
     }
     &__no {
       padding: 20px;
       font-style: italic;
       color: rgba(11, 53, 83, 0.6);
       a {
         color: #59B692;
         border-bottom: 1px solid rgba(89, 182, 146, 0.5);
         transition-duration: 0.2s;
         &:hover {
           color: #3CD298;
           border-bottom: transparent;
         }
       }
     }
   }
 }
}
@media (max-width: 768px) {
  .collapsed-item {
    display: none;
  }
  .jobs_list {
    &__item {
      width: 100%!important;
      max-width: 400px;
      margin: 0 auto!important;
      .item {
        &__header {
          color: #1C2833;
          height: 30px;
          padding: 0;
          padding-left: 20px;
          box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
          background: #F1C40F;
          .title {
            &__icon {
              display: none;
            }
            &__badge {
              color: white;
            }
            &__calendar {
              margin-left: auto;
              width: 30px;
              height: 30px;
              background: #59B692;
              display: flex;
              align-items: center;
              justify-content: center;
              color:$black;
              &:hover {
                color:$text-gray;
              }
            }
          }
        }
        &__container {
          height: calc(100vh - 309px);
          border: 0;
        }
      }
    }
  }
}
</style>
