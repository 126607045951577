<template>
  <div
      class="mini_card__item"
       :class="[small ? 'mini_card__item--small' : '',
               itemActive === index ? 'mini_card__item--active' : '']"
      :style="{background: state.background}"
       @click="chooseContainer(index, item.id)"
  >
    <div class="item__radio"
         @click.stop="toggleChosenContainer(item.id)"
         :class="chosenContainer.indexOf(item.id) > -1 ? 'item__radio--active' : ''">
    </div>
    <div style="width: 100px; font-weight: bold" class="d-flex align-items-center">
      <div class="item__icon"><svg-icon v-if="state.icon" :name="state.icon"/></div>
      {{ item.id_with_prefix }}
    </div>
    <div class="d-flex align-items-center">
      <div style="font-size: 13px"><svg-icon name="solid/layer-group"/></div>
      {{ item.total_positions_count }}
    </div>
    <div class="d-flex align-items-center ml-auto" style="font-size: 13px">
<!--      <div v-if="!small" style="text-transform: uppercase; margin-right: 5px">Price:</div>-->
<!--      <div :style="{width: small ? '96px' : '80px'}" style="color: #1C2833;font-weight: bold">${{ priceCalc(item.price) }}</div>-->
    </div>
    <div class="item__status" :style="{background: status.iconColor}">
      <div><svg-icon v-if="status.icon" :name="status.icon"/></div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed, onBeforeMount } from 'vue'
import {changePriceForm} from '../../helpers/aroundPrice';
import {useStore} from "vuex";


export default ({
  components: {
  },
  emits: ['choose-container','toggle-chosen-container'],
  props: ['item', 'itemActive', 'index', 'chosenContainer', 'small'],
  setup(props, {emit}) {
    const store = useStore()
    let state = computed(() => store.getters['jobs/exactState'](props.item.state))
    let status = computed(() => store.getters['jobs/exactStatus'](props.item.status))
    let chooseContainer = async (index, id) => {
      emit('choose-container',{index: index, id: id})
    }
    let toggleChosenContainer = async (container) => {
      emit('toggle-chosen-container',container)
    }
    let priceCalc = (price) => {
      return changePriceForm(price)
    }
    return {
      toggleChosenContainer,
      chooseContainer,
      priceCalc,
      status,
      state
    }
  }
})
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.mini_card {
      &__item {
        height: 32px;
        display: flex;
        align-items: center;
        margin: 15px 0;
        font-size: 15px;
        background: rgba(11, 53, 83, 0.15);
        box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
        color: rgba(28, 40, 51, 0.8);;
        transition-duration: 0.2s;
        position: relative;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 0px 10px rgba(11, 53, 83, 0.5);
        }
        &--small {
          box-shadow: none;
          border-radius: 2px;
          overflow: hidden;
          &:hover {
            box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
          }
        }
        &--active {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 5px;
            height: 100%;
            background: #0B3553;
          }
        }
        span {
          margin-right: 5px;
        }
        .item {
          &__radio {
            margin: 0 10px;
            width: 20px;
            height: 20px;
            position: relative;
            background: rgba(255, 255, 255, 0.8);
            border: 1px solid rgba(11, 53, 83, 0.4);
            box-sizing: border-box;
            border-radius: 20px;
            position: relative;
            &--active {
              &:before {
                position: absolute;
                content: '';
                width: 6px;
                height: 6px;
                background: #1C2833;
                border-radius: 6px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
          &__icon {
            width: 21px;
            font-size: 18px;
          }
          &__status {
            width: 32px;
            color: white;
            height: 100%;
            div {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              span {
                font-size: 16px;
                margin-right: 0;
              }
            }
          }
        }
      }
    }

</style>
