<script setup>

import { useStore } from 'vuex'
import dealerOrderApi from './../../services/dealerOrder.service.js'
import ConfirmationModal from './ConfirmationModal.vue';
import { useEventBus } from "@vueuse/core";
import { defineProps, defineEmits } from 'vue'

const props = defineProps(['id', 'area', 'show'])
const emit = defineEmits(['orderSubmitted', 'update:show'])
const store = useStore()
const orderSubmittedEvent = useEventBus("order-submitted");

async function submitOrderToStarline() {
    store.commit('preloader/preloader', true);
    try{
        const res = await dealerOrderApi.submitDealerOrderToStarline(props.area, props.id)
        emit('orderSubmitted', res)
        orderSubmittedEvent.emit()
    }catch(err) {
       console.log('Could not submit the order.')
    }
    store.commit('preloader/preloader', false);
}


</script>

<template>
    <ConfirmationModal
        message="Are you sure you want to submit this order to production?"
        @confirmClicked="submitOrderToStarline"
        :show="show"
        @update:show="$emit('update:show', false)"
    />
</template>
