<template>
  <Modal :modelValue="show" class="confirmation-modal" @update:modelValue="$emit('update:show', false)">
    <div class="confirmation-modal__content">
    <div class="confirmation-modal__title">
      <slot name="message">
        <h2>{{title ?? 'Action required'}}</h2>
        <br>
        <p v-html="message ?? 'Are you sure you want to continue'"></p>
      </slot>
    </div>
      <div class="confirmation-modal__actions">
        <Button :data="cancelBtnData"
                @click="$emit('cancelClicked'); $emit('update:show', false);"/>
        <Button :data="confirmBtnData" @click="$emit('confirmClicked')"/>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../elements/Modal'
import Button from '../form/Button'
import  { ref } from 'vue'

export default ({
  props: ['show','message','cancel_btn_data','confirm_btn_data', 'title'],
  components: {
    Modal,
    Button
  },
  setup(props, {emit}) {
    const cancelBtnData = ref(props.cancel_btn_data  ??  { type: 'primary', title: 'Back',icon: 'far fa-arrow-left' })
    const confirmBtnData = ref(props.confirm_btn_data  ??  { type: 'primary', title: 'Submit', icon: 'fa fa-archive', })

    return {
      cancelBtnData,
      confirmBtnData,
    }
  },
})
</script>

<style lang="scss" scoped>
.confirmation-modal {

  height: 100%;
  top: 0;
  min-width: 100%;
  max-width: 100vw;
  &__title{
    padding: 20px;
    width: 100%;
    max-width: 100%;
    height: auto;
    text-align: center;

    p{
      width: 100%;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 25px;

    button{
      margin: 5px;
    }
  }

  ::v-deep .modal {
    max-width: 100%;

    min-height: 180px;

    max-height: 80vh;
  }

  ::v-deep .btn_outline {
    border-color: var(--PrimaryButtonColour);
    color: var(--PrimaryButtonColour);
    margin-right: 20px;
  }
}

</style>
