<template>
  <SimpleModal
    class="check_data_modal"
    :class="checkMeasureData?.length === 2 ? 'check_data_modal--two' : checkMeasureData?.length === 1 ? 'check_data_modal--one' : 'check_data_modal--one'"
    @close="$emit('close')"
    close-text="Back to calendar"
  >
    <h4 class="check_data_modal__header">
<!--      <slot name="header">-->
      {{ formatDateLocal(date) }}
<!--      </slot>-->
    </h4>
    <div class="check_data_modal__container" v-if="checkMeasureData && checkMeasureData.length">
      <template v-for="j in checkMeasureData" :key="j.id">
        <JobsCard :noButton="true" :job="j" class="container__item" :type="'jobs'"/>
      </template>
      <div v-if="(checkMeasureData.length > 3) && ((checkMeasureData.length+1) % 3 == 0)" class="container__item"></div>
    </div>
  </SimpleModal>
</template>

<script>

import { ref } from 'vue'
import eventsApi from "@/services/events";
import {dateCalcFormat} from "@/helpers/datetime";

import SimpleModal from "@/components/modals/SimpleModal";
import JobsCard from "@/components/jobs/JobsCard";

export default ({
  props: {
    date:{
      type: Date
    },
    data: {
      type: String
    },
    title: {
      type: String
    },
    jobs: {
      type: Array
    }
  },
  components: {
    SimpleModal,
    JobsCard
  },
  emits: ['close'],
  setup(props, {emits}) {
    const checkMeasureData = ref(null)

    async function loadEvents() {
      eventsApi.get(props.date.toLocaleDateString('en-AU', {
        day: '2-digit',
        month: '2-digit',
        year: "numeric"
      }).replaceAll('/', '-')).then(response => {
        checkMeasureData.value = response.data
      })
    }

    function formatDateLocal(date){
      return dateCalcFormat(date, false, false)
    }


    loadEvents()

    return {
      checkMeasureData,
      formatDateLocal
    }
  }
})
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";

.check_data_modal {
  ::v-deep .simple_modal__wrapper .simple_modal__container {
    padding: 52px 40px 50px;
    min-height: 290px;
    width: 100%;
    max-width: 1190px;
  }
  &__header {
    font-weight: bold;
    font-size: 25px;
    line-height: 100%;
    color: #1C2833;
    padding-bottom: 30px;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .container {
      &__item {
        margin-bottom: 10px;
        width: 33%;
        max-width: 350px;
      }
    }
  }
  &--one {
    ::v-deep .simple_modal__wrapper .simple_modal__container {
      max-width: 430px;
    }
    .container {
      &__item {
        width: 100%;
      }
    }
  }
  &--two {
    ::v-deep .simple_modal__wrapper .simple_modal__container {
      max-width: 810px;
    }
    .container {
      &__item {
        width: 50%;
      }
    }
  }
}
@media (max-width: 768px) {
  .check_data_modal {
    ::v-deep .simple_modal__wrapper .simple_modal__container {
      padding: 0;
      width: 100%;
      max-width: 100%;
    }
    &__header {
      font-weight: bold;
      font-size: 21px;
      width: 100%;
      padding: 15px 20px;
      background: linear-gradient(0deg, rgba(241, 196, 15, 0.2), rgba(241, 196, 15, 0.2)), #FFFFFF;
      box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
      margin-bottom: 10px;
    }
    &__container {
      .container {
        &__item {
          width: 100%!important;
          max-width: 100%;
        }
      }
    }
  }
}

</style>
