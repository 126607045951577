<template>
  <InputGroup v-if="!hasGoogleMapErrors" :data="{label: 'Address', placeholder: 'Start typing'}">
    <GMapAutocomplete
        id="auto_add"
        :options="{ componentRestrictions: { country: 'aus' }}"
        :value="modelValue"
        ref="autocomplete"
        class="autocomplete"
        placeholder="Start typing address"
        @change="setPlace"
        @click="checkIsInputDisabled( $event.target)"
        @keyup="checkIsInputDisabled( $event.target)"
        @place_changed="setPlace"
    />
  </InputGroup>

  <InputGroup v-else :modelValue="modelValue"  :data="{label: 'Address', placeholder: 'Start typing address'}" @change="setPlace">
  </InputGroup>
</template>

<script>
import InputGroup from './InputGroup'
import {onMounted, ref} from "vue";

export default ({
  props: ['modelValue', 'states'],
  components: {
    InputGroup
  },
  setup(props, {emit}) {
    const autocomplete = ref(null)
    const hasGoogleMapErrors = ref(false)

    onMounted(async () => {
      document.getElementById('auto_add').style.opacity = 0
      document.getElementById('auto_add').value = 'Start typing'
      setTimeout(() => {
        let element = document.getElementById('auto_add')
        element.focus()
        setTimeout(() => {
          let element = document.getElementById('auto_add')
          if (element){
            element.style.opacity = ''
            hasGoogleMapErrors.value = document.getElementById('auto_add').disabled
            if (!hasGoogleMapErrors.value) {
              document.getElementById('auto_add').value = ""
              autocomplete.value?.$forceUpdate()
            } 

          }
        }, 1500)
      }, 200)

      setInterval(() => {
        let element = document.getElementById('auto_add')
        if (element) hasGoogleMapErrors.value = document.getElementById('auto_add').disabled
      }, 100)
    })

    function checkIsInputDisabled(element) {
      setTimeout(() => {
        if (element.disabled) {
          element.style.removeProperty('background-image');
          element.placeholder = 'Start typing'
          element.disabled = false
          element.focus()
          hasGoogleMapErrors.value = true
        }
      }, 200)
    }

    function setPlace(place) {

      const data = {
        address: '',
        suburb: '',
        postcode: '',
        state: '',
        state_id: '',
      }
      const address_components = place.address_components

      if (address_components) {
        const suburb = address_components.find(el => el.types.join(', ').includes('locality, political'))
        const postal_code = address_components.find(el => el.types.join(', ').includes('postal_code'))
        const state = address_components.find(el => el.types.join(', ').includes('administrative_area_level_1, political'))
        if (suburb) data.suburb = suburb.long_name
        if (postal_code) data.postcode = postal_code.long_name
        if (state) data.state = state.long_name


        const subpremise = address_components.find(el => el.types.join(', ').includes('subpremise'))
        const street_number = address_components.find(el => el.types.join(', ').includes('street_number'))
        const route = address_components.find(el => el.types.join(', ').includes('route'))

        if (subpremise) data.address += `${subpremise.long_name}/`
        if (street_number) data.address += `${street_number.long_name}, `
        if (route) data.address += route.long_name
        if (!data.address) data.address = place.formatted_address

        data.address = data.address.replace(',', '')
      } else {
        data.address = event.target.value
      }
      emit('updAddress', data)
    }

    return {
      autocomplete,
      setPlace,
      checkIsInputDisabled,
      hasGoogleMapErrors
    }
  },
})
</script>

<style lang="scss">
.pac-container {
  z-index: 99999999;
  border-top: 0 !important;
}

</style>


<style lang="scss" scoped>
.autocomplete {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 13px 0 15px;
  font-weight: bold;
  font-size: 17px;
  line-height: 140%;
  border: 1px solid rgba(11, 53, 83, 0.2);
  border-radius: 2px;

  &::placeholder {
    font-style: italic;
    font-weight: normal;
  }
}


</style>

