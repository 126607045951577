import { AJAX_POST, AJAX_PUT } from '../helpers/ajax'


export default {
    async submitDealerOrderToStarline(type, dealerOrderId) {
        return await AJAX_POST(`/${type}/${dealerOrderId}/submit`)
    },

    async updateOrderReference(dealerOrderId, reference) {
        return await AJAX_PUT(`/my-orders/${dealerOrderId}/reference`, {ref: reference})
    },

    async convertToJob(dealerOrderId, payload){
      return await AJAX_POST(`/my-orders/${dealerOrderId}/convert-to-job`, payload)
    }
}
