<template>
  <div class="tw-flex tw-items-center tw-space-x-6">
    <hr class="tw-flex-1 tw-border-secondary"/>
    <span class="tw-capitalize tw-text-primary tw-text-xl tw-font-bold">{{ location.name }} ({{location.item_count}})</span>
    <hr class="tw-flex-1 tw-border-secondary"/>
  </div>
  <div v-if="!isLoading && items" class="tw-mt-2">

    <div v-for="(elem, key) in items" :key="key">
      <div class="tw-mb-4">
        <div class="tw-flex tw-space-x-4 tw-items-start">
            <div class="item__img" v-if="elem.layout_image || elem.icon">
              <svg-icon-qouting  :data="(elem.layout_image || elem.icon)" alt="" width="100%" />
            </div>

          <div>
            <p v-if="!elem.is_additional_accessory" class="tw-text-secondary tw-font-bold tw-text-lg">{{ renderSize(elem) }} {{ elem.item.product_type.name }}</p>
            <p v-else class="tw-text-secondary tw-font-bold tw-text-lg">{{ elem.quantity }} x {{ elem.item.product_grade.name }}</p>
            <p class="tw-text-primary tw-text-sm tw-font-thin tw-font-helvetica">{{elem.item.product_style.name}}</p>
            <p><strong>Frame color:</strong> <span> {{ elem.frame_colour.name }} </span></p>
            <p v-if="elem.insert_colour"><strong>Insert color:</strong> <span> {{ elem.insert_colour.name }} </span></p>
            <p v-for="(option, ok) in elem.options" :key="ok"><strong>{{option.name}}:</strong> <span> {{option.customer_value}} </span></p>
          </div>
        </div>

        <div v-if="canSeePrice" class="tw-mt-2 tw-text-secondary tw-font-helvetica tw-text-right">
          <p><strong>Price:</strong> <span> {{ formatter.formatMoney(elem.price) }} </span></p>
        </div>
      </div>
    </div>
  </div>
  <template v-else-if="isLoading">
    <ContentLoader v-for="i in location.item_count" class="px-2" height="100" :key="i">
      <rect x="70" y="10" rx="5" ry="5" width="300" height="15"/>
      <rect x="70" y="39" rx="5" ry="5" width="220" height="9"/>
      <rect x="70" y="55" rx="5" ry="5" width="220" height="9"/>
      <rect x="70" y="70" rx="5" ry="5" width="220" height="9"/>
      <rect x="0" y="10" rx="0" ry="0" width="60" height="70"/>
    </ContentLoader>
  </template>
</template>

<script>
import  { ref, computed } from 'vue'
import api from '../../../api/orders/preview'
import { useFormatter } from '../../../composables/formatter'
import { ContentLoader } from 'vue-content-loader'
import { useStore } from "vuex";
import {isWidthIsTheSizeToEnterFirst} from "@/helpers/portalCommons";

export default {
  name: "location",
  props: ['containerId', 'location', 'mode'],
  components: {ContentLoader},
  setup(props){
    const formatter = useFormatter()
    const isLoading = ref(false)
    const items = ref(null)
    const store = useStore()
    const canSeePrice = computed(() => store.getters['auth/showPrice'](props.mode))

    async function loadItems(){
      isLoading.value = true
      items.value = await api.locationItems(props.mode, props.containerId, props.location.name)
      isLoading.value = false
    }

    function renderSize(elem){
      if (isWidthIsTheSizeToEnterFirst()){
        return elem.width + "x" + elem.height + "mm"
      }
      return elem.height + "x" + elem.width + "mm"
    }

    loadItems();

    return {
      canSeePrice,
      isLoading,
      items,
      formatter,
      renderSize
    }
  }
}
</script>

<style scoped lang="scss">
  .item__img{
      font-size: 90px;
      margin-right: 10px;

    .icons__wrapper {
      display: flex;
      max-width: 50px;

    }
    img {
      width: 100%;
    }
    ::deep(.svg-inline) {
      max-width: 100%;
    }
  }
</style>
