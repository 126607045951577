<template>
  <div>
    <Form
      v-slot="{ errors }"
      autocomplete="off"
      class="form form_col"
      @submit="formValid"
    >
    <label class="form__label ">
      <span class="form__labelTitle">Address*</span>
      <Field as="textarea"
        v-model="address.address"
        :rules="{required: true, max: 255}"
        class="form__input"
        name="address"
        placeholder="Address*"
        rows="2"
      />
      <span class="form__error">{{ errors.address }}</span>
    </label>

    <label class="form__label ">
      <span class="form__labelTitle">Suburb</span>
      <Field
        v-model="address.suburb"
        :rules="{max: 128}"
        class="form__input"
        name="suburb"
        placeholder="Suburb"
        type="text"
      />
      <span class="form__error">{{ errors.suburb }}</span>
    </label>


    <label class="form__label ">
      <span class="form__labelTitle">Postcode</span>
      <Field
        v-model="address.postcode"
        :rules="{digits: 4}"
        class="form__input"
        name="postcode"
        placeholder="Postcode"
        type="text"
      />
      <span class="form__error">{{ errors.postcode }}</span>
    </label>

    <label class="form__label ">
      <span class="form__labelTitle">State</span>
      <select
        v-model="address.state"
        class="form__input form__select"
        name="state"
      >
        <option
          :value="undefined"
          disabled
          hidden
          selected
        >State
        </option>
        <option
          v-for="(item, index) in state"
          :key="index"
          :value="item.state_id"
        >{{ item.name }}
        </option>
      </select>

    </label>
      <button type="submit" class="d-none" ref="address_create" />
    </Form>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "SingleAddressComponent",
  data() {
    return {
      valid : false,
      /* address: {
         state: undefined,
       }*/
    }
  },
  props: {
    address: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      "state": 'registration/state',
    }),
  },
  methods: {
    validateForm() {
      this.$refs.address_create.click()
      setTimeout(() => {
        this.$emit('isValidAddress', this.valid)
      },300)

    },
    formValid() {
      this.valid = true
      this.$emit('isValidAddress',  this.valid)
    },
    updateValidationErrors(error, index) {
      let field = String(index).replace('contact_address.', '')
     /* this.$validator.errors.add({
        field: field,
        msg: error[0]
      });*/
    }
  }
}
</script>

<style scoped>

</style>
