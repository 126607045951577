<template>
  <SimpleModal @close="itemActive >= 0 ? (itemActive = -1) : $emit('close')"
    :close-text="itemActive >= 0 ? 'Back' : 'Back to jobs'" :close-text-reversed="itemActive < 0" :close-outside="true"
    v-if="!loading">
    <div class="details_modal">
      <div class="details_modal__left" :class="itemActive < 0 && 'details_modal__left--mobile'">
        <div class="details_modal__left--top">
          <div class="details_modal__client_info">
            <div class="client_info">
              <h5 class="client_info__header d-block">
                <span class="header__id">{{ job.id_with_prefix }}</span>{{ job.customer?.name }} {{
                  job.customer?.last_name }}
                <div v-if="comment" class="header__comment" @click="commentIsOpened = !commentIsOpened">
                  <svg-icon name="solid/comment-alt-lines" />
                </div>
              </h5>
              <div class="client_info__reference" :class="!job.customer_ref ? 'client_info__reference--inactive' : ''">
                <svg-icon name="solid/cog" />
                <div>{{ job.customer_ref ? job.customer_ref : "No Reference" }}</div>
              </div>
              <div v-if="job.customer?.address" style="text-transform: uppercase; font-size: 13px; color: #59b692">
                <svg-icon name="solid/globe" />
                <div>
                  {{ job.customer?.address }} {{ job.customer?.suburb }}
                  {{ job.customer.state?.code }} {{ job.customer.postcode }}
                </div>
              </div>
              <a :href="'tel:' + job.customer?.phone" v-if="job.customer?.phone">
                <div style="font-size: 12px"><svg-icon name="solid/phone" /></div>
                <div>{{ job.customer?.phone }}</div>
              </a>
              <a :href="'mailto:' + job.customer?.email" v-if="job.customer?.email" class="link-green"
                style="text-transform: lowercase">
                <div style="font-size: 13px"><svg-icon name="solid/envelope" /></div>
                <div>{{ job.customer?.email }}</div>
              </a>
            </div>
          </div>
          <div class="tw-overflow-auto md:tw-hidden tw-p-5">
            <preview :container-id="id" :mode="'my-jobs'" />
          </div>
          <div class="details_modal__job_details" v-if="job.check_measure_date">
            <h4>
              <svg-icon name="solid/ruler-combined" title="Check measure date"></svg-icon>
              Check measure
            </h4>
            {{ formatDate(job.check_measure_date) }}
          </div>
          <div class="details_modal__job_details" v-if="job.installation_date">
            <h4>
              <svg-icon name="solid/screwdriver-wrench" title="Installation date"></svg-icon>
              Installation
            </h4>
            {{ formatDate(job.installation_date) }}
          </div>
        </div>
        <div class="details_modal__job_details" v-if="job.notes">
          <h4>Notes</h4>
          {{ job.notes }}
        </div>

        <div v-if="showConfirmation && placeOrder" class="details_modal__place_order tw-w-full">
          <Checkbox class="item__checkbox-" text="I confirm that I have checked the order [text TBC]"
            :value="confirmPlaceOrder" @input="confirmPlaceOrder = !confirmPlaceOrder" />
          <button class="btn btn_default" :disabled="!confirmPlaceOrder" @click="placeOrder">
            Submit order
          </button>
        </div>
      </div>
      <div class="details_modal__right" :class="itemActive >= 0 && 'details_modal__right--mobile'">
        <div class="details_modal__products">
          <preview :container-id="id" :mode="'my-jobs'" />
        </div>
      </div>
    </div>
  </SimpleModal>
</template>

<script>
import { reactive, ref, computed, onBeforeMount } from "vue";
import ButtonMain from "@/components/buttons/ButtonMain";
import SimpleModal from "@/components/modals/SimpleModal";
import SimpleDropdown from "@/components/dropdown/SimpleDropdown";
import JobStatusComponent from "@/components/jobs/JobStatusComponent";
import JobStatusHeader from "@/components/jobs/JobStatusHeader";
import TotalPrice from "@/components/TotalPrice";
import LinkReturn from "@/components/links/LinkReturn";
import JobsCardDropdown from "@/components/jobs/JobsCardDropdown";
import JobsCardButtons from "@/components/jobs/JobsCardButtons";
import { useStore } from "vuex";
import PreloaderSmall from "@/components/PreloaderSmall";
import { changePriceForm } from "../../helpers/aroundPrice";
import store from "@/store/store";
import preview from "@/components/item-wizard/components/orders/preview";
import { dateCalcFormat } from "@/helpers/datetime";
import Checkbox from "@/components/inputs/Checkbox";
import jobsApi from "@/services/jobs/job";

export default {
  components: {
    ButtonMain,
    SimpleModal,
    JobStatusComponent,
    JobStatusHeader,
    JobsCardButtons,
    JobsCardDropdown,
    TotalPrice,
    LinkReturn,
    SimpleDropdown,
    PreloaderSmall,
    preview,
    Checkbox,
  },
  emits: [
    "close",
    "showSchedule",
    "checkIsCompleted",
    "deliveryIsShown",
    "changeStatusCompleted",
    "placeOrderIsShown",
    "cloneJob",
    "showCancel",
    "updateList",
  ],
  props: ["job", "id", "placeOrder"],
  setup(props, { emit }) {
    const store = useStore();
    const confirmPlaceOrder = ref(false);

    function formatDate(date) {
      return dateCalcFormat(date, true);
    }

    function placeOrder() {
      jobsApi.placeOrder(props.job.id);
      emit("close");
      emit("updateList", "In Production");
    }

    const showConfirmation = computed(() => {
      return ['Open'].includes(props.job.status)
    })

    return {
      formatDate,
      confirmPlaceOrder,
      placeOrder,
      showConfirmation,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";

.content {
  &__img {
    margin-right: 10px;
    font-size: 80px;
    width: 65px;

    ::v-deep .icons__wrapper {
      display: flex;
    }
  }
}

.details_modal {
  display: flex;
  font-size: 15px;
  height: 100vh;
  max-height: 730px;

  &__left {
    width: 350px;
    background: linear-gradient(0deg, rgba(11, 53, 83, 0.07), rgba(11, 53, 83, 0.07)),
      #ffffff;
    border-radius: 2px 0 0 2px;
    display: flex;
    flex-direction: column;

    &--top {
      height: calc(100% - 120px);
      display: flex;
      flex-direction: column;
    }
  }

  &__right {
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 505px;
  }

  &__products {
    padding: 10px;
  }

  &__job_details {
    padding: 15px;
  }

  &__place_order {
    background-color: #f8f8f8;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__client_info {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    padding: 23px 17px 25px 30px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
    line-height: 100%;
    background: #0b3553;
    border-radius: 2px 0 0 0;
    box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);

    & span {
      margin-right: 5px;
      flex-shrink: 0;
    }

    .client_info {
      &>* {
        display: flex;
        align-items: center;
        padding-bottom: 14px;
        width: 100%;
      }

      &__reference {
        font-weight: bold;
        color: #ffffff;

        span {
          color: rgba(255, 255, 255, 0.6);
        }

        &--inactive {
          color: rgba(255, 255, 255, 0.6);
          font-style: italic;
          font-weight: normal;

          span {
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }

      &__header {
        font-size: 17px;
        font-weight: bold;
        position: relative;
        padding-right: 34px;
        white-space: normal !important;
        width: 100%;

        .header {
          &__id {
            color: white;
            margin-right: 10px;
            text-transform: uppercase;
          }

          &__comment {
            position: absolute;
            right: 0;
            top: -6px;
            background: rgba(241, 196, 15, 0.6);
            border-radius: 2px;
            font-size: 13px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #0b3553;
            cursor: pointer;
            transition-duration: 0.2s;

            span {
              margin-right: 0;
            }

            &:hover {
              background: #f1c40f;
            }
          }
        }
      }
    }
  }

  &__mini_card {
    overflow-y: auto;
    position: relative;
    height: 100%;

    &--closed {
      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(11, 53, 83, 0.6);
      }
    }
  }

  &__job_total {
    height: 120px;
    padding: 20px;
    box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2), 0px -3px 3px rgba(28, 40, 51, 0.2);
    background: #0b3553;
    border-radius: 0px 0px 0px 2px;
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-content: space-between;

    ::v-deep button {
      width: 100%;
      height: 40px;
      margin-top: auto;
    }

    ::v-deep .dropdown_menu {
      margin-right: 0;

      .simple_dropdown {
        top: auto;
        bottom: 30px;

        >* {
          transition-duration: 0.2s;

          &:hover {
            span {
              transition-duration: 0.2s;
              color: #59b692;
            }

            color: #59b692;
          }
        }

        div.cancel {
          &:hover {
            span {
              color: #c0392b;
            }

            color: #c0392b;
          }
        }
      }
    }

    ::v-deep .total_price__content {
      bottom: 120px;
    }
  }

  &__comment {
    width: calc(100% - 30px);
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 64px 52px 30px;
    background: linear-gradient(0deg, rgba(11, 53, 83, 0.07), rgba(11, 53, 83, 0.07)),
      #ffffff;
    border: 1px solid rgba(11, 53, 83, 0.1);
    border-radius: 0 0 2px 0;
    box-shadow: 0px 4px 15px rgba(28, 40, 51, 0.1);

    &--mobile {
      display: none;
    }

    &:before {
      content: "";
      position: absolute;
      left: -4px;
      top: 0;
      background-color: transparent;
      width: 3px;
      height: 100%;
      box-shadow: 3px 0px 3px rgba(28, 40, 51, 0.3);
    }

    .comment {
      &__close {
        position: absolute;
        right: 20px;
        top: 12px;
        font-size: 19px;
        cursor: pointer;
        color: rgba(28, 40, 51, 0.6);
      }
    }

    h4 {
      padding-bottom: 20px;
    }
  }

  &__products {
    height: calc(100% - 60px);
    overflow-y: auto;
    box-shadow: -3px 0px 3px rgba(28, 40, 51, 0.2);

    .products {
      &__bottom {
        left: 0;
        position: absolute;
        height: 80px;
        bottom: 0;
        width: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      }

      &__item {
        padding: 23px 30px 25px;
        display: flex;
        color: #1c2833;
        border: 1px solid rgba(11, 53, 83, 0.2);

        img {
          width: 65px;
          height: 80px;
          margin-top: 8px;
          object-fit: contain;
          object-position: center;
          margin-right: 30px;
        }

        p {
          padding-bottom: 5px;
          font-size: 13px;
          line-height: 140%;
          font-weight: bold;

          span {
            font-weight: normal;
          }
        }

        .item {
          &__title {
            font-size: 13px;
            font-weight: bold;
            display: flex;
            width: 100%;
            justify-content: space-between;
            text-transform: uppercase;

            & *:first-child {
              font-size: 15px;
              margin-right: 10px;
              flex-shrink: 1;
              white-space: normal;
            }

            & *:last-child {
              padding-top: 3px;
            }
          }
        }
      }

      &__category {
        background: rgba(11, 53, 83, 0.2);
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: bold;
        color: #1c2833;
      }
    }
  }
}

@media (max-width: 768px) {
  .details_modal {
    max-height: 100vh;

    &__left {
      width: 100%;
      background: white;

      // display: none;
      &--mobile {
        display: flex;
      }

      &--top {
        height: calc(100vh - 170px);
      }
    }

    &__right {
      width: 100%;
      background: white;
      display: none;

      &--mobile {
        display: flex;
      }
    }

    &__comment {
      display: none;

      &--mobile {
        display: block;
      }

      z-index: 10;
      width: calc(100% - 40px);
      position: absolute;
      top: 43px;
      left: 20px;
      padding: 20px 20px 40px;
      border-radius: 2px;

      &:before {
        content: none;
      }

      &--before {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        background: rgba(28, 40, 51, 0.6);
        width: 100vw;
        height: 100vh;
        z-index: 1;
      }

      .comment {
        &__close {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: -1px;
          top: -30px;
          font-size: 19px;
          width: 30px;
          height: 30px;
          color: #1c2833;
          background: #f1c40f;
          border-radius: 2px 2px 0px 0px;
        }
      }

      h4 {
        font-size: 19px;
        padding-bottom: 20px;
      }
    }

    &__client_info {
      padding: 20px;
      font-size: 13px;
      border-radius: 0;

      .client_info {
        &>* {
          padding-bottom: 10px;
        }

        &__header {
          font-size: 15px;
        }
      }
    }

    &__products {
      height: calc(100% - 40px);
      overflow-y: auto;
      box-shadow: -3px 0px 3px rgba(28, 40, 51, 0.2);

      .products {
        &__bottom {
          height: 70px;
        }

        &__item {
          position: relative;
          padding: 16px 20px 40px;
          display: flex;
          color: #1c2833;
          border: 1px solid rgba(11, 53, 83, 0.2);
          margin-bottom: 10px;
          box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);

          img {
            margin-right: 15px;
          }

          p {
            padding-bottom: 2px;
          }

          &:last-child {
            margin-bottom: 20px;
          }

          .item {
            &__title {
              & *:first-child {
                font-size: 13px;
                margin-right: 0px;
              }

              & *:last-child {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
                background: linear-gradient(0deg,
                    rgba(241, 196, 15, 0.2),
                    rgba(241, 196, 15, 0.2)),
                  #ffffff;
                padding-left: 100px;

                &:before {
                  content: "Price: ";
                  white-space: pre;
                  text-transform: none;
                  font-weight: normal;
                  position: relative;
                  color: rgba(28, 40, 51, 0.7);
                }
              }
            }
          }
        }

        &__category {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
