<template>
  <input
          type="checkbox"
          :id="id+random"
          class="checkbox_component"
          :class="reverse ? 'checkbox_component--reverse' : ''"
          @input="changeValue"
          :value="value">
  <label :for="id+random" class="checkbox_component__label" :class="value ? 'checkbox_component__label--active': ''"><svg-icon  name="solid/check"/>{{text}}</label>
</template>

<script>
import { reactive, ref, computed } from 'vue'

export default {
  props:{
    reverse: Boolean,
    value: Boolean,
    id: String,
    text: String,
  },
  emits: ['input'],

  setup(props, { emit }) {
    let random = ref(Math.random())
    let changeValue = (event) => {
      emit('input', event.target.value);
    }
    return {
      changeValue,
      random
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.checkbox_component {
  position: absolute;
  z-index: -1;
  opacity: 0;
  &__label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    position: relative;
    &::before {
      display: inline-block;
      flex-shrink: 0;
      flex-grow: 0;
      content: '';
      width: 20px;
      height: 20px;
      border: 1px solid rgba(28, 40, 51, 0.3);
      transition: all .2s;
      border-radius: 2px;
      background-color: white;
      margin-right: 10px;
    }
    span {
      display: none!important;
    }
    &--active {
      & span {
        display: flex!important;
        position: absolute;
        left: 0;
        width: 20px;
        justify-content: center;
        font-size: 13px;
        color: #59B692;
      }
      font-weight: bold;
      &:before {
        border: 1px solid #3CD298;
      }
    }
  }
  &--reverse {
    padding: 12px 10px 12px 10px;
    height: 40px;
  }
}
</style>
