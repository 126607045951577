<template>
  <div
    :class="getRelatedClass + ' ' + (singleColumn? '': 'col-lg-4 col-sm-6 col-12') "
    class=""
  >
    <div
      :style="{border: '1px solid black'}"
      class="orderItem tw-relative"
    >
      <h2
        class="orderItem__title orderItem__colour"
        style="background-color:#B5B5B5"
      >
        <span class="tw-inline-block-- tw-flex tw-items-center tw-space-x-2">
          <span>
            {{ contact.first_name }}  {{ contact.last_name }}
          </span>
        </span>
        <span
          class="orderItem__iconRight"
        >
          <span class="tw-inline-block tw-h-5"
                @click="$emit('updateThisContact',index)">
            <svg-icon name="pen" class="!tw-h-5" style="height: 16px;"/>
          </span>
        </span>
        <span
          class="orderItem__iconRight times_icon"
          @click="$emit('removeThisContact',index)"
        >
          <span class="tw-inline-block tw-h-5 ">
            <svg-icon name="times" style="height: 16px;"/>
          </span>
        </span>
      </h2>

      <div class="orderItem__boxInfo tw-grid  tw-space-x-2 tw-font-helvetica">
        <div class="tw-space-y-0">
          <ul
            class="pl-1"
          >
            <li>
              Contact type <span
              class="pr-1"
              style="float: right;"
            >  {{ getTypeName(contact) }}  </span>
            </li>
            <li>
              Mobile <span
              class="pr-1"
              style="float: right;"
            >  {{ contact.phone || 'N/A' }}  </span>
            </li>
            <!-- <li>
              Landline <span
              class="pr-1"
              style="float: right;"
            >  {{ contact.landline || 'N/A' }}  </span>
            </li> -->
            <li>
              Email <span
              class="pr-1"
              style="float: right;"
            >  {{ contact.email || 'N/A' }}  </span>
            </li>
          </ul>
        </div>

        <div class="tw-space-y-0">
          <hr style="border: 1px solid #B5B5B5;">
          Address
          <span
            style="float: right;text-align: end "
            v-html="getFullAddress"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleContactTitleComponent",
  props: {
    contact: {
      required: true,
      type: Object
    },
    contactTypes: {
      required: true,
      type: Array
    },
    index: {
      required: true,
      type: Number
    },
    states: {
      required: true,
      type: Array
    },
    singleColumn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getRelatedClass() {
      return (this.index + 1) % 3 === 1 ? 'pl-0' : (this.index + 1) % 3 === 0 ? 'pr-0' : ''
    },
    getFullAddress() {
      if (_.isEmpty(this.contact.contact_address) || (this.contact.contact_address && this.contact.contact_address.no_address)) {
        return `<p>N/A</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>`
      }
      let state_name = undefined
      const contactAddress = this.contact.contact_address
      let address = `<p>${contactAddress.address},</p>`
      if (contactAddress.postcode) address += `<p>${contactAddress.postcode},</p>`
      if (contactAddress.state && !(_.isObject(contactAddress.state))) {
        state_name = _.find(this.states, {'state_id': contactAddress?.state})?.name
      } else if (_.isObject(contactAddress.state)) {
        state_name = _.find(this.states, {'state_id': contactAddress.state.state_id})?.name
      }
      if (state_name) address += `<p>${state_name},</p>`
      if (contactAddress.suburb) address += `<p>${contactAddress.suburb}</p>`

      return address
    }
  },
  methods: {
    getTypeName(contact) {
      return _.find(this.contactTypes, {id: contact.contact_type_id}).name
    }
  }

}
</script>

<style lang="scss" scoped>
.orderItem {
  height: calc(100% - 34px)
}

.orderItem__iconRight.times_icon {
  margin-right: -8px !important;
}

</style>
