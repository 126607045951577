<template>
  <div
    v-if="job.actions?.some(e => e.name === 'Schedule Check Measure' && e.type !== 'main')"
    @click="$emit('showSchedule')">
    <svg-icon name="solid/history"/>
    Schedule Check Measure
  </div>
  <div
    v-if="job.actions?.some(e => e.name === 'Check Measure Completed' && e.type !== 'main')"
    @click="$emit('checkIsCompleted')">
    <svg-icon name="solid/tape"/>
    Check Measure Completed
  </div>
  <div
    v-if="job.actions?.some(e => e.name === 'Book Installation' && e.type !== 'main')"
    @click="$emit('showBookInstallation')">
    <svg-icon name="solid/calendar-alt"/>
    Book Installation
  </div>
  <div
    v-if="job.actions?.some(e => e.name === 'I Received Order' && e.type !== 'main')"
    @click="$emit('deliveryIsShown')">
    <svg-icon name="solid/file-export"/>
    I Received Order
  </div>
  <div
    v-if="job.actions?.some(e => e.name === 'Complete Job' && e.type !== 'main')"
    @click="$emit('changeStatusCompleted')">
    <svg-icon name="solid/archive"/>
    Complete Job
  </div>
  <div
    v-if="job.actions?.some(e => e.name === 'Place Order' && e.type !== 'main')"
    @click="$emit('placeOrderIsShown')">
    <svg-icon name="solid/file-export"/>
    Place An Order
  </div>
  <router-link
    v-if="job.actions?.some(e => e.name === 'Edit' && e.type !== 'main')"
    :to="`/my-jobs/${job.id}`">
    <svg-icon name="solid/edit"/>
    Edit
  </router-link>
  <div
    v-if="job.actions?.some(e => e.name === 'Change installation date' && e.type !== 'main')"
    @click="$emit('showBookInstallation')">
    <svg-icon name="solid/calendar-alt"/>
    Change installation date
  </div>
  <div
    v-if="job.actions?.some(e => e.name === 'Specification' && e.type !== 'main')"
    @click="$emit('showSpecification')">
    <svg-icon name="solid/file-alt"/>
    Specification
  </div>
  <div
    v-if="job.actions?.some(e => e.name === 'Send Quote' && e.type !== 'main')"
    @click="$emit('showSendQuote')">
    <svg-icon name="solid/envelope"/>
    Send Quote
  </div>
  <div
    v-if="job.actions?.some(e => e.name === 'Send Estimate' && e.type !== 'main')"
    @click="$emit('showSendEstimate')">
    <svg-icon name="solid/envelope"/>
    Send Estimate
  </div>
  <div
    v-if="job.actions?.some(e => e.name === 'Submit Order' && e.type !== 'main')"
    @click="$emit('showSubmitOrder')">
    <svg-icon name="solid/file-export"/>
    Submit Order
  </div>
  <div v-if="job.actions?.some(e => e.name === 'Clone' && e.type !== 'main')"
       @click="$emit('cloneJob')">
    <svg-icon name="solid/clone"/>
    Clone
  </div>
  <div
    v-if="job.actions?.some(e => e.name === 'Cancel' && e.type !== 'main')"
    class="cancel" @click="$emit('showCancel')">
    <svg-icon name="solid/times"/>
    Cancel
  </div>
  <div
    v-if="job.actions?.some(e => e.name === 'Delete' && e.type !== 'main')"
    class="cancel" @click="$emit('showDelete')">
    <svg-icon name="solid/trash"/>
    Delete
  </div>
</template>

<script>

export default {
  name: 'jobsCard',
  props: [
    'job'
  ],
  emits: [
    'showSchedule',
    'checkIsCompleted',
    'deliveryIsShown',
    'changeStatusCompleted',
    'placeOrderIsShown',
    'cloneJob',
    'showCancel',
    'showBookInstallation'],
  components: {},
  setup(props, {emit}) {
    return {}
  }
};
</script>
