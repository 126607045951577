import {httpReq} from "@/htttpReq";

const routePrefix = '/ordering-portal/customers'

export default {
  getDealerCustomers: (page =1,paginate = 9, search = '',groupBy = 'none', sortBy = 'name') => httpReq.get(`${routePrefix}?paginate=${paginate}&page=${page}&sort=${sortBy}&filter[search]=${search}&group_by=${groupBy}`),
  getDealerSingleCustomer: (customer_id) => httpReq.get(`${routePrefix}/${customer_id}`),
  addNewCustomer: (payload) => httpReq.post(`${routePrefix}`, payload),
  dealerSingleCustomerOrders: (customer_id,page =1,paginate = 9) => httpReq.get(`${routePrefix}/${customer_id}/orders?paginate=${paginate}&page=${page}`),
  updateACustomer: (customer_id, payload) => httpReq.put(`${routePrefix}/${customer_id}`, payload),
  validateContact: (payload) => httpReq.post(`${routePrefix}/validate-contact`, payload),

  //Business customer contacts
  dealerCustomerContactCreat: (customer_id, payload) => httpReq.post(`${routePrefix}/${customer_id}/contacts`, payload),
  dealerCustomerContacts: (customer_id, contact_id, payload) => httpReq.get(`${routePrefix}/${customer_id}/contacts/${contact_id}`, payload),
  dealerCustomerContactUpdate: (customer_id, contact_id, payload) => httpReq.put(`${routePrefix}/${customer_id}/contacts/${contact_id}`, payload),
  dealerCustomerContactDelete: (customer_id, contact_id, payload) => httpReq.delete(`${routePrefix}/${customer_id}/contacts/${contact_id}`, payload),

  //Core
  activeContactTypes: () => httpReq.get('core/contact-types'),
}
