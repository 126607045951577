<template>
  <Modal v-model="show" class="delete-confirmation-modal" @update:modelValue="show =false">
    <div class="delete-confirmation-modal__content">
      {{message || 'Do you want to continue ?'}}
      <div class="delete-confirmation-modal__actions">
        <Button :data="{ type: 'outline', title: 'Back',icon: 'far fa-arrow-left' }"
                @click="$emit('update:modelValue', false)"/>
        <Button :data="{ type: 'primary', title: 'Continue', icon: 'far fa-trash', }" @click="$emit('deleteEstimate')"/>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../elements/Modal'
import Button from '../form/Button'

export default ({
  props: ['show', 'message'],
  components: {
    Modal,
    Button
  },
  setup(props, {emit}) {
    function submit(loadData) {
      if (loadData) {
        const data = sessionStorage.getItem('wizardCurrentStep') && JSON.parse(sessionStorage.getItem('wizardCurrentStep'))
        emit('loadData', data)
      } else emit('closeModal')
    }

    return {
      submit
    }
  },
})
</script>

<style lang="scss" scoped>
.delete-confirmation-modal {
  // &__content {
  //     display: flex;
  //     flex-direction: column;
  // }

  &__content{
    padding: 50px;
  }
  height: 100%;
  top: 0;
  min-width: 100%;

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
  }

  ::v-deep(.modal) {
    width: 380px;
    min-height: 150px;
    // height: 130px;
  }

  ::v-deep(.btn_outline) {
    border-color: var(--PrimaryButtonColour);
    color: var(--PrimaryButtonColour);
    margin-right: 20px;
  }
}

</style>
