<template>
  <div v-if="!container" class="tw-mt-8 order-info-popup">
    <h3>Loading order {{containerId}} details... </h3>
    <ContentLoader class="px-2" height="100">
      <rect x="70" y="39" rx="5" ry="5" width="220" height="9"/>
      <rect x="70" y="55" rx="5" ry="5" width="220" height="9"/>
      <rect x="70" y="70" rx="5" ry="5" width="220" height="9"/>
    </ContentLoader>
  </div>
  <div class="tw-mt-8 order-info-popup" style="min-height: 500px" v-else>
    <div class="tw-pt-6-">
        <h4><strong>Order:</strong> {{containerId}}</h4>

      <div class="tw-pt-6-" v-if="container.reference">
        <h5><strong>Reference:</strong> {{container.reference}}</h5>
      </div>
      <template v-if="isIncludeCustomerDetails">
        <div class="tw-mt-2 tw-text-secondary tw-font-helvetica">
          <h4>Customer</h4>
        </div>
        <div class="tw-mt-2 tw-text-secondary tw-font-helvetica">
          <p><strong>Name:</strong> <span> {{ items.customer.name }} </span></p>
          <p><strong>Address:</strong> <span> {{ items.customer.address }} {{ items.customer.suburb }} {{ items.customer.postcode }} </span></p>
          <p v-if="items.customer.email"><strong>Email:</strong> <span> {{ items.customer.email }} </span></p>
          <p v-if="items.customer.phone"><strong>Phone:</strong> <span> {{ items.customer.phone }} </span></p>
        </div>
      </template>
    </div>
    <template v-if="locations">
      <div class="" v-if="locations.length === 0">
        <p class="">No location added yet</p>
      </div>
      <div v-for="(location, key) in locations" class="tw-pt-6-" :key="key">
        <location :container-id="containerId" :location="location" :mode="mode" />
      </div>
    </template>
    <!--Summary-->
    <template v-if="canSeePrice">
      <div class="tw-flex tw-items-center tw-space-x-6">
        <hr class="tw-flex-1 tw-border-secondary"/>
        <span class="tw-capitalize tw-text-primary tw-text-xl tw-font-bold">Order Summary</span>
        <hr class="tw-flex-1 tw-border-secondary"/>
      </div>

      <Receipt :summary="summary"/>
    </template>

  </div>

</template>

<script>
import {defineAsyncComponent, ref, computed} from 'vue'
import location from './preview/location'
import api from '../../api/orders/preview'
import { useStore } from "vuex";
const Receipt = defineAsyncComponent(() => import('@/components/item-wizard/components/estimate/item/summary/Receipt'));
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'orderPreview',
  props: ['containerId', 'mode', 'isIncludeCustomerDetails', 'isIncludeDealerDetails'],
  components: {location, Receipt, ContentLoader},
  setup(props){
    const container = ref(null)
    const locations = ref(null)
    const summary = ref(null)
    const store = useStore()
    const canSeePrice = computed(() => store.getters['auth/showPrice'](props.mode))

    async function loadData(){
      container.value = await api.load(props.mode, props.containerId)
      locations.value = await api.locations(props.mode, props.containerId)
      summary.value = await api.summary(props.mode, props.containerId)
    }

    loadData()

    return {
      canSeePrice,
      container,
      locations,
      summary
    }
  }
}
</script>

<style lang="scss">
.order-info-popup {

  .titlePage {
    display: none;
  }

  padding-bottom: 25px;

  //@apply tw-text-secondary;
  //.table_bg td {
  //  @apply tw-bg-secondary;
  //}
  .table_stripe tr:not(.table_bg):nth-child(odd) td {
    background-color: initial;
  }
}
</style>
