<template>
  <base-modal
    v-model:show="createNewContactModalVisibility"
    :title="'New contact'"
  >
    <template v-slot:modal-content>
      <span class="text text_italic required__text">*Required Fields</span>
      <div class="row">
        <Form
          v-slot="{ errors }"
          autocomplete="off"
          class="form form_col"
          id="update_contact"
          @submit="handleContact"
        >
          <div class="col-12">

            <label class="form__label mt-0">
              <span class="form__labelTitle">Type*</span>
              <select v-model="contact.contact_type_id"
                      :rules="'required'"
                      class="form__input form__select"
                      name="type"
                      placeholder="Type*"
              >
                <option :value="undefined" disabled hidden selected>Type*</option>
                <option
                  v-for="(item, index) in contactTypes"
                  :key="index"
                  :value="item.id"
                >{{ item.name }}
                </option>
              </select>
              <span class="form__error">{{ errors.type }}</span>
            </label>

            <label class="form__label">
              <span class="form__labelTitle">First Name*</span>
              <Field v-model="contact.first_name" :rules="{required:true, max:128}"
                     class="form__input" label="first name"
                     name="first_name"
                     placeholder="First Name*"
                     type="text"
              />
              <span class="form__error">{{ errors.first_name }}</span>
            </label>

            <label class="form__label">
              <span class="form__labelTitle">Last Name</span>
              <Field v-model="contact.last_name" :rules="{max:128}" class="form__input"
                     label="password"
                     name="last name"
                     placeholder="Last Name*"
                     type="text"
              />
              <span class="form__error">{{ errors.last_name }}</span>
            </label>

            <label class="form__label">
              <span class="form__labelTitle">E-mail*</span>
              <Field v-model="contact.email" :rules="'email|required'"
                     class="form__input"
                     label="e-mail"
                     name="email"
                     placeholder="E-mail"
                     type="text"
              />

              <span class="form__error">{{ errors.email }}</span>
            </label>
            <label class="form__label">
              <span class="form__labelTitle">Mobile Number*</span>
              <Field v-model="contact.phone"
                        :rules="{required: true, digits_without_whitespace: 10, min: 6}"
                        class="form__input"
                        label="phone number"
                        v-maska="'## ## ### ###'"
                        name="phone"
                        placeholder="Mobile Number*"
              />
              <span class="form__error">{{ errors.phone }}</span>
            </label>

            <label class="form__label" v-if="!hideLandline">
              <span class="form__labelTitle">Landline Number</span>
              <Field v-model="contact.landline"
                        :rules="{digits_without_whitespace: 10, min: 6, numeric: true}"
                        class="form__input"
                        label="landline number"
                        v-maska="'## ## ### ###'"
                        name="landline"
                        placeholder="Landline Number*"
              />
              <span class="form__error">{{ errors.landline }}</span>
            </label>

            <!-- Address section starts -->

            <h4 class="titlePage titlePage_subtitle titlePage_green">Address</h4>

            <label class="label-switch switch-success pb-3">
              <input id="status" v-model="hasAddress"
                     class="switch switch-bootstrap status" name="status" type="checkbox" value="1">
              <span class="lable"> New address </span></label>

            <transition name="fade">
              <!-- <SingleAddressComponent v-if="hasAddress"
                                      ref="addressRef"
                                      :address.sync="contact.contact_address"
                                      @isValidAddress="(value) => updateIsValidAddress(value)"
                                      @updateAddress="(value) => updateAddress(value)"/> -->
                <NewSingleAddressComponent
                  v-if="hasAddress"
                  ref="addressRef"
                  class="details_input"
                  :suburb-and-post-code-in-same-raw="true"
                  :address.sync="contact.contact_address"
                  @update:address.sync="address = $event"
                  @isValidAddress="(value) => updateIsValidAddress(value)"
                /> 
            </transition>

            <!-- Address section ends -->

          </div>

        </Form>
      </div>
    </template>
    <template v-slot:actions>
      <button class="btn btn_transparent mx-0 mb-0" form="update_contact">
        <svg-icon name="solid/floppy-disk"/>
        Update
      </button>

    </template>

  </base-modal>
</template>

<script>
import SingleAddressComponent from '/src/components/customer/SingleAddressComponent'
import NewSingleAddressComponent from '../../components/commons/NewSingleAddressComponent.vue';

import {mapGetters} from "vuex";
import CustomerAPI from '@/routes/Customer/CustomerAPI.js'
import GlobalMixins from "../../Mixins/GlobalMixins";
import { maska } from 'maska'

export default {
  name: "UpdateContactModal",
  directives: {
    maska
  },
  mixins : [GlobalMixins],
  data() {
    return {
      createNewContactModalVisibility: false,
      // address: {},
      hasAddress: false,
      validAddress: false,
      contact: {},
      currentUpdatingIndex: 0
    }
  },
  props: {
    contactTypes: {
      required: true,
      type: Array
    },
    hideLandline: {
      default: false,
      type: Boolean
    }
  },
  components: {
    SingleAddressComponent, NewSingleAddressComponent
  },
  methods: {
    updateIsValidAddress(value) {
      this.validAddress = value
    },
    updateAddress(value) {
      this.address = value
    },
    async open(contactToBeUpdated, index) {

      if(contactToBeUpdated.contact_address && _.isObject(contactToBeUpdated.contact_address.state)){
        contactToBeUpdated.contact_address.state = contactToBeUpdated.contact_address.state.state_id
      }
      this.contact = {
        index: index,
        id: contactToBeUpdated.id,
        first_name: contactToBeUpdated.first_name,
        last_name: contactToBeUpdated.last_name,
        email: contactToBeUpdated.email,
        landline: contactToBeUpdated.landline,
        phone: contactToBeUpdated.phone,
        contact_type_id: contactToBeUpdated.contact_type_id,
        contact_address: {...contactToBeUpdated.contact_address, address_type_id: contactToBeUpdated.contact_type_id},
      }

      if (contactToBeUpdated.contact_address && contactToBeUpdated.contact_address.address) {
        this.hasAddress = true
      }

      this.createNewContactModalVisibility = true
    },
   async handleContact() {
    this.$emit('updatedContact', this.contact, this.hasAddress)
    this.createNewContactModalVisibility = false
    return
      if (this.hasAddress) this.$refs.addressRef.validateForm()

     /* this.$validator.validate().then(async valid => {*/

        if ((this.hasAddress && !(this.validAddress))) return

        try {
          (await CustomerAPI.validateContact(this.contact))
          if (!this.hasAddress) this.contact.contact_address = {}
          this.$emit('updatedContact', this.contact)
          this.createNewContactModalVisibility = false
        } catch (errors) {
          if (errors.response.status === 422) {
            _.forEach(errors.response.data.errors, (error, index) => {
             /* if (String(index).includes('contact_address')) {
                this.$refs.addressRef.updateValidationErrors(error, index)
              } else {
                this.$validator.errors.add({
                  field: index,
                  msg: error[0]
                });
              }*/ /*index = String(index).replace('contact_address.','')*/
              console.log(index)

            })
          }
          this.showErrorModal(errors)
        }

     /* });*/

    }
  },
  computed: {
    ...mapGetters({
      "state": 'registration/state',
    }),
    address: {
      get: function() {
        return (
          {
            address: this.contact?.contact_address?.address,
            suburb: this.contact?.contact_address?.suburb,
            postcode: this.contact?.contact_address?.postcode,
            state: this.contact?.contact_address?.state,
          } ?? {}
        );
      },
      set: function(value) {
        this.contact.contact_address = { ...this.contact.contact_address, ...value };
      },
    }
  },
}
</script>

<style scoped>

</style>
