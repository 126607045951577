<template>
  <div class="card" :class="noButton ? 'card--no_button': ''">
    <div class="card__header tw-text-black-gray">
      <router-link :to="`/my-orders/${order.container_id}`">
        <h5 class="header__id">{{ order.id_with_prefix }}</h5>
        <h5 class="header__title">{{order.order_customer?.name}} {{order.order_customer?.last_name}}</h5>
      </router-link>
      <div class="header__info" @click="showInfoForOrderId = order.container_id">
        <svg-icon name="solid/info"/>
      </div>
      <div v-if="!noButton"
           class="dropdown_menu"
           @click="toggleMenu">
        <svg-icon name="regular/ellipsis-h"/>
        <SimpleDropdown v-if="menuIsOpened" @close="closeMenu" class="tw-flex tw-flex-col tw-items-start"  style="padding-left: 15px;">
          <div
            class="tw-flex tw-gap-1 tw-items-center"
            v-for="action in otherActions"
            :key="action.action"
            @click="handleActionClick(action.action)">
            <svg-icon :name="action.icon"/>
            {{action.name}}
          </div>
        </SimpleDropdown>
      </div>
    </div>
    <div class="card__content">
      <div class="content__elements">
        <div
          class="d-flex align-items-center elements__reference"
          :class="!order.dealer_reference ? 'elements__reference--inactive' : ''"
        >
          <svg-icon name="solid/cog"/>
          {{order.dealer_reference ? order.dealer_reference : 'No Reference'}}
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div
            v-if="!order.related_customer_order"
            class="d-flex align-items-center"
          >
            Direct order
          </div>
          <div
            v-else-if="order.related_customer_order?.id_with_prefix"
            class="d-flex align-items-center"
          >
            Job: <router-link :to="{name: 'job', params: { id: order.related_customer_order?.container_id}}" class="font-weight-bold color-green"> {{order.related_customer_order?.id_with_prefix}}</router-link>
          </div>


          <div @mouseover="showDateTitle = true" @mouseleave="showDateTitle = false" ref="reference"  class="d-flex align-items-center justify-content-end" v-if="type === 'Draft'">
            <div v-if="order.due_date && order.due_date!='0000-00-00'" class="font-weight-bold d-flex align-items-center" style="font-weight: bold">
              <svg-icon name="solid/clock"/>
              {{dateFormat(order.due_date)}}
            </div>
            <div v-else-if="order.order_date && order.order_date!='0000-00-00'" class="d-flex align-items-center" style="font-weight: bold">
              <svg-icon name="solid/clock"/>
              {{dateFormat(order.order_date)}}
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-end" v-else-if="type === 'In Production'">
            <div v-if="order.prod_date && order.prod_date!='0000-00-00'" class="d-flex align-items-center" style="font-weight: bold">
              <svg-icon name="solid/clock"/>
              {{dateFormat(order.prod_date)}}
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-end"
               v-else-if="type === 'Production Completed'">
            <div v-if="order.estimated_dispatch_date && order.estimated_dispatch_date!='0000-00-00'" class="d-flex align-items-center" style="font-weight: bold">
              <svg-icon name="solid/clock"/>
              {{dateFormat(order.estimated_dispatch_date)}}
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-end"
               v-else-if="type === 'Dispatched'">
            <div v-if="order.actual_dispatch_date && order.actual_dispatch_date!='0000-00-00'" class="d-flex align-items-center" style="font-weight: bold">
              <svg-icon name="solid/clock"/>
              {{dateFormat(order.actual_dispatch_date)}}
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-end"
               v-else-if="type !== 'Completed' && type !== 'Cancelled'">
            <div class="d-flex align-items-center" style="font-weight: bold">
              <svg-icon name="solid/hourglass"/>
              Age: {{ orderAge }}  day{{ orderAge > 1 ? "s" : ""}}
            </div>
          </div>
          <div v-if="showDateTitle" ref="floating" :style="floatingStyles" class="tw-bg-gray-200 tw-p-1 tw-rounded tw-text-xs">Creation date</div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center mr-5px" style=" width: 80px">
            <svg-icon name="solid/layer-group"/>
              <span v-if="order.total_positions_count">{{order.total_positions_count || 0}}</span>
              <span v-if="order.total_positions_count && order.total_additional_count"> + </span>
              <span v-if="order.total_additional_count">{{order.total_additional_count || 0}}</span>
          </div>
          <div v-if="order.total && canSeePrice" style="font-size: 15px;" class="d-flex justify-content-end">
            <div class="mr-5px font-weight-bold color-black">$ {{priceCalc(order.total)}}</div>
          </div>
        </div>
      </div>
        <div class="content__button">
      <ButtonMain
        v-if="mainAction"
        @click="handleActionClick(mainAction.action)">
        <svg-icon :name="mainAction.icon"/>
        {{ mainAction.name }}
      </ButtonMain>
        <OrdersDelivery v-if="deliveryIsShown" :id="deliveryIsShown"  @close="deliveryIsShown = false"/>

<!--      <OrdersScheduleCheckMeasure v-if="showSchedule" :id="showSchedule" @close="showSchedule = false"/>-->
<!--      <OrdersCancel v-if="showCancel" :id="showCancel" @close="showCancel = false"/>-->
<!--      <OrdersCheckMeasureCompleted v-if="checkIsCompleted" :id="checkIsCompleted" @close="checkIsCompleted = false"/>-->
<!--      <OrdersDetails v-if="detailsAreShown" :id="detailsAreShown"  @close="detailsAreShown = false"/>-->
<!--      <OrdersDetails v-if="placeOrderIsShown" :id="placeOrderIsShown" :place-order="true"  @close="placeOrderIsShown = false"/>-->
            <transition name="fade" >
                <OrderItemInfoPopup v-if="showInfoForOrderId && showInfoForOrderId !== 0" @onClose="showInfoForOrderId = 0" :order-id="showInfoForOrderId"/>
            </transition>

            <Teleport to="body">
              <DeleteConfirmationModal v-model="showDeleteConfirmationModal" @deleteEstimate="deleteOrder" message="Are you sure you want to delete this order?"/>
            </Teleport>

            <SubmitOrderOrJobModal
                    v-model:show="showSubmitOrderConfirmation"
                    @orderSubmitted="handleOrderSubmitted"
                    :id="order.id"
                    area="my-orders"
            />
            <ConvertToJobsModal v-model:show="showConvertToJobsModal" :order-id="order.id"/>
        </div>
    </div>


  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import ButtonMain from "@/components/buttons/ButtonMain";
import SimpleDropdown from "@/components/dropdown/SimpleDropdown";
// import OrdersScheduleCheckMeasure from "@/components/portal-orders/OrdersScheduleCheckMeasure";
// import OrdersCancel from "@/components/portal-orders/OrdersCancel";
//import OrdersDetails from "@/components/portal-orders/OrdersDetails";
import OrdersDelivery from "@/components/portal-orders/OrdersDelivery";
// import OrdersCheckMeasureCompleted from "@/components/portal-orders/OrdersCheckMeasureCompleted";
import {changePriceForm} from '../../helpers/aroundPrice';
import {formatDate, dateCalcFormat} from "../../helpers/datetime";
import OrderItemInfoPopup from "../orders/OrderItemInfoPopup";
// import DeleteConfirmationModal from '@/components/commons/DeleteConfirmationModal.vue'
import DeleteConfirmationModal from '@/components/item-wizard/components/modals/DeleteConfirmationModal'

// import OrdersCardDropdown from './OrdersCardDropdown.vue';
import SubmitOrderOrJobModal from "@/components/item-wizard/components/modals/SubmitOrderOrJobModal.vue";
import ConvertToJobsModal from "@/components/item-wizard/components/modals/ConvertToJobsModal.vue";
import router from "@/router";

import {useFloating, autoUpdate} from '@floating-ui/vue';


export default {
  name: 'OrdersCard',
  props: ['order', 'type', 'noButton'],
  components: {
    ButtonMain,
    SimpleDropdown,
    OrderItemInfoPopup,
    DeleteConfirmationModal,
    // OrdersCardDropdown,
    SubmitOrderOrJobModal,
    ConvertToJobsModal,
    OrdersDelivery
},
  setup(props, {emit}) {
    const store = useStore()
    const showSchedule = ref(false)
    const showCancel = ref(false)
    const checkIsCompleted = ref(false)
    const menuIsOpened = ref(false)
    const detailsAreShown = ref(false)
    const deliveryIsShown = ref(false)
    const placeOrderIsShown = ref(false)
    const showInfoForOrderId = ref (0)
    const showDeleteConfirmationModal = ref(false)
    const showSubmitOrderConfirmation = ref(false)
    const showConvertToJobsModal = ref(false)
    const orderAge = computed(() => Math.ceil((new Date() - new Date(props.order.order_date)) / (1000 * 60 * 60 * 24)))
    const mainAction = computed(() => {
      return props.order.actions.find(x => x.type === "main")
    })
    const otherActions = computed(() => {
      return props.order.actions.filter(i => {
        return i.type !== "main" && (i.available_on === 'list' || i.available_on === 'both')
      })
    })

    const showDateTitle = ref(false)
    const reference = ref(null);
    const floating = ref(null);

    const {floatingStyles}  = useFloating(reference, floating, {
      whileElementsMounted: autoUpdate,
    });

    const handleOrderSubmitted = () => {
      showSubmitOrderConfirmation.value = false
      const sort = store.getters['orders/ordersSorting']
      store.commit('orders/ordersSorting', sort +' ')
    }

    let toggleMenu = () => {
      menuIsOpened.value =! menuIsOpened.value
    }
    let closeMenu = () => {
      menuIsOpened.value = false
    }
    let changeStatus = (id, value) => {
      menuIsOpened.value = false
    }
    let priceCalc = (price) => {
      return changePriceForm(price);
    }
    let dateCalc = (date) => {
      if(formatDate(date)) {
        return formatDate(date);
      } else
      return date
    }
    let dateFormat = (date) => {
      return dateCalcFormat(date, false, false)
    }
    let deleteOrder = async () => {
      await store.dispatch('orders/deleteOrder', props.order.container_id)
    }
    let cloneOrder = async () => {
      await store.dispatch('orders/cloneOrder', {id: props.order.container_id, status: 'clone'} )
    }

    const canSeePrice = computed(() => store.getters['auth/canSeeCost'])

    async function handleActionClick(action){
      switch (action){
        case "EDIT":
        case "VIEW": router.push({name: "order-item", params: {id : props.order.container_id}})
          break
        case "MARK_AS_DELIVERED":
          deliveryIsShown.value = props.order.id
          break
        case "CLONE":
          await cloneOrder()
          break
        case "SUBMIT_ORDER":
          //placeOrderIsShown.value = props.job.id
          showSubmitOrderConfirmation.value = true
          break
        case "CANCEL":
          showCancel.value = props.order.id
          break
        case "DELETE":
          showDeleteConfirmationModal.value = true
          break
        case "SPECIFICATION":
          router.push({ name: 'specification', params: { id: props.order.id, type: 'my-orders' } })
          break
        case "CONVERT":
          showConvertToJobsModal.value = true
          break

      }

      //@showBookInstallation="showBookInstallation = job.id"
      //@showSchedule="showSchedule = job.id"

    }


    return {
      showDateTitle,
      floatingStyles,
      reference,
      floating,
      handleOrderSubmitted,
      showDeleteConfirmationModal,
      toggleMenu,
      deleteOrder,
      cloneOrder,
      menuIsOpened,
      closeMenu,
      priceCalc,
      dateCalc,
      dateFormat,
      orderAge,

      showSchedule,
      checkIsCompleted,
      showCancel,
      changeStatus,
      detailsAreShown,
      deliveryIsShown,
      placeOrderIsShown,
      showInfoForOrderId,
      showSubmitOrderConfirmation,
      showConvertToJobsModal,
      canSeePrice,
      handleActionClick,
      mainAction,
      otherActions
    }
  }
};
</script>


<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
  border-radius: 2px;
  height: auto;
  margin-bottom: 10px;
  &__header {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: rgba(11, 53, 83, 0.15);
    border-radius: 2px 2px 0px 0px;
    .header {
      &__id {
        font-size: 13px;
        margin-left: 10px;
        margin-right: 5px;
        text-transform: uppercase;
        flex-shrink: 0;
        font-weight: bold;
      }
      &__title {
        font-size: 13px;
        text-transform: uppercase;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: rgba(28, 40, 51, 0.7);
        font-weight: bold;
      }
      &__info {
        height: 100%;
        width: 30px;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        background: rgba(11, 53, 83, 0.2);
        margin-left: auto;
        font-size: 14px;
        color: white;
        cursor: pointer;
        transition-duration: 0.2s;
        &:hover {
          background: #59B692;
        }
      }
    }
    ::v-deep .dropdown_menu {
      flex-shrink: 0;
      margin: 0;
      background: linear-gradient(0deg, rgba(11, 53, 83, 0.15), rgba(11, 53, 83, 0.15)), #FFFFFF;
      &:hover {
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.2), rgba(11, 53, 83, 0.2)), #FFFFFF;
      }
      .simple_dropdown {
        > * {
          transition-duration: 0.2s;
          &:hover {
            span {
              transition-duration: 0.2s;
              color: #59B692;
            }
            color: #59B692;
          }
        }
        div.cancel {
          &:hover {
            span {
              color: #C0392B;
            }
            color: #C0392B;
          }
        }
      }
    }
  }
  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px 10px 7px;
    color: rgba(28, 40, 51, 0.8);
    font-size: 13px;
    span {
      margin-right: 5px;
    }
    .content {
      &__elements {
        & > div {
          padding-bottom: 10px;
        }
        & * {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        span {
          flex-shrink: 0;
        }
        //& div {
        //  text-overflow: ellipsis;
        //  overflow: hidden;
        //  white-space: nowrap;
        //}
        .elements {
          &__reference {
            font-weight: bold;
            font-size: 13px;
            color: #1C2833;
            padding-bottom: 14px;
            &--inactive {
              color: rgba(28, 40, 51, 0.6);
              font-style: italic;
              font-weight: normal;
            }
          }
        }
      }
      &__button {
        margin-top: auto;
        button {
          margin-bottom: 10px;
        }
      }
    }
  }
  &--no_button {
    height: 160px;
    .card__content {
      padding-bottom: 0;
    }
  }
}
@media (max-width: 768px) {
  ::v-deep .dropdown_menu {
    .simple_dropdown {
      padding: 0;
      width: 260px;
      > * {
        padding: 0 18px;
        height: 50px;
        &:not(:last-child) {
          border-bottom: 1px solid rgba(11, 53, 83, 0.2);
        }
      }
    }
  }
}
</style>
