import { log } from "console"

<script setup>
    import { watch } from "@vue/runtime-core";
    import { onUnmounted, ref, defineProps, onMounted } from 'vue';
    import _ from 'lodash'

    const props = defineProps({
        offset: {
            type: Number,
            default: 70
        },
        elementClass: {
            type: String,
            default: ''
        },
        showOverflow: {
            type: Boolean,
            default: false,
        },
        alwaysShow: {
            type: Boolean,
            default: false,
        },
        adjustMarginTopSelector: {
            type: String,
            default: '.estimate'
        }
    })
    const isOpen = ref(false)
    const el = ref()

    watch(
        isOpen,
        (newVal, oldVal) => {
            if (isOpen.value ) {
                setTimeout(() => {
                    document.querySelector('#page-header').style.overflow = "unset"
                }, 300);
            }else {
                document.querySelector('#page-header').style.overflow = "hidden"
            }
        },
        { immediate: true }
    );

    const makeVisible = () => {
        isOpen.value = true
        el.value.classList.add('active')

        if(props.alwaysShow && el.value) {
            const body = document.querySelector(props.adjustMarginTopSelector)
            const mt = parseFloat(body.style.marginTop || 0)
            const finalTop = parseFloat(el.value.clientHeight || 0) + mt
            body.style.marginTop = `${finalTop}px`
        }
    }

    const makeNotVisible = () => {
        isOpen.value = false
        el.value.classList.remove('active')
    }

    const handleScroll = _.throttle((e) => {
        if(props.alwaysShow) return

        const h = document.querySelector('#page-header')
        if(window.pageYOffset >= props.offset){
            makeVisible()
        }else {
            makeNotVisible()
        }
    }, 100)

    onMounted(() => {
        window.addEventListener('scroll', handleScroll);
        if(props.alwaysShow) makeVisible()
    })

    onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll)
    })


</script>

<template>
    <teleport to="#page-header">
        <div class="page-header-global" :class="props.elementClass" ref="el" :style="isOpen? 'overflow: unset;': ''">
            <slot></slot>
        </div>
    </teleport>
</template>

<style lang="scss">
    #page-header {
        overflow: hidden;
        padding-bottom: 5px;
    }

    .page-header-global {
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        transition: all .3s linear;
        transform: translateY(-100%);
        box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
        overflow: hidden;
        max-height: 0;
        &.active {
            transform: translateY(0);
            max-height: 1000px;
        }

        @media(max-width: 768px) {
            padding: 5px 5px;
        }
    }
</style>
