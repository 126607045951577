import { AJAX_POST, AJAX_GET, AJAX_PUT, AJAX_DELETE } from '../helpers/ajax'
import {httpReq} from "@/htttpReq";

const status = {
  SUCCESS: 'success',
  ERROR: 'error',
  LOADING: 'loading'
}

export default {
    status,
    async getCustomers(payload) {
      return await AJAX_GET(`customers/search?filter[search]=${payload.text || ''}`)
    },

    async createCustomer(payload){
      return await AJAX_POST('customers', payload)
    },

    async updateCustomer(payload){
      return await AJAX_PUT('customers/' + payload.id, payload)
    }
    // async nextStep(payload) {
    //     return await AJAX_POST('/product-builder/next', payload, this.getArgAuth())
    // },

}
