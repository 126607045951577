<template>
  <section>
      <JobsList></JobsList>
  </section>
</template>

<script>

import { ref } from 'vue'
import { useStore } from 'vuex'
import JobsList from '@/components/jobs/JobsList'

export default {
  components: {
    JobsList
  },
  setup() {

  }
};
</script>

<style scoped>

</style>
