<template>

  <div class="customer_selector">
    <template v-if="!showEditor">
      <slot name="searchHeader">

      </slot>
    </template>

    <div class="customer_selector__search" v-if="!showEditor">
      Customer Search
      <SelectDropdownInput
        :list="customers"
        :loading="loading"
        placeholder="Search Customer"
        :selection="selectedCustomer"
        :value="searchValue"
        @input="changeValue"
        @select="selectedCustomer = $event"
        :edit="!noEdit"
        :addNew="!noCreate"
        @addNewItem="addNewCustomer"
        @editItem="editCustomer"
        editLinkText="Edit Customer"
      >
      </SelectDropdownInput>
      <div class="btn_group">
        <button class="btn btn_default"
                type="button"
                :disabled="!selectedCustomer"
                @click="$emit('selectCustomer', selectedCustomer)"
        ><svg-icon name="solid/check"></svg-icon>
          {{selectButtonTitle || 'Select'}}
        </button>
        <button v-if="!noCreate"
                class="btn btn_outline"
                type="button"
                @click="addNewCustomer"
        >
          <svg-icon name="solid/plus-circle"></svg-icon>
          Create New Customer
        </button>
      </div>
    </div>
    <div v-if="showEditor" class="customer_selector__editor">
      <CustomerForm :selected="newCustomer? null: selectedCustomer" :customerId="newCustomer? null: selectedCustomer?.id" @customerSaved="customerSaved" @back="closeEditor" createButtonText="Create & Select" updateButtonText="Update & Select"/>
    </div>

  </div>


</template>

<script>
import SelectDropdownInput from "@/components/dropdown/SelectDropdownInput";
import {computed, ref} from "vue";
import api from "@/services/customers.service"
import Modal from '@/components/modals/Modal';
import SingleAddressComponent from "@/components/commons/NewSingleAddressComponent";
import Input from "@/components/inputs/Input";
import AddressAutoComplete from "@/components/item-wizard/components/form/AddressAutocomplete"
import SvgIcon from "@/components/SvgIcon";
import { useEventBus } from '@vueuse/core'
import { maska } from 'maska'
import CustomerForm from "./CustomerForm.vue";

export default {
  name: "CustomerSelector",
  props: ["selected", 'selectButtonTitle', 'noCreate', 'noEdit'],
  directives: {
    maska
  },
  components: {
    SvgIcon,
    Modal,
    SelectDropdownInput,
    SingleAddressComponent,
    Input,
    AddressAutoComplete,
    CustomerForm
},
  setup(props, {emit}) {
    const updateCustomerEvent = useEventBus('update-customer')
    const loading = ref(true)
    const searchValue = ref('')
    const isError = ref(false)

    const customers = ref([])

    const showEditor = ref(false)
    const selectedCustomer = ref(props.selected)
    const newCustomer = ref(false)
    const processing = ref(false)

    const editorData = ref({})

    const address = computed({
      get: () => {
        return {
          address: editorData.value?.address,
          suburb: editorData.value?.suburb,
          postcode: editorData.value?.postcode,
          state: editorData.value?.state
        } ?? {}
      },
      set: value => {
        editorData.value = {...editorData.value, ...value}
      }
    })
    //editor variables
    const customerType = ref(null)

    // const changeValue = _.debounce(search, 300)

    let changeValue = (e) => {
      searchValue.value = e
      if (searchValue.value.length > 2)
        search()
        // setTimeout(() => {
        //     search()
        // }, 300)

    }

    const search = _.debounce(async () =>{
      loading.value = true
      let result = await api.getCustomers({amount: 15,text: searchValue.value})

      customers.value = result.data
      loading.value = false
    }, 700)

    function addNewCustomer(){
      editorData.value = {}
      showEditor.value = true
      newCustomer.value = true
    }

    function editCustomer(){
      editorData.value = Object.assign({}, selectedCustomer.value)
      showEditor.value = true
    }

    function closeEditor(){
      editorData.value = {}
      showEditor.value = false
      newCustomer.value = false
    }

    function customerSaved(customer) {
      showEditor.value = false
      emit('selectCustomer', customer)
      closeEditor()
    }

    async function saveCustomer(){
     console.log('save')
     processing.value = true
      if (!editorData.value.id){
        let result = await api.createCustomer(editorData.value)
        if (result.success){
          editorData.value.id = result.id
          selectedCustomer.value = Object.assign({}, editorData.value)
          showEditor.value = false
          emit('selectCustomer', selectedCustomer.value)
          closeEditor()
        }
      }else{
        let result = await api.updateCustomer(editorData.value)
        if (result.success){
          selectedCustomer.value = Object.assign({}, editorData.value)
          emit('selectCustomer', selectedCustomer.value)
          // update customer
          updateCustomerEvent.emit()
          closeEditor()
        }
      }
     processing.value = false
    }

    return {
      newCustomer,
      isError,
      customers,
      searchValue,
      changeValue,
      loading,
      showEditor,
      selectedCustomer,
      addNewCustomer,
      closeEditor,
      editCustomer,
      customerType,
      address,
      saveCustomer,
      editorData,
      processing,
      customerSaved,
    }
  }
}
</script>

<style lang="scss">
   .content__job-customer_details {
    overflow: visible!important;
  }
</style>

<style lang="scss" scoped>

::v-deep(.modal__content__wrapper ) {
  width: 700px;
  max-width: 100%;
}
.customer_selector__editor {
  padding-bottom: 20px;
}

::v-deep {
  .select_dropdown__list {
    top: auto;
    max-height: 70vh;
    position: absolute;
    overflow: auto;
  }

}

.customer_selector{
  max-width: 100%;
  width: 700px;
  background: white;
  padding: 25px;
  max-height: 90vh;
  opacity: 1;
  z-index: 10;
  overflow: visible;

    &.__search{
      width: 100%;
      padding-bottom: 20px;
    }
  }
  .editor{
    &.__header{
      display: flex;
      .editor__back{
        color: var(--PrimaryButtonColour);
        cursor: pointer;
        padding-top: 0.3rem;
        padding-right: 0.5rem;
      }
    }

    &.__content{
      max-height: 90vh;
    }
  }
</style>
