<template>
    <div
      v-click-outside="closeDropdown"
      class="simple_dropdown">
        <slot></slot>
    </div>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import vClickOutside from 'click-outside-vue3'
export default {
  name: "SimpleDropdown",
  components: {
  },
  emits: ['close'],
  directives: {
    clickOutside: vClickOutside.directive
  },
  setup(props, { emit }) {
    let closeDropdown = () => {
      emit('close');
    }
    return {
      closeDropdown,
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/utils/vars";
.simple_dropdown {
  background: linear-gradient(0deg, rgba(11, 53, 83, 0.05), rgba(11, 53, 83, 0.05)), #FFFFFF;
  border: 1px solid rgba(11, 53, 83, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.1);
  cursor: pointer;
  padding: 10px 8px 6px 28px;
  white-space: nowrap;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 5;

   > ::v-deep * {
      padding-bottom: 14px;
     font-weight: bold;
     font-size: 13px;
     line-height: 100%;
     display: flex;
     align-items: center;
     justify-content: flex-end;
     letter-spacing: 0.02em;
     text-transform: uppercase;
     & span {
       color: rgba(28, 40, 51, 0.8);
       margin-right: 2px;
     }
  }
}
@media (max-width: 768px) {
  .simple_dropdown {
    padding: 12px 10px;

    > ::v-deep * {
      padding-bottom: 0px;
    }
  }
}
</style>
