<template>
  <div class="info_card"
       :style="{background: state.background}"
  >
    <div class="font-weight-bold" >
      <div class="info__icon"><svg-icon v-if="state.icon" :name="state?.icon"/></div>
      <h4 class="color-black font-weight-bold" style="font-size: 19px">{{ container.id_with_prefix }}</h4>
      <div class="info__status">
        <div v-if="status.iconHeader"><svg-icon
          :style="{color: status.iconHeaderColor}"
          :name="status.iconHeader"/></div>
      </div>
    </div>
    <div>
      <svg-icon name="solid/layer-group"/>
      <div class="mr-5px ml-5px">Quantity:</div>
      <div class="color-black font-weight-bold">{{ container.total_positions_count || 0 }}</div>
    </div>
    <div>
      <div class="text-uppercase mr-5px">Price:</div>
      <div class="color-black font-weight-bold">${{ priceCalc(container.price) }}</div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed, onBeforeMount } from 'vue'
import {changePriceForm} from '../../helpers/aroundPrice';
import {useStore} from "vuex";


export default ({
  components: {
  },
  props: ['container'],
  setup(props, {emit}) {
    const store = useStore()
    let state = computed(() => store.getters['jobs/exactState'](props.container.state))
    let status = computed(() => store.getters['jobs/exactStatus'](props.container.status))
    let priceCalc = (price) => {
      return changePriceForm(price)
    }
    return {
      priceCalc,
      state,
      status
    }
  }
})
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.info_card {
  height: 60px;
  padding: 16px 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
  & > * {
    width: 200px;
    display: flex;
    align-items: center;
    &:nth-child(1) {
      justify-content: flex-start;
    }
    &:nth-child(2) {
      justify-content: center;
    }
    &:nth-child(3) {
      justify-content: flex-end;
    }
  }
  .info {
    &__status {
      margin-left: 8px;
      color: rgba(28, 40, 51, 0.6);
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__icon {
      font-size: 24px;
      margin-right: 6px;
    }
  }
}
@media (max-width: 768px) {
  .info_card {
    height: 40px;
    width: 100%;
    flex-shrink: 0;
    padding: 4px 20px;

    & > * {
      width: auto;
    }

    .info {
      &__status {
        margin-left: 8px;
        color: rgba(28, 40, 51, 0.6);
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__icon {
        font-size: 24px;
        margin-right: 6px;
      }
    }
  }
}

</style>
