<template>
  <ButtonMain
    v-if="job.actions?.some(e => e.name === 'Schedule Check Measure' && e.type == 'main')"
    @click="$emit('showSchedule')">
    <svg-icon name="solid/history"/>
    Schedule Check Measure
  </ButtonMain>
  <ButtonMain
    v-if="job.actions?.some(e => e.name === 'Check Measure Completed' && e.type == 'main')"
    @click="$emit('checkIsCompleted')">
    <svg-icon name="solid/tape"/>
    Check Measure Completed
  </ButtonMain>
  <ButtonMain
    v-if="job.actions?.some(e => e.name === 'Book Installation' && e.type == 'main')"
    @click="$emit('showBookInstallation')">
    <svg-icon name="solid/calendar-alt"/>
    Book Installation
  </ButtonMain>
  <ButtonMain
    v-if="job.actions?.some(e => e.name === 'I Received The Order' && e.type == 'main')"
    @click="$emit('deliveryIsShown')">
    <svg-icon name="solid/file-export"/>
    I Received The Order
  </ButtonMain>
  <ButtonMain
    v-if="job.actions?.some(e => e.name === 'Complete Job' && e.type == 'main')"
    @click="$emit('changeStatusCompleted')">
    <svg-icon name="solid/archive"/>
    Complete Job
  </ButtonMain>
  <ButtonMain
    v-if="job.actions?.some(e => e.name === 'Place Order' && e.type == 'main')"
    @click="$emit('placeOrderIsShown')">
    <svg-icon name="solid/file-export"/>
    Submit Order
  </ButtonMain>
  <ButtonMain v-if="job.actions?.some(e => e.name === 'Clone' && e.type == 'main')"
              @click="$emit('cloneJob')">
    <svg-icon name="solid/clone"/>
    Clone
  </ButtonMain>
  <router-link
    v-if="job.actions?.some(e => e.name === 'Edit' && e.type == 'main')"
    :to="`/my-jobs/${job.id}`">
    <ButtonMain>
      <svg-icon name="solid/edit"/>
      Edit
    </ButtonMain>
  </router-link>
  <ButtonMain
    v-if="job.actions?.some(e => e.name === 'Cancel' && e.type == 'main')"
    class="cancel" @click="$emit('showCancel')">
    <svg-icon name="solid/times"/>
    Cancel
  </ButtonMain>
</template>

<script>

import ButtonMain from "@/components/buttons/ButtonMain";

export default {
  name: 'jobsCard',
  props: [
    'job'
  ],
  emits: [
    'showSchedule',
    'checkIsCompleted',
    'deliveryIsShown',
    'changeStatusCompleted',
    'placeOrderIsShown',
    'cloneJob',
    'showCancel',
    'showBookInstallation'],
  components: {
    ButtonMain
  },
  setup(props, {emit}) {
    return {}
  }
};
</script>
