import { AJAX_POST, AJAX_GET, AJAX_PUT, AJAX_DELETE } from '../helpers/ajax'

export default {

  async getAll(){
    return await AJAX_GET(`events`)
  },

  async get(date){
    return await AJAX_GET(`/events/${date}`)
  },

}
